'use strict';


var lazyLoadInstance =null;
var lazyLoadEinstein =null;

function initLazyLoad(){
    if (lazyLoadInstance !== null) {
        lazyLoadInstance.update();
    }
    else {
        newLazyLoadInstance();
    }
}

function initLazyLoadEinstein(){
    if (lazyLoadEinstein !== null) {
        lazyLoadEinstein.update();
    }
    else {
        newLazyLoadEinstein();
    }
}

function newLazyLoadInstance() {
    if($('.lazy').length > 0){
        lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

    } else {
      setTimeout(newLazyLoadInstance, 3000);
    }
}

function newLazyLoadEinstein() {
    if($('.lazy').length > 0 && $('.product-selection .ensteinhomeitem').length >0){ 
    	setTimeout(newLazyLoadEinstein, 3000);
        lazyLoadEinstein = new LazyLoad({
            elements_selector: ".lazy",
            callback_loaded: ()=> {}
        });
    } else {
      setTimeout(newLazyLoadEinstein, 3000);
    }
}


exports.init = function () {
    initLazyLoad();
    initLazyLoadEinstein();
};