'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    index = require('./index');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            zoomimgg();
            zoomimgg1();
            index.mobilePriceDisplay();
        }
    });
};

module.exports = function () {
    var $pdpMain = $('#pdpMain');
    // hover on swatch - should update main image with swatch image
    $pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
        var largeImg = $(this).data('lgimg'),
            $imgZoom = $pdpMain.find('.main-image'),
            $mainImage = $pdpMain.find('.primary-image');

        if (!largeImg) { return; }
        // store the old data from main image for mouseleave handler
        $(this).data('lgimg', {
            hires: $imgZoom.attr('href'),
            url: $mainImage.attr('src'),
            alt: $mainImage.attr('alt'),
            title: $mainImage.attr('title')
        });
        // set the main image
        image.setMainImage(largeImg);
    });

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {  
        
        $(".variation-select option").each(function() {
            var len1=$(this).length;
                for (let i = 0; i < len1; i++) {
                   if($(this)[i].className == "emptytext" && $(this)[i].selected == true) {
                     $("#add-to-cart").attr('disabled', true);
                   };
                }  
        });
            if ($(this).val().length === 0) { return; }
            updateContent($(this).val());
    });
};

function zoomimgg () {
    if($(window).width() > 1024){
        var zoomImg = $('.js-zoom-large');
        if(zoomImg.length){
            zoomImg.elevateZoom({
                zoomWindowWidth: 660,
                zoomWindowHeight: 660,
                borderSize: 0,
                zoomWindowPosition: "product-zoom-container",
                zoomWindowOffetx: 20,
                zoomLens: false,
                lensBorder:0,
                easing : true
            });
        }
        zoomImg.hover(function (e) {
            $('body').toggleClass('removeScroll');
        });
    };
    $(".back").click(function() {
        window.location.href = document.referrer; 
    });
}

function zoomimgg1 () {
    $('.slider-for .item:empty').remove();
        if ($('.slider-for .item').length > 1) {
            $('.slider-nav').removeClass('hide');
            $('.slider-for').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                accessibility: false,
                responsive: [
                    {
                        breakpoint: 1001,
                        settings: {
                            dots: true
                        
                        }
                    }

                ]
            });
            $('.slider-nav').slick({
                slidesToShow: 3,
                accessibility: false,
                slidesToScroll: 1,
                dots: false,
                centerMode: false,
                arrows: true,
                vertical: true,
                verticalSwiping: true
            });
            $('.slider-nav .slick-arrow').on('click', function () {
                $('.slider-nav .slick-slide').each(function () {
                    $(this).removeClass('slick-current');
                });
            });
            $('.slider-nav .slick-slide').on('click', function () {

                $('.slider-for').slick('slickGoTo', $(this).data('slickIndex'));
                $('.slider-nav .slick-slide').each(function () {
                    $(this).removeClass('slick-current');
                });
                $(this).addClass('slick-current');
            });

            $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $('.slick-track').find('.item').each(function(){
                    var item = $(this);
                    var productimgdiv = item.find('.product-image div');
                    var indeximg = item.data('slick-index');
                    if(indeximg === nextSlide && productimgdiv.length>0 ){ 
                        var classes = productimgdiv.attr('class');
                        var itemprop = productimgdiv.attr('itemprop');
                        var alt = productimgdiv.attr('alt');
                        var datasrc = productimgdiv.attr('data-src');
                        var datazoomimage = productimgdiv.attr('data-zoom-image');
                        var datalgimg = productimgdiv.attr('data-lgimg');
                        var newcontent = '<img class="'+classes+'" itemprop="'+itemprop+'" alt="'+alt+'" src="'+datasrc+'" data-src="'+datasrc+'" data-zoom-image="'+datazoomimage+'"  data-lgimg="'+datalgimg+'" />';                    
                        item.find('.product-image').html(newcontent);
                        if($(window).width() > 1024){
                            var zoomImg = $('.js-zoom-large');
                            if(zoomImg.length){
                                zoomImg.elevateZoom({
                                    cursor: "crosshair",
                                    zoomWindowWidth: 660,
                                    zoomWindowHeight: 660,
                                    borderSize: 0,
                                    zoomWindowPosition: "product-zoom-container",
                                    zoomWindowOffetx: 20,
                                    zoomLens: false,
                                    lensBorder:0,
                                    easing : true
                                });
            
                            }
                            zoomImg.hover(function (e) {
                                $('body').toggleClass('removeScroll');
                            });
            
                        }
                    }
                });
            });

            $('.primary-image').on( "click", function() {
                $("img.lazy").trigger("appear");
            });
        }
        else {
            $('.slider-nav').hide();
        }
        $(".back").click(function() {
            window.location.href = document.referrer; 
        });
}