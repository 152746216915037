'use strict';

//
var caContactForm = {

    init:function contactFormCA() {

        // submit content asset contact-form
        $('.ca-contact').find('.content-asset').find('form').on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            if ($form.valid()) {
                $('.form-gift').animate({scrollTop: 0}, "fast" , function () {
                    $('.form-gift').append('<div class="loader"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                    $('.form-gift').addClass('removeOverflow');
                });
                var formUrl = $('.ca-contact').find('.content-asset').find('form').attr('action');
                var formMethod = $('.ca-contact').find('.content-asset').find('form').attr('method');
                $.ajax({
                    url: formUrl+"?format=json&jsoncallback",
                    dataType : "jsonp",
                    type: formMethod,
                    data: $form.serialize(),
                    contentType : "application/json",
                    crossDomain : true,
                    success: function (reponse) {
                        if (response) {
                            if (response.error == true) {
                                return false;
                            } else if (response.error == false) {
                                page.refesh(url);
                            }
                        }
                        
                    }

                }).fail(function(){

                })
                setTimeout(function () {
                    $('.form-gift ').find('.loader').remove();
                    $('.form-gift form').fadeOut(); 
                    $('#overlay-popup-contact').fadeIn();
                    $('.confirmGiftForm').fadeIn();
                }, 5000);
                
            }

            return;
        });
    }
}	

module.exports = caContactForm;