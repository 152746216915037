'use strict';
var util = require('./util'),
    bonusProductsView = require('./bonus-products-view');
    var canClose = true;
    var canOpen = true;

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form,$this) {
    var $form = $(form);
    var $qty = $this.parent().find('quantityInMiniCart');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    
	  if ($('.qty-acc').length > 0){
		  $qty.val('1');
	  }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            $this.parents(".mini-cart-details-wrapper").find('.minicart-quantity-error').removeClass('d-none');
        } else {
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function ($this, e) {
    e.preventDefault();
    var $this = $($this);
    var $form = $this.closest('form');
    var encreaseqte = '.miniCartQuantity';
    const quantityInMiniCart_ =  ".quantityInMiniCart";
    addItemToCart($form, $this).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            var maxQty = parseInt($('.miniCartMaxOrderQuantity').text());
            var maxStockminCart = parseInt($('#miniCartMaxOrderQuantity').data('available'));
            var newval = parseInt($(quantityInMiniCart_).html())+1;
    
            // Condition 1: maxQty > maxStock and newval <= maxStock
            if((maxQty === 0 && newval >= maxStock)) {
                return;
            }
            else if (!(maxQty > maxStockminCart && newval <= maxStockminCart) && !(maxQty < maxStockminCart && newval <= maxQty)) {
                return;
            }
            else {
                $this.closest('.addcart_minicart').find(encreaseqte).html(newval);
            }
            if(newval<=maxQty){
                minicart.show(response);
                $this.closest('.addcart_minicart').find(encreaseqte).html(newval);
                if($('.mini-cart-empty').length > 0){
                    $("body").removeClass("overflow-hidden");
                    $(".minicartoverlay").removeClass("overlayminicart").hide();
                    canOpen = false;
                    setTimeout(function () {
                      canOpen = true;
                    }, 100);

                } else {
                    $("body").addClass("overflow-hidden");
                    $(".minicartoverlay").addClass("overlayminicart").show();
                    $(".mini-cart-content").show();
                }
            }
            restoreScrollPosition();
        }
    }.bind(this));
};

var deleteItemToCart_ = function (form) {
    var $form = $(form);
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.deleteProductfromCartItems), 
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response === '') {
            throw new Error(response.error);
        } else {
            return response; 
        }
    });
};

var removeItemfromcart = function ($this, e) { 
    
    e.preventDefault();
    var $this = $($this);
    var $form = $this.closest('form');
    var encreaseqte = '.miniCartQuantity';
    var v = $(encreaseqte).html();
    const quantityInMiniCart = '.quantityInMiniCart';

    deleteItemToCart_($form).then(function (response) {
        // do not close quickview if adding individual item that is part of product set
        minicart.show(response);
        $(quantityInMiniCart).html(v - 1);
        $this.closest('.addcart_minicart').find(encreaseqte).html(v - 1);
        if($('.mini-cart-empty').length > 0){
            $("body").removeClass("overflow-hidden");
            $(".minicartoverlay").removeClass("overlayminicart").hide();
            canOpen = false;
            setTimeout(function () {
              canOpen = true;
            }, 100);
            
        } else {
            $("body").addClass("overflow-hidden");
            $(".minicartoverlay").addClass("overlayminicart").show();
            $(".mini-cart-content").show();
        }
        restoreScrollPosition();
    }.bind(this));
}

var completlyRemoveItemFromCart = function (uuid) {
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.RemoveProduct), 
        data: {'uuid' : uuid}
    })).then(function (response) {
        // handle error in the response
        if (response === '') {
            throw new Error(response.error);
        } else {
            minicart.show(response);
            if($('.mini-cart-empty').length > 0){
                $(".mini-cart-link").removeClass("pointer-none");
                $('.close-headercard').addClass('pointer-none')
                canOpen = false;
                setTimeout(function () {
                  canOpen = true;
                }, 100);
                if($("body").hasClass("overflow-hidden")){
                    $("body").removeClass("overflow-hidden");
                }
                $(".minicartoverlay").removeClass("overlayminicart").hide();
            } else {
                if(!$("body").hasClass("overflow-hidden")){
                    $("body").addClass("overflow-hidden");
                }
                $(".minicartoverlay").addClass("overlayminicart").show();
                $(".mini-cart-content").show();
            }
            restoreScrollPosition();
        }
    });
};

/**
 * @description Keep the position of scroll after add/remove from the minicart
 */
function saveScrollPosition() {
    var scrollPosition = $(".mini-cart-products").scrollTop();
    localStorage.setItem("scrollPosition", scrollPosition);
  }

  function restoreScrollPosition() {
    var $miniCartProducts = $(".mini-cart-products");
    var scrollPosition = localStorage.getItem("scrollPosition");
    
    if (scrollPosition !== null) {
        $miniCartProducts.scrollTop(parseInt(scrollPosition));
    }

    function handleScroll() {
        saveScrollPosition();
    }

    $miniCartProducts.on("scroll", handleScroll);
}
  restoreScrollPosition();

var minicart = {
    init: function () {
        this.$el = $('#mini-cart');
        this.$el.addClass("minicart-header");
        this.$content = this.$el.find('.mini-cart-content');

        $(document).on('click','.miniCartAddQ', function(e){
            e.stopImmediatePropagation();
            var $this = $(this);
            addToCart($this, e);
        });

        $(document).on('click', '.miniCartRemoveQ', function(e){
            e.stopImmediatePropagation();
            var $this = $(this);
            removeItemfromcart($this, e);
        });

        $(document).on('click', '.remove-minicart-item', function(e){
            e.stopImmediatePropagation();
            var $this = $(this);
            var uuid = $this.closest('.mini-cart-product').data('uuid');
            completlyRemoveItemFromCart(uuid);
        });
        $(document).on('click, mouseenter','.mini-cart-total', function () {
            if (canOpen) {
           if(!$('.mini-cart-empty').length > 0){
            var $container = $(this).siblings(".mini-cart-content");
            $(".minicartoverlay").addClass("overlayminicart").show();
            $container.show();
            $("body").addClass("overflow-hidden");
            canClose = false;
            setTimeout(function() {
                canClose = true;
            }, 100);
           }
        }
          });
          $(document).on('click','.close-headercard, #mini-cart .overlayminicart', function () {
            if (canClose) {
              var $container = $(".mini-cart-content");
              $(".minicartoverlay").removeClass("overlayminicart").hide();
              $container.hide();
              $("body").removeClass("overflow-hidden");
              canOpen = false;
              setTimeout(function () {
                canOpen = true;
              }, 100);
            }
        
          });
          $(document).on('click','.mini-cart-link', function (e) {
            if(!$('.mini-cart-empty').length > 0){
            e.preventDefault();
            }
          });
        $(document).on('click','.renewOrder',function(e){
            e.stopImmediatePropagation();
            $.spinner().start();
            var selector = $(this);
            $.ajax({
                url: selector.data('url'),
                method: 'GET',
                data: {
                    'orderNo': selector.data('order-id')
                },
                success: function (response) {
                    //Afficher mini Cart
                    $.ajax({
                        url: Urls.displayMiniCart,
                        method: "GET",
                        success: function(response) {
                            $('#mini-cart').empty().html(response);
                        }
                    }).done(function(){
                        if (canOpen) {
                            if(!$('.mini-cart-empty').length > 0){
                             var $container = $('#mini-cart').find(".mini-cart-content");
                             $(".minicartoverlay").addClass("overlayminicart").show();
                             $container.show();
                             $("body").addClass("overflow-hidden");
                             canClose = false;
                             setTimeout(function() {
                                 canClose = true;
                             }, 100);
                            }
                        }
                        $.spinner().stop();
                    });
                },
                error: function(){
                    $.spinner().stop();
                }
            });
        });
          
        
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.$el.html(html);
        this.init();
        bonusProductsView.loadBonusOption();
    },

    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(4000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
    }
};


module.exports = minicart;
