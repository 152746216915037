'use strict';
var dialog = require('./dialog'),
    page = require('./page'),
    validator = require('./validator');

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
   

//------------- readonly input message --------//

        $('.required.is24SevresInput').click( function(){
            if($(this).length){
                var clicked = $(this).find('.is24SevresError');
                clicked.show();
            }
            
    });

    $('.submitMdp').click(function(e) { 
        var formNewPass = $('.input-text[placeholder="Nouveau mot de passe *"]').val();
        var formNewPassConfirm = $('.input-text[placeholder="Confirmer le nouveau mot de passe *"]').val();
         if(formNewPass != formNewPassConfirm){
             e.preventDefault();
             $('.erreurTextMdp').removeClass('d-none');
             
             return false;
         }else{
            $('.erreurTextMdp').addClass('d-none');
           return true;
         }
      
     });
    	
    	//password visiblity
    	$('.checkoutlogin .form-row.password .field-wrapper').append('<span class="togglePassText"></span>');
    	$('.togglePassText').bind('click', function () {
    		$(this).toggleClass('shown');
    		var type = ($(this).hasClass('shown') ? 'text' : 'password');
    		$(this).closest('.password').find('input.input-text').attr('type', type);
        });

        $('.toggle-changepw').bind('click', function () {
            $(this).next('.changepw-content').toggle();
            $(this).hide();
            $('#ChangePassowrdForm').toggle();
            $(".js-hide-row-closest-on-edit").closest(".row").hide();
            $(".js-remove-when-edit").hide();
        });
    	
    	$('#24desc-1').show();
    	
    	
    	//MEM DATE SELECTOR
    	
    	var months = [ "01", "02", "03", "04", "05", "06",
    	    "07", "08", "09", "10", "11", "12" ];
    	var i =1;
    	var exprofileDate = $('#dwfrm_profile_customer_birthday').val();
    	var profileDate ='';
        $('.memdate #years').append($('<option />').val('').html('AAAA'));
    	for (i = new Date().getFullYear(); i > 1900; i--){
    	    $('.memdate #years').append($('<option />').val(i).html(i));
    	}

        $('.memdate #months').append($('<option />').val('').html('MM'));
    	for (i = 1; i < 13; i++){
    	    $('.memdate #months').append($('<option />').val(i).html(i));
    	}
    	
    	updateNumberOfDays();
    	 
    	if(exprofileDate){
            var from = exprofileDate.split("/");
            if(from[0] != "" && from[1] != "" && from[2] != "" )
            {
                var existingDate = new Date(exprofileDate);
            }
            if(existingDate)
            {
                $('.memdate #days option').each(function(){
                    if($(this).val()==existingDate.getDate()){
                        
                        $(this).prop("selected", true);
                    }
                });
                $('.memdate #months option').each(function(){
                    if($(this).val()==existingDate.getMonth()+1){
                        $(this).prop("selected", true);
                    }
                });
                $('.memdate #years option').each(function(){
                    if($(this).val()==existingDate.getFullYear()){ 
                        $(this).prop("selected", true);
                    }
                });
            }
            else
            {
                $('select').val('');
            }
     		
     		
     	}
    	 
    	$('.memdate #years, .memdate #months').on("change", function(){
    	    updateNumberOfDays(); 
    	});
    	$('.memdate .combine').on("change", function(){
    	    profileDate =  $('.memdate #months').val() + '/' + $('.memdate #days').val() + '/' + $('.memdate #years').val();
    	    $('#dwfrm_profile_customer_birthday').val(profileDate);
    	    //console.log(profileDate); 
    	});


    	function updateNumberOfDays(){
            var dday = $('.memdate #days').val();
    	    $('.memdate #days').html('');
    	    var month=$('.memdate #months').val();
    	    var year=$('.memdate #years').val();
    	    var days=daysInMonth(month, year);
            $('.memdate #days').append($('<option />').val('').html('JJ'));
    	    for(i=1; i < days+1 ; i++){
    	            $('.memdate #days').append($('<option />').val(i).html(i));
    	    }

            $('.memdate #days option').each(function(){
                if($(this).val() == dday){
                	$(this).prop("selected", true);
                }
            });
    	    
    	}

    	function daysInMonth(month, year) {
    	    return new Date(year, month, 0).getDate();
    	}

    	
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').bind('click', function () {
            $('#OAuthProvider').val(this.id);
        });
    
        
        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').bind('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        function wishInfiniteScroll() {
            // getting the hidden div, which is the placeholder for the next page
            var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
            // get url hidden in DOM
            var gridUrl = loadingPlaceHolder.attr('data-grid-url');

            if (loadingPlaceHolder.length === 1) {
                // switch state to 'loading'
                // - switches state, so the above selector is only matching once
                // - shows loading indicator
                loadingPlaceHolder.attr('data-loading-state', 'loading');
                loadingPlaceHolder.addClass('infinite-scroll-loading');


                // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
                var fillEndlessScrollChunk = function (html) {
                    loadingPlaceHolder.removeClass('infinite-scroll-loading');
                    loadingPlaceHolder.attr('data-loading-state', 'loaded');
                    $('div.search-result-items').append(html);

                    var newStart = parseInt(loadingPlaceHolder.attr('data-start')) + 30;
                    var dataFinish = parseInt(loadingPlaceHolder.attr('data-finish')) ;
                    if( newStart < dataFinish){
                        loadingPlaceHolder.attr('data-start', newStart);
                        loadingPlaceHolder.attr('data-loading-state', 'unloaded');
                        newStart = 'start=' + newStart;
                        gridUrl = gridUrl.replace(/start=\d+/, newStart);
                        loadingPlaceHolder.attr('data-grid-url', gridUrl);
                    }else{
                        $('#show-more-wish').hide();
                    }


                };

                // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
                // it was removed to temporarily address RAP-2649

                $.ajax({
                    type: 'GET',
                    dataType: 'html',
                    url: gridUrl,
                    success: function (response) {

                        // put response into cache
                        try {
                            sessionStorage['scroll-cache_' + gridUrl] = response;
                        } catch (e) {
                            // nothing to catch in case of out of memory of session storage
                            // it will fall back to load via ajax
                        }

                        fillEndlessScrollChunk(response);



                    }
                });
            }
        }

        $('#password-reset-id').on('click', function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                options: {
                    open: function () {
                        validator.init();
                        var $requestPasswordForm = $('[name$="_requestpassword"]');
                        var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                        $($submit).on('click', function (e) {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            e.preventDefault();
                            var data = $requestPasswordForm.serialize();
                            // add form action to data
                            data += '&' + $submit.attr('name') + '=';
                            // make sure the server knows this is an ajax request
                            if (data.indexOf('ajax') === -1) {
                                data += '&format=ajax';
                            }
                            $.ajax({
                                type: 'POST',
                                url: $requestPasswordForm.attr('action'),
                                data: data,
                                success: function (response) {
                                    if (typeof response === 'object' &&
                                            !response.success &&
                                            response.error === 'CSRF Token Mismatch') {
                                        page.redirect(Urls.csrffailedLoginShow);
                                    } else if (typeof response === 'string') {
                                        dialog.$container.html(response);
                                    }
                                },
                                failure: function () {
                                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                                }
                            });
                        });
                    }
                }
            });
        });

        $(document).on('click', '#show-more-wish', wishInfiniteScroll);
    }


}

if($('.login-box.login-account').length){
    if($('.passwordErrorFix.error').length){
        $('.form-caption.text-error').insertAfter('#password-reset').css({
            'float': 'left',
            'margin-top': '5px',
            'position': 'relative',
            'display': 'block',
            'text-align': 'left',
            'padding-bottom': '15px'
          });
    }
}

module.exports = login;
