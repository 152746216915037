'use strict';
var util = require('../util');

var gravure = {
    
    init : function gravurePredefinis() {
        var productRows = $('ul.product-rows');
        productRows.find('li.cart-row').each(function(){
            var self = $(this);

            var checkBoxMessageBtn = self.find('.product-grave-option1 > #product-grave-content-message-cart > fieldset > div > label.messageLabel');
            var checkBoxDestinataireBtn = self.find('.product-grave-option2 > #product-grave-content-destinataire-cart > fieldset > div > label.destinataireLabel');
            var optionsMessageInputs = self.find('.product-grave-option1 input[name=messageText]').toArray();
            var optionsDestinataireInputs = self.find('.product-grave-option2 input[name=destinataireText]').toArray();
            var clearOptionMessage = self.find('.product-grave-option1 .clear-option-message');
            var clearOptionDestinataire = self.find('.product-grave-option2 .clear-option-destinataire');
            var uncheckedMessage = self.find('.product-grave-option1 input[name=messageAGraver]');
            var uncheckedDestinataire = self.find('.product-grave-option2 input[name=destinataireAGraver]');
            var cartGravureRequired = self.find('.cart-gravure-required');
            var cartTextMessageLabel = checkBoxMessageBtn.contents().filter(function() {
                return this
            }).toArray();
            var cartTextDestinataireLabel = checkBoxDestinataireBtn.contents().filter(function() {
                return this
            }).toArray();
            let optionMessage = null;
            let optionDestinataire = null
            let checkedItemMessage = null;
            let checkedItemDestinataire = null;
        
            // check The input from back in hidden list message options
            var updateActiveInputOption1 = function (a, b){
                b = b.find(function(item){
                    return a.replace(/(\r\n|\n|\r)/gm, "") === $(item).val();
                });
                if(b) {
                    checkedItemMessage = $(b);
                    $(b).parent().css("background-color", "#f2f2f2");
                    $(b).parent().find('span.message-span').css("border", "2px solid #fd712b");
                } 
            }
            
            var removeActiveInputOption1 = function (a, b){
                b = b.find(function(item){
                    return a.replace(/(\r\n|\n|\r)/gm, "") === $(item).val();
                });
                if(b) {
                    checkedItemMessage = $(b);
                    $(b).parent().css("background-color", "#ffffff");
                    $(b).parent().find('span.message-span').css("border", "2px solid #e5e5e5");
                }
            }
            // Option 2 Gravure Destinataire
            var updateActiveInputOption2 = function (a, b){
                    b = b.find(function(item){
                        return a.replace(/(\r\n|\n|\r)/gm, "") === $(item).val();
                    })
                    if(b) {
                        checkedItemDestinataire = $(b);
                        $(b).parent().css("background-color", "#f2f2f2");
                        $(b).parent().find('span.destinataire-span').css("border", "2px solid #fd712b");
                    } 
            }
            var removeActiveInputOption2 = function (a, b){
                b = b.find(function(item){
                    return a.replace(/(\r\n|\n|\r)/gm, "") === $(item).val();
                });
                if(b) {
                    checkedItemDestinataire = $(b);
                    $(b).parent().css("background-color", "#ffffff");
                    $(b).parent().find('span.destinataire-span').css("border", "2px solid #e5e5e5");
                }
            }
            var switchCheckBoxStateOption1 = function(oldClass, newClass, element){
                $(element).parents('.product-grave-option1').find('.product-grave-content-message-cart').removeClass(oldClass).addClass(newClass);
            }
            var switchCheckBoxStateOption2 = function(oldClass, newClass, element){
                $(element).parents('.product-grave-option2').find('.product-grave-content-destinataire-cart').removeClass(oldClass).addClass(newClass);
            }

             // Update GravureText Ajax function Call 
            function updateGravureTextTocCart (uuid, customizedText, messageText, destinataireText){
                $.ajax({
                    url: util.ajaxUrl(Urls.addGravureTextToPorduct),
                    type: 'POST',
                    data: {uuid: uuid, customizedText: customizedText, messageText: messageText, destinataireText: destinataireText },
                    success: function (response) {
                    }
                });
            }

            // Update Gravure Price
            function updateGravureToCart (productID, uuid, Quantity, self) {
                var disableGravure;
                if (self.parents('.product-grave-option1').find('.messageLabel').text() === uncheckedMessage.val()) {
                    disableGravure = "y";
                } else if (self.parents('.product-grave-option2').find('.destinataireLabel').text() === uncheckedDestinataire.val()) {
                    disableGravure = "y";
                }
                else {
                    disableGravure = "n";
                }
                $.ajax({
                    url: util.ajaxUrl(Urls.addGravureToPorduct),
                    type: 'POST',
                    data: {uuid: uuid, disableGravure: disableGravure, pid: productID, Quantity: Quantity},
                    success: function (response) {
                        $('.cart-order-totals .order-totals-table').replaceWith(response);
                        var total = parseFloat($('.order-value')[0].textContent.replace(/\s\€/gm, '')).toFixed(2);
                        var order_total_value_class = $('.orderTotalMinmum');
                        $.ajax({
                            url: util.ajaxUrl(Urls.getGravurePrice),
                            type: 'POST',
                            data: {uuid: uuid, disableGravure: disableGravure, pid: productID, Quantity: Quantity},
                            success: function (result) {
                                $("li[data-uuid='" + uuid +"'] div.price-option").remove();
                                $("li[data-uuid='" + uuid +"'] div.product-price").after( result );
                                if(total >= SitePreferences.MIN_ORDER_TOTAL){
                                    order_total_value_class.addClass('hide');
                                } else {
                                    order_total_value_class.removeClass('hide');
                                }
                            }
                        });
                    }
                });
            }

            if(cartTextMessageLabel.length > 0){
                updateActiveInputOption1(cartTextMessageLabel[1].data, optionsMessageInputs);
                optionMessage = cartTextMessageLabel[1].data.replace(/\n/gm, '');
            }
            if(cartTextDestinataireLabel.length > 0){
                updateActiveInputOption2(cartTextDestinataireLabel[1].data, optionsDestinataireInputs);
                optionDestinataire = cartTextDestinataireLabel[1].data.replace(/\n/gm, '');
            }
            
            if(cartGravureRequired.text() === "true"){
                checkBoxMessageBtn.parents('.product-grave-option1').find('.product-grave-content-message-cart').addClass('gravure-disabled');
            }
            
            // Option 1 Gravure Message 
            // Handle the click on the label option message
            checkBoxMessageBtn.on('click.checkMessageHandler', function(e){
                var optionsMessageContainer = $(this).parents('.product-grave-option1').find('#product-grave-message');
                var clearOptionMessage = $(this).parents('.product-grave-option1').find('.clear-option-message');
                e.preventDefault();
                if(optionMessage === uncheckedMessage.val() && $(this).parents('.product-grave-content-message-cart').hasClass('notCheckedState')){
                    switchCheckBoxStateOption1('notCheckedState', 'checkbox', this);
                }
                if(!$(this).prev().is(':checked')){
                    $(this).parents('.product-grave-option1').addClass('graveur-added');
                    optionsMessageContainer.removeClass('hide');
                    clearOptionMessage.removeClass('hide'); 
                } else {
                    checkedItemMessage = optionsMessageInputs.find(function(itemInput){
                        return $(itemInput).is(':checked');  
                    });
                    if($(this).hasClass('optionSetted')){
                        $(this).prev().click();
                        if(optionsMessageContainer.hasClass('hide')){
                            optionsMessageContainer.removeClass('hide');
                            clearOptionMessage.removeClass('hide');
                        } else {
                            optionsMessageContainer.addClass('hide');
                            clearOptionMessage.addClass('hide');
                        }
                    } else {
                        if(optionMessage === uncheckedMessage.val()){
                            switchCheckBoxStateOption1('checkbox', 'notCheckedState', this);
                        }
                        if($(this).parents('.product-grave-content').find('.messageLabel').hasClass('optionNotSetted')){
                            $(this).parents('.product-grave-content').find('.product-grave-option1').removeClass('graveur-added');
                        }
                        optionsMessageContainer.addClass('hide');
                    }
                }
            });

            // Handle Message Options Background Color
            $(optionsMessageInputs).on('click', function(e){
                var customizedTextElement = $(this).parents('.product-grave-content').find('textarea[name=customizedText]');
                var customizedText = customizedTextElement.val();
                var clearOptionMessage = $(this).parents('.product-grave-option1').find('.clear-option-message');
                var optionsMessageContainer = $(this).parents('.product-grave-option1').find('#product-grave-message');
                var optionDestinataireText = $(this).parents('.product-option').find('.product-grave-option2 .destinataireLabel');
                optionDestinataire = optionDestinataireText.text().replace(/(\r\n|\n|\r)/gm, "");
                $(this).parents('.product-grave-option1').removeClass('graveur-added');
                var productID = $(this).closest('div.product-grave').find('input[name="pid"]').val();
                var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
                var Quantity = $(this).closest('div.product-grave').find('input[name="Quantity"]').val();
                if($(this).parents('.product-grave-option1').find('.notCheckedState') === true){
                    switchCheckBoxStateOption1('notCheckedState', 'checkbox', this);
                }
                if($(this).parents('product-grave-option1').find('.optionNotSetted')){
                    $(this).parents('.product-grave-option1').find('.messageLabel').removeClass('optionNotSetted').addClass('optionSetted');
                }
                optionsMessageInputs.forEach(function(optionInput) {
                    $(optionInput).parent().css("background-color", "#ffffff");
                    $(optionInput).parent().find('span.message-span').css("border", "");
                });
                $(this).parent().css("background-color", "#f2f2f2");
                checkBoxMessageBtn.prev().prop('checked', true);
                if(!(optionsMessageContainer.hasClass('hide'))){
                    optionsMessageContainer.addClass('hide');
                }
                if(!(clearOptionMessage.hasClass('hide'))){
                    $(clearOptionMessage).addClass('hide');
                }
                checkBoxMessageBtn.text($(this).val());
                optionMessage = $(this).val();
                if(optionDestinataire !== uncheckedDestinataire.val()) {
                    customizedText = optionMessage + ' ' + optionDestinataire;  
                } else {
                    customizedText = optionMessage;
                }
                customizedTextElement.val(customizedText);
                // Calling function to Update gravure Text
                updateGravureTextTocCart(uuid, customizedTextElement.val(), optionMessage, optionDestinataire);    
                updateGravureToCart(productID, uuid, Quantity, $(this));
            });

            // Clear Messages Options
            clearOptionMessage.on('click', function (e) {
                var productID = $(this).closest('div.product-grave').find('input[name="pid"]').val();
                var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
                var Quantity = $(this).closest('div.product-grave').find('input[name="Quantity"]').val();
                optionMessage = uncheckedMessage.val();
                optionDestinataire = uncheckedDestinataire.val();
                let changedCartTextMessageLabel = checkBoxMessageBtn.contents();
                if(changedCartTextMessageLabel.length > 0) {
                    removeActiveInputOption1(changedCartTextMessageLabel[0].textContent, optionsMessageInputs);
                }
                $(this).parents('.product-grave-option1').find('#product-grave-message').addClass('hide');
                if($(this).parents('.product-grave-content').find('.destinataireLabel').hasClass('optionSetted')){
                    $(this).parents('.product-grave-content').find('.product-grave-option1').addClass('graveur-added');
                } else {
                    $(this).parents('.product-grave-content').find('.product-grave-option1').removeClass('graveur-added');
                }
                $('.product-price').show();
                let checkBoxOptionInput = $(this).parent().prev().find('input#messageInput');
                if(checkBoxOptionInput.is(':checked')){
                    if(!(optionsMessageContainer.hasClass('hide'))){
                        optionsMessageContainer.addClass('hide');
                    }
                    checkBoxOptionInput.prop('checked', false);
                    $(this).addClass('hide');
                    let inputRadioOption = Array.from($(optionsMessageInputs)).find(function(item){
                        return $(item).is(':checked');
                    })
                    $(inputRadioOption).prop('checked', false);
                    $(inputRadioOption).parent().css("background-color", "#ffffff");
                }
                if($(this).parents('.product-grave-option1').find('.product-grave-content-message-cart').hasClass('checkbox')){
                    switchCheckBoxStateOption1('checkbox','notCheckedState', this);
                }
                if($(this).parents('.product-grave-option1').find('.product-grave-content-message-cart').hasClass('checkedState')){
                    switchCheckBoxStateOption1('checkedState','checkbox', this);
                }
                if($(this).parents('product-grave-option1').find('.optionSetted')){
                    $(this).parents('.product-grave-option1').find('.messageLabel').removeClass('optionSetted').addClass('optionNotSetted');
                }
                $(this).addClass('hide');
                $(this).parent().prev().find('label.messageLabel').text(uncheckedMessage.val());
                updateGravureToCart(productID, uuid, Quantity, $(this));
                updateGravureTextTocCart(uuid, '', optionMessage, optionDestinataire);
                $(this).parents('.product-grave-content').find('.clear-option-destinataire').trigger('click');
            });
    
            // Handle the click on the label option destinataire
            checkBoxDestinataireBtn.on('click', function(e){
                var optionsDestinataireContainer = $(this).parents('.product-grave-option2').find('#product-grave-destinataire');
                var clearOptionDestinataire = $(this).parents('.product-grave-option2').find('.clear-option-destinataire');
                e.preventDefault();
                if(optionDestinataire === uncheckedDestinataire.val() && $(this).parents('.product-grave-content-destinataire-cart').hasClass('notCheckedState')){
                    switchCheckBoxStateOption2('notCheckedState', 'checkbox', this);
                }
                if(!$(this).prev().is(':checked')){
                    if($(this).parents('.product-grave-content').find('.messageLabel').hasClass('optionNotSetted')){
                        $(this).parents('.product-grave-content').find('.product-grave-option1').addClass('graveur-added'); 
                    }
                    optionsDestinataireContainer.removeClass('hide');  
                    clearOptionDestinataire.removeClass('hide');
                } else {
                    checkedItemDestinataire = optionsDestinataireInputs.find(function(itemInput){
                        return $(itemInput).is(':checked');  
                    });
                    if($(this).hasClass('optionSetted')){
                        $(this).prev().click();
                        if($(this).parents('.product-grave-content').find('.messageLabel').hasClass('optionNotSetted')){
                            $(this).parents('.product-grave-content').find('.product-grave-option1').addClass('graveur-added'); 
                        }
                        if(optionsDestinataireContainer.hasClass('hide')){
                            optionsDestinataireContainer.removeClass('hide');
                            clearOptionDestinataire.removeClass('hide');
                        } else {
                            optionsDestinataireContainer.addClass('hide');
                            clearOptionDestinataire.addClass('hide');
                        }
                    } else {
                        if(optionDestinataire === uncheckedDestinataire.val()){
                            switchCheckBoxStateOption2('checkbox', 'notCheckedState', this);
                        }
                        if($(this).parents('.product-grave-content').find('.destinataireLabel').hasClass('optionNotSetted')){
                            $(this).parents('.product-grave-content').find('.product-grave-option1').removeClass('graveur-added'); 
                        }
                        optionsDestinataireContainer.addClass('hide');
                    }
                }
            });

            // Handle Destinatire Options Background Color
            $(optionsDestinataireInputs).on('click', function(e){
                var customizedTextElement = $(this).parents('.product-grave-content').find('textarea[name=customizedText]');
                var customizedText = customizedTextElement.val();
                var clearOptionDestinataire= $(this).parents('.product-grave-option2').find('.clear-option-destinataire');
                var optionsDestinataireContainer = $(this).parents('.product-grave-option2').find('#product-grave-destinataire');
                var optionMessageText = $(this).parents('.product-option').find('.product-grave-option1 .messageLabel');
                optionMessage = optionMessageText.text().replace(/(\r\n|\n|\r)/gm, "");
                var productID = $(this).closest('div.product-grave').find('input[name="pid"]').val();
                var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
                var Quantity = $(this).closest('div.product-grave').find('input[name="Quantity"]').val();
                if($(this).parents('.product-grave-option2').find('.notCheckedState')){
                    switchCheckBoxStateOption2('notCheckedState', 'checkbox', this);
                }
                if($(this).parents('product-grave-option2').find('.optionNotSetted')){
                    $(this).parents('.product-grave-option2').find('.destinataireLabel').removeClass('optionNotSetted').addClass('optionSetted');
                }
                optionsDestinataireInputs.forEach(function(optionInput) {
                    $(optionInput).parent().css("background-color", "#ffffff");
                    $(optionInput).parent().find('span.destinataire-span').css("border", "");
                });
                $(this).parent().css("background-color", "#f2f2f2");
                checkBoxDestinataireBtn.prev().prop('checked', true);
                if(!(optionsDestinataireContainer.hasClass('hide'))){
                    optionsDestinataireContainer.addClass('hide');
                }
                if(!(clearOptionDestinataire.hasClass('hide'))){
                    $(clearOptionDestinataire).addClass('hide');
                }
                checkBoxDestinataireBtn.text($(this).val());
                optionDestinataire = $(this).val();
                customizedText = optionMessage + ' ' + optionDestinataire;
                customizedTextElement.val(customizedText);
                if( optionMessage !== null && optionMessage !== uncheckedMessage.val()){
                    updateGravureTextTocCart(uuid, customizedTextElement.val(), optionMessage, optionDestinataire);
                } else {
                    $(this).parents('.product-grave-option2').find('.clear-option-destinataire').trigger('click');
                }
            });

            // Clear Destinatire Options
            clearOptionDestinataire.on('click', function (e) {
                var productID = $(this).closest('div.product-grave').find('input[name="pid"]').val();
                var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
                var Quantity = $(this).closest('div.product-grave').find('input[name="Quantity"]').val();
                optionDestinataire = uncheckedDestinataire.val();
                let changedCartTextDestinataireLabel = checkBoxDestinataireBtn.contents();
                var optionMessageText = $(this).parents('.product-option').find('.product-grave-option1 .messageLabel');
                var optionsDestinataireContainer = $(this).parents('.product-grave-option2').find('#product-grave-destinataire');
                optionMessage = optionMessageText.text().replace(/(\r\n|\n|\r)/gm, "");
                if(changedCartTextDestinataireLabel.length > 0) {
                    removeActiveInputOption2(changedCartTextDestinataireLabel[0].textContent, optionsDestinataireInputs);
                }
                $(this).parents('.product-grave-option2').find('#product-grave-destinataire').addClass('hide');
                $('.product-price').show();
                let checkBoxOptionInput = $(this).parent().prev().find('input#destinataireInput');
                if(checkBoxOptionInput.is(':checked')){
                    if(!(optionsDestinataireContainer.hasClass('hide'))){
                        optionsDestinataireContainer.addClass('hide');
                    }
                    checkBoxOptionInput.prop('checked', false);
                    $(this).addClass('hide');
                    let inputRadioOption = Array.from($(optionsDestinataireInputs)).find(function(item){
                        return $(item).is(':checked');
                    })
                    $(inputRadioOption).prop('checked', false);
                    $(inputRadioOption).parent().css("background-color", "#ffffff");
                }
                if($(this).parents('.product-grave-option2').find('.checkbox')){
                    switchCheckBoxStateOption2('checkbox','notCheckedState', this);
                }
                if($(this).parents('.product-grave-option2').find('.checkedState')){
                    switchCheckBoxStateOption2('checkedState','checkbox', this);
                }
                if($(this).parents('product-grave-option2').find('.optionSetted')){
                    $(this).parents('.product-grave-option2').find('.destinataireLabel').removeClass('optionSetted').addClass('optionNotSetted');
                }
                $(this).addClass('hide');
                $(this).parent().prev().find('label.destinataireLabel').text(uncheckedDestinataire.val());
                if($(this).parents('.product-grave-option2').prev().find('.messageLabel').hasClass('optionNotSetted')){
                    updateGravureToCart(productID, uuid, Quantity, $(this));
                    updateGravureTextTocCart(uuid, '', optionMessage, optionDestinataire);
                } else {
                    updateGravureTextTocCart(uuid, optionMessage, optionMessage, optionDestinataire);
                }
            });
            
        });
    }
}

module.exports = gravure;