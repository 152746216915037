'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    shipping = require('./shipping');

/**
 * @function
 * @description function to convert html tag to lt or gt;
 * @param {fieldValue} value of the field
 */
function encodeRequestFieldValue(fieldValue) {

    return fieldValue.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}
emojisDelete();
function removeChars(validChars, inputString) {
    var regex = new RegExp('[^' + validChars + ']', 'g');
    return inputString.replace(regex, '');
}
function emojisDelete() {
    $('#dwfrm_shippingbilling').find('input').on('input propertychange', function (event) {
        var whitelist = 'a-zA-Z0-9- ';
        var inputString = $(this).val();
        var newInputString = removeChars(whitelist, inputString);
        $(this).val(newInputString);
    });
}
function showHideCreditCardFormInput(show) {
    var shownewpaiement = $('#shownewpaiement');
    if (show) {

        if (!shownewpaiement.hasClass('open_newcard_form')) {
            shownewpaiement.addClass('open_newcard_form');
        }

        $('legend.creditCardLegend').removeClass('hide');
        var $creditCard = $('[data-method="CREDIT_CARD"]');
        $creditCard.find('input[name$="creditCard_owner"]')
        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_owner').parent().parent().removeClass('hide');
        $('div.cart-number').removeClass('hide');
        $('div.cart-month').removeClass('hide');
        $('div.cart-year').removeClass('hide');
        $('div.new-cart-cvn').removeClass('hide');
        $('div.save-card').removeClass('hide');
        $('div.save-card2').removeClass('saved-card-style');


    } else {

        if (shownewpaiement.hasClass('open_newcard_form')) {
            shownewpaiement.removeClass('open_newcard_form');
        }

        $('legend.creditCardLegend').addClass('hide');
        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_owner').parent().parent().addClass('hide');
        $('div.cart-number').addClass('hide');
        $('div.cart-month').addClass('hide');
        $('div.cart-year').addClass('hide');
        $('div.new-cart-cvn').addClass('hide');
        $('div.save-card').addClass('hide');
        $('div.save-card2').addClass('saved-card-style');
    }
}

var disableSelect = function (el) {
    $(el).find('.input-select').after('<div class="disabledSelect"></div>');
}
var enableSelect = function (el) {
    $(el).find('.disabledSelect').remove();
}

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFieldsCybersource(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    // @STORY-ID LGEP-162
    var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
    if (selectedPaymentMethodID == 'SA_SILENTPOST') {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth);
        $creditCard.find('[name$="_year"]').val(data.expirationYear);
    }
    else {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
        $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    }

    /* @STORY-ID LGEP-162
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    */
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    var logoname;


    switch (data.type) {
        case 'MasterCard':
            logoname = 'mastercard';

            $('input[name*=\'_creditCard_cvn_\']').addClass('ccvmastercard');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvvisa');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvamex');

            $('input[name*=\'_creditCard_cvn_\']').attr({
                'minlength': 3,
                'maxlength': 3
            });

            break;
        case 'visa':
            logoname = 'Visa';

            $('input[name*=\'_creditCard_cvn_\']').addClass('ccvvisa');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvamex');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvmastercard');

            $('input[name*=\'_creditCard_cvn_\']').attr({
                'minlength': 3,
                'maxlength': 3
            });
            break;
        case 'Visa':
            logoname = 'Visa';


            $('input[name*=\'_creditCard_cvn_\']').addClass('ccvvisa');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvamex');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvmastercard');

            $('input[name*=\'_creditCard_cvn_\']').attr({
                'minlength': 3,
                'maxlength': 3
            });
            break;
        case 'Amex':
            logoname = 'amex';


            $('input[name*=\'_creditCard_cvn_\']').addClass('ccvamex');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvvisa');
            $('input[name*=\'_creditCard_cvn_\']').removeClass('ccvmastercard');

            $('input[name*=\'_creditCard_cvn_\']').attr({
                'minlength': 4,
                'maxlength': 4
            });
            break;
        default:
            logoname = data.type;
    }

    $('.credit-card-type-logo').fadeIn();
    $('.credit-card-type-logo').find('img')
        .removeAttr('src')
        .attr('src', Urls.urlCardPicto + logoname + '.png');
    // @STORY-ID LGEP-162
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    $creditCard.find('input[name$=\'_cvn\']').val('');
    //$('.overlay-popup').fadeOut();

}
/**
 * clear credit card form
 * @param 
 * @returns
 */
function clearCCFields() {

    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val('').trigger('change');
    $creditCard.find('select[name$="_type"]').val('').trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val('').trigger('change');
    $creditCard.find('[name$="_month"]').val(0).trigger('change');
    $creditCard.find('[name$="_year"]').val(0).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');

    $('input[name*=\'creditCard_cvn_\']').val('').trigger('change');

    $('.credit-card-type-logo').find('img').removeAttr('src');

    // @STORY-ID LGEP-162
    $creditCard.find('[name$="creditCard_selectedCardID"]').val('').trigger('change');
    $creditCard.find('input[name$=\'_cvn\']').val('');

}

function showProductsSummary() {

    $('.see-products').on('click', function () {
        $('.overlay').fadeIn(500, function () {
            $('#secondary').addClass('active');
            $('html , body').animate({scrollTop: 0}, 'slow');
        });

        return;

    });


    $('.checkout-mini-cart i ,  .back-link ').on('click', function () {
        $('.overlay').fadeOut();
        $('#secondary').removeClass('active');
    });
}


/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID, selectedPaymentMethod) { // // @STORY-ID LGEP-162
    $('#btn-validate-pay').addClass('no-click');
    // load card details
    //$('.overlay-popup').fadeIn();
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {

            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            // @STORY-ID LGEP-162
            switch (selectedPaymentMethod) {
                case 'SA_REDIRECT':
                    $('.payment-method-expanded .saCCToken .field-wrapper').val(data.selectedCardID);
                    $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_selectedCardID').val(data.selectedCardID);
                    break;
                case 'SA_IFRAME':
                    $('.payment-method-expanded .saIframeCCToken .field-wrapper').val(data.selectedCardID);
                    $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_selectedCardID').val(data.selectedCardID);
                    break;
                case 'CREDIT_CARD':
                    //LGEPK-1270 (clear field payment after uncheck paymentMethod)

                    if (!$('.open_newcard_form').length) {
                        setCCFieldsCybersource(data);
                        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_owner').attr('readonly', true);
                        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_number').attr('readonly', true);
                        $('#select2-dwfrm_billingwithoutadr_paymentMethods_creditCard_expiration_month-container').attr('readonly', true);
                        $('#select2-dwfrm_billingwithoutadr_paymentMethods_creditCard_expiration_year-container').attr('readonly', true);

                        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_saveCard').prop('checked', false);
                        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_saveCard').closest('div.form-row').hide();


                        disableSelect('.cart-year');
                        disableSelect('.cart-month');
                    }

                    break;
                default:
                    setCCFieldsCybersource(data);

            }
            $('#btn-validate-pay').removeClass('no-click');
        }
    });
    //$('.overlay-popup').fadeOut();
}

/**
 * @function
 * @description saving the payment brand form depending on the passed brandID
 * @param {String} brandID the ID of the selected payment method
 */
function updateSelectedBrand(brandID) {
    var url = Urls.updateSelectedBrand;
    url += '?brand=' + brandID;
    $.get(url, function (data) { });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {

    if (SitePreferences.ENABLE_INGENICO === true) {

        var oldPaymentMethodID = paymentMethodID;
        if (paymentMethodID == 'Klarna E-commerce' || paymentMethodID == 'Klarna Installment E-commerce') {
            if (formPrepare.klarna_forms != undefined) {
                $('div.klarna_forms_wrapper').append(formPrepare.klarna_forms);
                var requiredInputs = $('.required', $('div.klarna_forms_wrapper')).find(':input');
                // start listening
                requiredInputs.on('change', formPrepare.validateEl);
                requiredInputs.filter('input').on('keyup', formPrepare.formDebounce(formPrepare.validateEl, 200));
            }
            var countryCode = $('form[id$="billing"]').find('.country').val();
            if (countryCode) {
                paymentMethodID = paymentMethodID + '_' + countryCode;
            }
        } else {
            if ($('div.klarna_forms').length > 0) {
                formPrepare.klarna_forms = $('div.klarna_forms').remove();
            }
        }
        var $paymentMethods = $('.payment-method');
        $paymentMethods.removeClass('payment-method-expanded');

        var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="Custom"]');
        }

        paymentMethodID = oldPaymentMethodID;

        var $paymentMethodeInput = $('#is-' + paymentMethodID);
        if ($paymentMethodeInput.data('is-hosted-tokenization') == true) {

            $('[name="dwfrm_billingwithoutadr_save"]').hide();
            $selectedPaymentMethod = $('.payment-method.hosted-tokenization-page');
            var url = Urls.hostedTokenizationPage;
            url += '?paymentMethode=' + ($paymentMethodeInput.val() == 'CREDIT_CARD' ? 'CreditCard' : $paymentMethodeInput.val());

            var isSavedCardUsed = false;
            if ($('.ingenico-credit-card-list .item').length > 0) {

                $('.ingenico-credit-card-list .item .input-radio').each(function (index, value) {

                    if (!$('#savedAliases-' + index).parent().hasClass('expired') && $('#savedAliases-' + index).is(':checked')) {
                        var paymentMethode = $('#savedAliases-' + index).data('cardtype').trim().replace(/\s/g, '_').toUpperCase();
                        if (paymentMethodID == paymentMethode) {
                            url += '&useAlias=' + $('#savedAliases-' + index).val();
                            isSavedCardUsed = true;
                        }

                    } else {
                        var paymentMethode = null;
                    }


                });
            } else {
                var paymentMethode = null;
            }

            $.get(url, function (data) {
                $selectedPaymentMethod.html(data);
            });
        } else {
            $('[name="dwfrm_billingwithoutadr_save"]').show();
        }

        $selectedPaymentMethod.addClass('payment-method-expanded');

        // ensure checkbox of payment method is checked
        $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
        paymentMethodID = '\'' + paymentMethodID + '\'';
        $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

        formPrepare.validateForm();

    } else {

        var $paymentMethods = $('.payment-method');
        $paymentMethods.removeClass('payment-method-expanded');

        // @STORY-ID LGEP-162
        var dataMethod = paymentMethodID;
        if (paymentMethodID == 'SA_SILENTPOST') {
            dataMethod = 'CREDIT_CARD';
        }
        var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + dataMethod + '"]');
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="Custom"]');
        }
        // @STORY-ID LGEP-162
        if (paymentMethodID == 'VISA_CHECKOUT') {
            $('.continue-place-order').hide();
            $('.visacheckoutbutton').show();
        }
        else {
            $('.continue-place-order').show();
            $('.visacheckoutbutton').hide();
        }
        if (paymentMethodID == 'CREDIT_CARD' || paymentMethodID == 'SA_SILENTPOST') {
            $('.spsavecard').show();
        } else if ((paymentMethodID == 'SA_REDIRECT' || paymentMethodID == 'SA_IFRAME') && SitePreferences.TOKENIZATION_ENABLED) {
            $('.spsavecard').show();
        }
        else {
            $('.spsavecard').hide();
        }

        /*  @STORY-ID LGEP-162
        var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="Custom"]');
        }
        */
        $selectedPaymentMethod.addClass('payment-method-expanded');

        // ensure checkbox of payment method is checked
        $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
        $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

        formPrepare.validateForm();
    }
}

/**
 * @function
 * @description Updates the billing form with the attributes with the single shipping form fields
 */
function populateBillingForm() {
    // load billing form details
    var shipAddrFieldsSelector = 'input[name*=dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields]';
    var billAddrFieldsSelector = 'input[name*=dwfrm_shippingbilling_billing_billingAddress_addressFields';
    var $singleShippingAddressInputs = $(shipAddrFieldsSelector);
    $.each($singleShippingAddressInputs, function () {
        var $this = $(this);
        var value = $this.val();
        if (value) {
            var id = $this.attr('id');
            var type = $this.attr('type');
            if (!id) id = $this.attr('name');
            id = id.substring(id.lastIndexOf('_'));
            if (type == 'radio') {
                if ($this.is(':checked')) {
                    $(billAddrFieldsSelector + id + '][value=' + value + ']').attr('checked', 'checked');
                }
            } else {
                $(billAddrFieldsSelector + id + ']').val(value);
            }
        }
    });
}

/**
 * @function
 * @description clears address form
 * @param {String} form to clear
 */
function clearForm(form) {
    var inputs;
    if (form == 'billing') {
        inputs = $('input[name*=dwfrm_shippingbilling_billing_billingAddress_addressFields]');
    } else {
        inputs = $('input[name*=dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields]');
    }
    $.each(inputs, function () {
        var $this = $(this);
        var type = $this.attr('type');
        if (type !== 'radio') {
            $this.val('');
        }
    });
    //uncheck civility options
    $('[id$="' + form + 'Address_addressFields_civility"]').each(function () {
        var $this = $(this);
        if ($this.is(':checked')) $this.attr('checked', false);
    })
    //set default country
    var countryName = $('select[id*="' + form + 'Address_addressFields_country"]').find('option:selected').html();
    $('input[name$="' + form + '_input_country"]').val(countryName);
}

/**
 * @function
 * @description hides specified form
 * @param {String} form to hide
 */
function hideForm(formtohide) {
    // hide form details
    var usAsBillingSelector = '#dwfrm_shippingbilling_singleshipping_shippingAddress_useAsBillingAddress';
    var $formElmts = $('[name*=shippingbilling_singleshipping]').not(usAsBillingSelector);
    if (formtohide == 'billing') {
        var $formElmts = $('[name*=shippingbilling_billing]');
    }

    $.each($formElmts, function () {
        var $this = $(this);
        $this.closest('.form-row').addClass('hidden');
        $this.closest('.select-country').addClass('hidden');
    });
    $('input[name$="' + formtohide + '_input_country"]').addClass('hidden');
    $('input[name$="dwfrm_profile_address_companyName"]').closest('.form-row').addClass('hidden');
}

/**
 * @function
 * @description shows specified form
 * @param {String} form to show
 */
function showForm(formtoshow) {

    // show form details
    var $formElmts = $('[name*=shippingbilling_billing]');
    if (formtoshow == 'shipping') {
        var $formElmts = $('[name*=shippingbilling_singleshipping]');
    }

    $.each($formElmts, function () {
        var $this = $(this);
        $this.closest('.form-row').removeClass('hidden');
        $this.closest('.select-country').removeClass('hidden');
    });
    $('input[name$="' + formtoshow + '_input_country"]').removeClass('hidden');
    $('input[name$="dwfrm_profile_address_companyName"]').closest('.form-row').removeClass('hidden');
    $.fn.select2.defaults.set('theme', 'bootstrap');
    $('.shipping-address-form .select-country .input-select.country , .billing-address-form .select-country .input-select.country').select2({
        minimumResultsForSearch: -1
    });
    $('.state-ca-code .input-select , .state-us-code .input-select ').select2({
        minimumResultsForSearch: -1,
    });
    // $('.shipping-address-form .select-country .input-select.country , .billing-address-form .select-country .input-select.country').val('FR').trigger('change');
    $('.country-input-holder, .country-input-holder-checkout').each(function () {
        if ($(this).val() === '') {
            $(this).parent().find('.select2-selection__rendered').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');
        }
    });
}


/**
 * @function
 * @description uncheck other addresses in the address list
 * @param {String} id checkbox to skip
 */
function uncheckInputs(id) {
    var $inputsToUncheck = $('#' + id).closest('.list-blocs-width-check-list').find('.input-radio').not('#' + id);
    $.each($inputsToUncheck, function () {
        var $that = $(this);
        if ($that.is(':checked')) {
            $that.trigger('click');
        }
    });
}



/**
 * @function
 * @description uncheck other addresses in the address list
 * @param {String} id checkbox to skip
 */
function uncheckCreditCardInputs(id) {
    var $inputsToUncheck = $('#' + id).closest('.credit-card-list').find('.input-radio').not('#' + id);
    $.each($inputsToUncheck, function () {
        var $that = $(this);
        if ($that.is(':checked')) {
            $that.trigger('click');
        }
    });
}

/**
 * @function
 * @description check checkbox save address by default
 * @param {String} address type
 */
function checksaveadr(addresstype) {
    if (addresstype) {
        var $elementtocheck = $('input[id$=' + addresstype + 'Address_addToAddressBook]');
        if (!$elementtocheck.is(':checked')) $elementtocheck.trigger('click');
    } else {
        var $elementtocheck1 = $('#dwfrm_shippingbilling_singleshipping_shippingAddress_addToAddressBook');
        var $elementtocheck2 = $('#dwfrm_shippingbilling_billing_billingAddress_addToAddressBook');
        if (!$elementtocheck1.is(':checked')) $elementtocheck1.trigger('click');
        if (!$elementtocheck2.is(':checked')) $elementtocheck2.trigger('click');
    }
}


/**
 * @function
 * @description sets old address id
 * @param {String} address type
 */
function setoldaddressid(addresstype) {
    var addressid = $('input[id$=' + addresstype + 'Address_addressFields_addressid]').val(),
        $oldaddressid = $('input[id$=' + addresstype + 'Address_addressFields_oldaddressid]');
    $oldaddressid.val(addressid);
}

/**
 * @function
 * @description updates country select label
 * @param {String} address type
 */
function setSelectCountryLabel(addresstype) {

    //init select country label
    addresstype = addresstype == 'billing' ? 'billing_billing' : 'singleshipping_shipping';
    $('#select2-dwfrm_shippingbilling_' + addresstype + 'Address_addressFields_country-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');

}




/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    if ($('.payment-method-options').length) {
        $('.pt_checkout  #secondary .checkout-mini-cart .mini-cart-pricing.qte').addClass('show');
    }
    var $checkoutForm = $('#dwfrm_shippingbilling');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
    var $usAsBillingFormSelector = $('#dwfrm_shippingbilling_singleshipping_shippingAddress_useAsBillingAddress');
    var $form = $('.address');
    var init = true;
    showProductsSummary();
    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    //default check save address
    checksaveadr();

    //set default country
    var countryName = $('select[id*="shippingAddress_addressFields_country"]').find('option:selected').html();
    if (countryName) $('input[name$="shipping_input_country"]').val(countryName);
    countryName = $('select[id*="billingAddress_addressFields_country"]').find('option:selected').html();
    if (countryName) $('input[name$="billing_input_country"]').val(countryName);

    //card expiration select from current year
    var todayDate = new Date(); var year = todayDate.getFullYear();
    $('.cart-year .input-select option').filter(function () {
        return $(this).val() && $(this).val() < year;
    }).remove();

    // @STORY-ID LGEP-162
    var $ccContainer = $($checkoutForm).find('.payment-method').filter(function () {
        return $(this).data('method') == 'CREDIT_CARD';
    });
    $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    $($checkoutForm).find('input[name*="_number"]').val('');


    $ccContainer.find('input[name*="_number"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('input[name$="_owner"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('select[name$="creditCard_type"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });

    $ccContainer.find('select[name*="expiration"]').on('change', function (e) {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');

        var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();
        var cardNumber = $($checkoutForm).find('input[name*="_number"]').val();
        if (cardNumber.indexOf('****') != -1 && selectedPaymentMethodID == 'SA_SILENTPOST') {
            $($checkoutForm).find('input[name*="_number"]').val('');
        }

    });

    var $ccNum = $ccContainer.find('input[name$=\'_number\']');	// default payment method to 'CREDIT_CARD'


    // default payment method to 'CREDIT_CARD'
    var defaultPM = 'CREDIT_CARD';
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : defaultPM);
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updateSelectedBrand($(this).val());
        if ($(this).hasClass('updatePaymentMethod')) {
            updatePaymentMethod($(this).val());
            $(this).removeClass('updatePaymentMethod');
        }
    });
    $(document).on('click', '#reloadIngenicoIframe', function () {
        updatePaymentMethod(defaultPM);
    });

    //hide the shipping adress on page load
    //hideForm('shipping');

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        /* @STORY-ID LGEP-162 
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID); */

        // @STORY-ID LGEP-162
        if (!cardUUID) {
            $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
            return;
        }
        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
        if (!$selectPaymentMethod.find(':checked').parent().hasClass('expired')) {
            populateCreditCardForm(cardUUID, selectedPaymentMethod);
            $('.required.error').removeClass('error');
            $('.text-error').remove();
        }
    });

    //    $('.credit-card-list .item  label').on('click', function () {
    //        var cardUUID = $(this).parents('.item').find('input[type="checkbox"]').val();
    //
    //        if (!cardUUID) {
    //            return;
    //        }
    //        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
    //        populateCreditCardForm(cardUUID, selectedPaymentMethod);
    //        //populateCreditCardForm(cardUUID);
    //
    //
    //        // remove server side error
    //        $('.required.error').removeClass('error');
    //        $('.text-error').remove();
    //    });

    $('.credit-card-list .item  .edit-cart').on('click', function () {

        if (!$(this).parents('.item').hasClass('expired')) {
            $('.credit-card-list .item ').each(function (index, value) {
                $('#loop-' + index).prop('checked', false);
            });
            var cardUUID = $(this).parents('.item').find('input[type="checkbox"]').val();
            if (!cardUUID) {
                return;
            }
            $('.credit-card-list .item  .edit-cart').show();
            $(this).hide();
            $('html,body').animate({
                scrollTop: $('.payment-method.payment-method-expanded').offset().top - 120 + 'px'
            },
                'slow');

            $(this).parents('.item').find('input[type="checkbox"]').prop('checked', true);

            populateCreditCardForm(cardUUID);
            // remove server side error
            $('.required.error').removeClass('error');
            $('.text-error').remove();
        }
        return;

    });

    var cartcvnlist = $('.cart-cvn-list');

    // click credit card radio button
    $('input[id*=loop-]').on('click', function (e) {
        if (!$(this).parent().hasClass('expired')) {
            $('.credit-card-list .item .edit-cart').show();
            var $this = $(this);
            var id = $this.attr('id');
            if ($this.is(':checked')) {
                uncheckCreditCardInputs(id);
                cartcvnlist.addClass('hide');
                showHideCreditCardFormInput(false);
                var cardUUID = $(this).parents('.item').find('input[type="checkbox"]').val();
                if (!cardUUID) {
                    return;
                }
                var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
                populateCreditCardForm(cardUUID, selectedPaymentMethod);

                $('#' + id).parent().find('.cart-cvn-list').removeClass('hide');

                $('#' + id).closest('.credit-card-list .item  .edit-cart').show();
                // remove server side error
                $('.required.error').removeClass('error');
                $('.text-error').remove();
                return;
            } else {
                var $inputsToUncheck = $('#' + id).closest('.credit-card-list').find('.input-radio').not('#' + id);
                cartcvnlist.addClass('hide');

                var clearForm = true;
                $.each($inputsToUncheck, function () {
                    var $that = $(this);
                    if ($that.is(':checked')) {
                        clearForm = false;
                    }
                });

            }
        } else {
            $(this).removeAttr('checked');
        }
    });

    $('#shownewpaiement').on('click', function (e) {

        $('input[id*=loop-]').prop('checked', false);

        cartcvnlist.addClass('hide');

        var clearForm = true;
        showHideCreditCardFormInput(true);


        clearCCFields();


        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_owner').attr('readonly', false);
        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_number').attr('readonly', false);
        $('#select2-dwfrm_billingwithoutadr_paymentMethods_creditCard_expiration_month-container').attr('readonly', false);
        $('#select2-dwfrm_billingwithoutadr_paymentMethods_creditCard_expiration_year-container').attr('readonly', false);

        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_saveCard').prop('checked', false);
        $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_saveCard').closest('div.form-row').show();

        enableSelect('.cart-year');
        enableSelect('.cart-month');




    });



    $('select[id*="Address_addressFields_country"]').on('change', function (e) {
        var $this = $(this);
        var countryID = $this.val();
        var countryName = $this.find('option:selected').html();
        var id = $this.attr('id');
        var addresstype = id.indexOf('billingAddress') !== -1 ? 'billing' : 'shipping';
        $('input[name$="' + addresstype + '_input_country"]').val(countryName);
        setSelectCountryLabel(addresstype);
        if (addresstype === 'billing') {
            var countrycode = $('select[id*="billingAddress_addressFields_country"]').find('option:selected').val();
            if (countrycode && countrycode === 'US') {
                $('.state-us-code').removeClass('hide');
                $('.state-ca-code').addClass('hide');
            }
            else if (countrycode && countrycode === 'CA') {
                $('.state-us-code').addClass('hide');
                $('.state-ca-code').removeClass('hide');
            }
            else {
                $('.state-us-code').addClass('hide');
                $('.state-ca-code').addClass('hide');
            }

        }
    });
    $('select[id*="Address_addressFields_country"]').trigger('change');


    $usAsBillingFormSelector.on('click', function (e) {
        var usAsBillingAddress = $(this).is(':checked');
        if (!usAsBillingAddress) {
            showForm('billing');
            formPrepare.validateForm();
            return;
        }
        uncheckInputs('create_billing_addr');
        hideForm('billing');
        populateBillingForm();
        $('input[id$=billingAddress_addressFields_oldaddressid]').val('');
        formPrepare.validateForm();
    });

    var addrToSelect = $('#billingAddrToSelect').val();
    var index = addrToSelect == 0 ? addrToSelect : addrToSelect - 1;
    if (addrToSelect && !$('input[id*=billing_radio]').eq(index).is(':checked') && !$usAsBillingFormSelector.is(':checked')) {
        $('input[id*=billing_radio]').eq(index).trigger('click');
        hideForm('billing');
    } else if ($usAsBillingFormSelector.is(':checked')) {
        hideForm('billing');
    } else {
        $usAsBillingFormSelector.trigger('click');
    }


    $('#dwfrm_shippingbilling').on('submit', function (event) {
        var $elementtocheck1 =  document.getElementById('dwfrm_shippingbilling_singleshipping_shippingAddress_addToAddressBook');
        var $btnsaveshippingadress = $('#create_shipping_addr').data('maxadress');
        $('#shipping-address-max-error').html('');
        $('#billing-address-max-error').html('');
        if (!$('.shipping-address-form .civility-radios').hasClass('hidden') && $elementtocheck1.checked && $btnsaveshippingadress) {
            event.preventDefault();
            $('#shipping-address-max-error').html(Resources.SHIPPING_ADDRESS_MSG_ERROR);
            return;
        }

        var $elementtocheck2 =  document.getElementById('dwfrm_shippingbilling_billing_billingAddress_addToAddressBook');
        var $btnsavebillingadress = $('#create_billing_addr').data('maxadress');
        if (!$('.billing-address-form .civility-radios').hasClass('hidden') && $elementtocheck2.checked && $btnsavebillingadress) {
            event.preventDefault();
            $('#billing-address-max-error').html(Resources.BILLING_ADDRESS_MSG_ERROR);
            return;
        }

    });

    $('#create_billing_addr').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: '+=600'}, 800);
        if ($(this).hasClass('disabled')) return;
        clearForm('billing');
        showForm('billing');
        $('.billing-address-form .select-country .input-select.country').val('').trigger('change');
        uncheckInputs('create_billing_addr');
        if ($usAsBillingFormSelector.is(':checked')) $usAsBillingFormSelector.prop('checked', false);
        setSelectCountryLabel('billing');
        setSelectCountryLabel('shipping');
        formPrepare.validateForm();
    });

    $('#create_shipping_addr').on('click', function (e) {
        e.preventDefault();
        var deviceSize = $(window).width();
        if (deviceSize >= 1001) {
            $('html, body').animate({
                scrollTop: $(".civility-radios").offset().top - 110
            }, 800);
        } else {
            $('html, body').animate({
                scrollTop: $(".civility-radios").offset().top - 70
            }, 800);
        }
        if ($(this).hasClass('disabled')) return;
        clearForm('shipping');
        showForm('shipping');
        $('.shipping-address-form .select-country .input-select.country').val('').trigger('change');
        uncheckInputs('create_shipping_addr');
        setSelectCountryLabel('shipping');
        setSelectCountryLabel('billing');
        formPrepare.validateForm();
    });

    $('.edit-link a').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: '+=600'}, 750);
        var $this = $(this);
        var id = $this.attr('id');
        var addresstype = id.indexOf('billing') !== -1 ? 'billing' : 'shipping';
        var selectedAddress = $(this).parent().siblings('input[id^=' + addresstype + '_radio]').data('address');
        if (!selectedAddress) {
            return;
        }

        uncheckInputs(id);
        if ($usAsBillingFormSelector.is(':checked') && addresstype == 'billing') $usAsBillingFormSelector.trigger('click');


        util.fillAddressFields(selectedAddress, $form, addresstype);


        setoldaddressid(addresstype);
        checksaveadr(addresstype);
        shipping.updateShippingMethodList();
        showForm(addresstype);
        setSelectCountryLabel(addresstype);
        formPrepare.validateForm();
        $('input.input-text,select.input-select').filter(function () {
            return this.value;
        }).parent('.field-wrapper').prev('label').children('.form-name').show();
    });

    $('input[id*=_radio]').on('click', function (e) {
        var $this = $(this);
        var id = $this.attr('id');
        var addresstype = id.indexOf('billing') !== -1 ? 'billing' : 'shipping';
        if ($this.is(':checked')) {
            if (addresstype == 'billing') $usAsBillingFormSelector.prop('checked', false);
            uncheckInputs(id);
            var selectedAddress = $this.data('address');
            if (!selectedAddress) {
                return;
            }
            util.fillAddressFields(selectedAddress, $form, addresstype);
            $('input[id$=' + addresstype + 'Address_addressFields_oldaddressid]').val('');
            shipping.updateShippingMethodList();
            hideForm(addresstype);
            formPrepare.validateForm();
        } else {
            clearForm(addresstype);
            showForm(addresstype);
        }
    });

    // @STORY-ID LGEP-162
    // Secure Acceptance Redirect or iframe payment method : on selection change of saved credit card
    $('.creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {
            return;
        }

        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
        if (!$selectPaymentMethod.find(':checked').parent().hasClass('expired')) {
            populateCreditCardForm(cardUUID, selectedPaymentMethod);
            // remove server side error
            $('.required.error').removeClass('error');
            $('.text-error').remove();
        }

    });


    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $form = $('.address');
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find('.giftcert-error');
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    if (!$('.credit-card-list .item:first').hasClass('expired')) {
        $('.credit-card-list .item:first  .input-radio').trigger('click');
    }

    $('.delete-card-link').on('click', function (e) {
        e.preventDefault();
        var Alias = $(this).data('alias');
        var divToRemove = $(this).parent().parent();

        var url = util.appendParamsToUrl(Urls.removeIngenicoCard, {aliasId: Alias, format: 'ajax'});
        $.getJSON(url, function (data) {
            if (data && data.success && data.success == true) {
                divToRemove.remove();
            } else if (data.error) {
                alert('error');
            }

        });


    });

    $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_acceptCgv').on('click', function (e) {
        setTimeout(function () {
            $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_acceptCgv-error').remove();
        });

    });


    $('input[name*=\'creditCard_cvn_\']').on('keypress keyup blur', function (event) {
        $(this).val($(this).val().replace(/[^\d].+/, ''));
        if ((event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    $('input[name*=\'_creditCard_number\']').on('keypress keyup blur', function (event) {
        $(this).val($(this).val().replace(/[^\d].+/, ''));
        if ((event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    $('input[name^=\'creditCard_cvn_\']').on('blur', function (event) {
        $(this).val($(this).val().replace(/[^\d].+/, ''));

        $('input[name*=\'_creditCard_cvn_\']').val('');
        $('input[name*=\'_creditCard_cvn_\']').val($(this).val());

    });


    $('.secureacceptance').on('click', function (e) {

        var $selectPaymentMethod = $('.payment-method-options');
        var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
        setTimeout(function () {
            $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_acceptCgv-error').remove();
        });

        var cgvinput = $('.checkout-billing').find('input[name$="_creditCard_acceptCgv"]').is(':checked');
        if (!cgvinput) {
            setTimeout(function () {
                $('#dwfrm_billingwithoutadr_paymentMethods_creditCard_acceptCgv-error').remove();
                $('.acceptCgv-error').addClass('show  right');
            });

        }
        else {
            $('.acceptCgv-error').removeClass('show  right');
            if (SitePreferences.ENABLE_INGENICO === true) {
                if (intervalHandle) {
                    clearInterval(intervalHandle);
                }
            }
        }

        if ('SA_SILENTPOST' == selectedPaymentMethod) {
            var $checkoutForm = $('.checkout-billing');
            var ccnumber = $($checkoutForm).find('input[name$="_creditCard_number"]').val();
            ccnumber = ccnumber.replace(/ /g, '');
            var cvn = $($checkoutForm).find('input[name$="_creditCard_cvn"]').val();
            var month = $('.payment-method-expanded .month select').val();
            var expyear = $('.payment-method-expanded .year select').val();
            var dwcctype = $('.payment-method-expanded .cctype select').val();
            var savecc = $($checkoutForm).find('input[name$="_creditCard_saveCard"]').is(':checked');
            var customerEmail = $('#dwfrm_billing_billingAddress_email_emailAddress').val();

            var cardmap = {'Visa': '001', 'Amex': '003', 'MasterCard': '002', 'Discover': '004', 'Maestro': '042'};
            if (month.length == 1) {
                month = '0' + month;
            }
            var cctype = cardmap[dwcctype];

            var firstname = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_firstName"]').val());
            var lastname = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_lastName"]').val());
            var companyname = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_companyName"]').val());
            var address1 = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_address1"]').val());
            var address2 = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_address2"]').val());
            var city = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_city"]').val());
            var zipcode = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_postal"]').val());
            var country = encodeRequestFieldValue($($checkoutForm).find('select[name$="_addressFields_country"]').val());
            var state = $($checkoutForm).find('select[name$="_addressFields_states_state"]').val();
            if (state === undefined) {
                //TODO
                //state = $($checkoutForm).find('input[name$="_addressFields_states_state"]').val();
                state = '';
            }
            //state = encodeRequestFieldValue(state);
            state = '';

            var phoneno = encodeRequestFieldValue($($checkoutForm).find('input[name$="_addressFields_phone"]').val());
            var cctoken = encodeRequestFieldValue($('[data-method="CREDIT_CARD"]').find('[name$="creditCard_selectedCardID"]').val());

            var validCardType = dwcctype.toLowerCase();
            var validCardNumber = $.payment.validateCardNumber(ccnumber);
            var validCardCvv = $.payment.validateCardCVC(cvn, validCardType);
            var validCardExp = $.payment.validateCardExpiry(month, expyear);
            if (cctoken) {
                validCardNumber = true;
            }

            $($checkoutForm).find('input[name$="_creditCard_number"]').val('');
            $($checkoutForm).find('input[name$="_creditCard_cvn"]').val('');
            $($checkoutForm).find('input[name$="_creditCard_expiration_month"]').val('');
            $($checkoutForm).find('input[name$="_creditCard_expiration_year"]').val('');
            $($checkoutForm).find('input[name$="_creditCard_type"]').val('');
            alert(validCardCvv);
            alert(validCardExp);
            alert(validCardNumber);

            if (validCardCvv && validCardExp && validCardNumber) {
                var data = {
                    custemail: customerEmail,
                    savecc: savecc,
                    firstname: firstname,
                    lastname: lastname,
                    companyname: companyname,
                    address1: address1,
                    address2: address2,
                    city: city,
                    zipcode: zipcode,
                    country: country,
                    state: state,
                    phone: phoneno,
                    cctoken: cctoken,
                    format: 'ajax'
                };
                $.ajax({
                    url: Urls.silentpost,
                    type: 'POST',
                    data: data,
                    success: function (xhr, data) {
                        if (xhr) {
                            if (xhr.error == true) {
                                $('#saspCardError').html(xhr.errorMsg);
                                $('#saspCardError').addClass('error');
                            }
                            else {
                                $('#secureAcceptancePost').html(xhr);
                                $('#card_expiry_date').val(month + '-' + expyear);
                                $('#card_type').val(cctype);
                                $('#card_cvn').val(cvn);
                                if (cctoken == null || cctoken == '') {
                                    $('#silentPostFetchToken').append('<input type="hidden" id="card_number" name="card_number" />');
                                    $('#card_number').val(ccnumber);
                                }
                                $('#silentPostFetchToken').submit();
                            }
                        }
                        else {
                            $('#saspCardError').html(Resources.INVALID_SERVICE);
                            $('#saspCardError').addClass('error');
                        }
                    },
                    error: function () {
                        $('#saspCardError').html(Resources.INVALID_SERVICE).addClass('error');
                    }
                });
            }
            else {
                $('#saspCardError').html(Resources.INVALID_CREDITCARD);
                $('#saspCardError').addClass('error');

                return false;
            }
        }
        else {

            var _cardDate = '';
            /* var _cardMonth = $('.cart-month').attr('card-month');
             var _cardYear = $('.cart-year').attr('card-year');*/
            var _cardMonth = $('select[name$="_creditCard_expiration_month"] :selected').val();
            var _cardYear = $('select[name$="_creditCard_expiration_year"] :selected').val();
            if (_cardMonth && _cardYear) {
                var lastDayOfMonth = new Date(_cardYear, _cardMonth, 0).getDate();
                _cardDate = _cardMonth + '/' + lastDayOfMonth + '/' + _cardYear;
                var _dateCard = new Date(_cardDate);
                var todayDate = new Date();
                todayDate.setHours(0, 0, 0, 0);
                if (_dateCard < todayDate) {
                    $('#dwfrm_cardexpdate-error').removeClass('hide');
                } else {
                    $('#dwfrm_cardexpdate-error').addClass('hide');
                    $('.secureacceptance').prop('type', 'submit').submit();
                }
            } else {
                $('.secureacceptance').prop('type', 'submit').submit();
            }


            /*$('.secureacceptance').prop("type", "submit").submit();*/
            var $checkoutForm = $('.checkout-billing');
            var ccnumber = $($checkoutForm).find('input[name$="_creditCard_number"]').val();
            if (ccnumber) {
                ccnumber = ccnumber.replace(/ /g, '');
            }



            return true;
        }
    });


    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    $('.add-credit-card  a').on('click', function () {
        $('.add-credit-card').hide();
        $('.payment-method.payment-method-expanded input').val('');
        $('html,body').animate({
            scrollTop: $('.payment-method.payment-method-expanded').offset().top - 120 + 'px'
        }, 'slow'
        );
        $('.credit-card-list .item ').each(function (index, value) {
            $('#loop-' + index).prop('checked', false);
        });
        return;
    });

    $.fn.select2.defaults.set('theme', 'bootstrap');
    $('.cart-month .input-select .select-option:first-child').attr('value', '0');
    $('.cart-month .input-select .select-option:first-child').attr('disabled', 'disabled');
    $('.cart-year .input-select .select-option:first-child').attr('value', '0');
    $('.cart-year .input-select .select-option:first-child').attr('disabled', 'disabled');
    $('.cart-month .input-select').on('change', function (e) {
        $('.cart-month  label span.error').addClass('hide');
    });
    $('.cart-year .input-select').on('change', function (e) {
        $('.cart-year  label span.error').addClass('hide');

    });
    $('.cart-month .input-select').select2({
        minimumResultsForSearch: -1,
        placeholder: 'MM'
    });
    $('.cart-year .input-select').select2({
        minimumResultsForSearch: -1,
        placeholder: 'AAAA'
    });


    $('.checkout-billing .form-row .field-wrapper input.input-text').on('focus', function () {

        var elem = $(this);
        setTimeout(function () {
            //elem.parents('.form-row').find('label').addClass('animate-label');
            elem.parent().addClass('focused');
        }, 0);
    });
    $('.checkout-billing .form-row .field-wrapper input.input-text').on('focusout', function () {
        var elem = $(this);
        setTimeout(function () {
            // elem.parents('.form-row').find('label').removeClass('animate-label');
            elem.parent().removeClass('focused');
        }, 0);
    });


    var code = $('.checkout-billing .form-row.checkbox > label span').text();
    var attr = $('.checkout-billing .form-row.checkbox > label').attr('for');
    $('.checkout-billing .form-row.checkbox  .field-wrapper').append('<label for=' + attr + '>' + code + '</label>');
    $('.checkout-billing .form-row.checkbox > label').remove();

    if ($('.billing-coupon-code').length) {
        $('#secondary').addClass('isPaiementPage');
    }




};
