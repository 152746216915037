'use strict';

var util = require('../../util');
var shipping = require('./shipping');
// var QASAddressShipping = require('./QASAddressShipping');
// var QASAddressBilling = require('./QASAddressBilling');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form, "");
        shipping.updateShippingMethodList();
        // re-validate the form
        $form.validate().form();
    });
    
    /*
    $(document).ready(function() { 
        // QAS search default
        QASAddressShipping.init('dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_', 'shipping');
        QASAddressBilling.init('dwfrm_shippingbilling_billing_billingAddress_addressFields_', 'billing');
        
        $(document).on('change', '#dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_country', function() {
        	if ($(this).val() != '' && $(this).val().toUpperCase() === 'FR') {
        		QASAddressShipping.init('dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_', 'shipping');
        		QASAddressShipping.enable();
        	} else {
        		QASAddressShipping.disable();
        	}
        });
        $(document).on('change', '#dwfrm_shippingbilling_billing_billingAddress_addressFields_country', function() {
        	if ($(this).val() != '' && $(this).val().toUpperCase() === 'FR') {
        		QASAddressBilling.init('dwfrm_shippingbilling_billing_billingAddress_addressFields_', 'billing');
        		QASAddressBilling.enable();
        	} else {
        		QASAddressBilling.disable();
        	}
        });
    });
    */


};
