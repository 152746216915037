'use strict';

var progress = require('../progress'),
    util = require('../util');
 

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	$('#filtreAplphaNum').on('click', 'ul li a', function (e) {
		var id = $(this).attr('id');
		
		$('#filtreAplphaNum ul li a').each(function () {
			$(this).removeClass('text-accent') ;
		});
		$(this).addClass('text-accent');
		
    	if (id == "afficherMarques") {
    		$('.listeMarques ul li').each(function () {
    			 $(this).parents('.listeMarques').show();
    		});
    	} else {
    		$('.listeMarques ul li').each(function () {
				if ($(this).attr('id') == 'lettre_' + id || $(this).attr('id') == 'marque_' + id ) {
					$(this).parents('.listeMarques').show();
				} else {
					$(this).parents('.listeMarques').hide();
				}
    		});
    	}
    });	
}

exports.init = function () {    
    initializeEvents();
};
