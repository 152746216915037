'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    addToCart = require('./product/addToCart'),
    util = require('../util'),
    lazyload = require('../lazyload'),
    app = require('../app');

	window.infiniteScrollPage = 0;
	
	function replaceURL(pageURL) {
		if(pageURL) {
			var stateObj = { path: pageURL };
			window.history.replaceState(stateObj, "", pageURL);
			
		}
	}
	
	function isOnScreen(element){
	    var win = $(window);
	    var fitScreen = win.height() / 3;
	    var viewport = {
	        top : win.scrollTop() + fitScreen,
	        left : win.scrollLeft()
	    };
	    
	    viewport.right = viewport.left + win.width();
	    viewport.bottom = viewport.top + win.height() - fitScreen;
	 
	    var bounds = element.offset();
	    bounds.right = bounds.left + element.outerWidth();
	    bounds.bottom = bounds.top + element.outerHeight();
	 
	    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    }
    
    var scrollPreviousPosition = $(window).scrollTop();
    
    function updateUrlPageParam(currentPage) {
        var absUrl = window.location.pathname;
        if(window.location.search.indexOf("page="+currentPage) < 0){
            if (currentPage > 1) {
                var url = absUrl + "?page=" + currentPage;
                window.history.pushState({ path: url }, '', url);
            } else {
                window.history.pushState({ path: absUrl }, '', absUrl);
            }
        }
        updateAddToCartPosition();
        updateProductImagesHeight();
    }
    
    function updateUrlPageOnScroll() {
    	
    	var scrollDirection = 'up';
        var scrollCurrentPosition = $(window).scrollTop();
        if (scrollCurrentPosition > scrollPreviousPosition) {
            scrollDirection = 'down';
        }
        
        var scrollCurrentBottomPosition = $(window).scrollTop() + $(window).height();
        
        var productGridRange = [];
        var grids = document.querySelectorAll('div[class=gridproductdivider]');
        var i = 0;
        Array.prototype.forEach.call(grids, function (element) {
            var top = element.offsetTop;
            var nextElement = grids[i+1];
            if(nextElement){
            	var bottom = nextElement.offsetTop;
            	productGridRange.push({ from: top, to: bottom });
            }else{
            	var bottom = $(document).height();
            	productGridRange.push({ from: top, to: bottom });
            }
            i++;
        });
        
        const targetGrid = productGridRange.map(e => (scrollCurrentBottomPosition >= e.from) && (scrollCurrentBottomPosition <= e.to));
        var index = targetGrid.indexOf(true);
        var element = $(grids[index]);
        var page = parseInt(element.data("page"));
        if (page >= 1) {
            updateUrlPageParam(page + 1);
        }else{
        	updateUrlPageParam(1);
        }
        updateAddToCartPosition();
        updateProductImagesHeight();
        scrollPreviousPosition = $(window).scrollTop();
    }

function infiniteScroll() {
	updateUrlPageOnScroll();
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');

        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function(html) {
            var count = 0;
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            if( $('.modernlayout').length){
                $('div.search-result-content .modernlayout').append(html);
            } else {
                $('div.search-result-content').append(html);
            }

            var perpage = SitePreferences.SHOWMORE;
            if(!$('#scrolledToPage').length && $('#scrollToPage').length){
            	var strt = parseInt($('#scrollToPage').data('page')) * perpage;
            	var newStart = parseInt(strt);
            }else{
            	var newStart = parseInt(loadingPlaceHolder.attr('data-start')) + perpage;
            }
            var valueStart = parseInt(loadingPlaceHolder.attr('data-start'));
            var dataFinish = parseInt(loadingPlaceHolder.attr('data-finish'));
            if(!$('#scrolledToPage').length && $('#scrollToPage').length){
            	var currentPage = parseInt($('#scrollToPage').data('page'));
            }else{
            	var currentPage = parseInt(valueStart / perpage);
            }
            
            var page = $('div.search-result-content').children().last();
            page.find("input#currentPage").val(currentPage);
            
            $('div.search-result-content').append(('<div class="gridproductdivider" id="gridproductdivider-'+(currentPage+1)+'" data-page="'+(currentPage+1)+'"></div>'));
            
            if (newStart < dataFinish) {
                loadingPlaceHolder.attr('data-start', newStart);
                loadingPlaceHolder.attr('data-loading-state', 'unloaded');
                newStart = 'start=' + newStart;

                gridUrl = gridUrl.replace(/start=\d+/, newStart);
                loadingPlaceHolder.attr('data-grid-url', gridUrl);

                if ((valueStart - dataFinish) <= 0) {
                    $("#linkNext").attr("href", (gridUrl + "&page=" + (currentPage + 1)));
                    $("#linkPrevious").attr("href", (gridUrl + "&page=" + (currentPage - 1)));
                }

            } else {
                $('.loader-readMore').hide();
            }

        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function(response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                    // alert(response);
                    // console.log(response);

                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                fillEndlessScrollChunk(response);
                productTile.init();
                lazyload.init();

            }
        });
    }
}

/**
 * init slots content
 *
 * @returns
 */
function initSlotsContent() {
    // check if grid-tile contains more then one slotcontent 
    $('.grid-tile').each(function(item, idx) {
        var childs = $(this).find('.html-slot-container');
        if (childs.length == 2) {
            if ($(childs[0]).html() != '') {
                $(childs[0]).show();
                $(childs[1]).hide();
                $(childs[1]).empty().remove();
            } else if ($(childs[1]).html() != '') {
                $(childs[1]).show();
                $(childs[0]).empty().remove();
            }
        } else if (childs.length == 1) {
            $(childs[0]).show();
        }
    });
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */

function updateProductListingFilter(url, historyParamValue, historyParamcat, action, historyfiltercat) {
    if (!url || url === window.location.href) {
        return;
    }

    progress.show($('.search-result-content'));
    if (url.indexOf(Urls.homeUrl) !== -1) {
        window.location.href = url;
        return;
    }
    $.ajax({
        type: 'GET',
        url: util.ajaxUrl(action),
        data: {historyParamValue:historyParamValue, historyParamUrl:url,historyParamcat:historyParamcat, historyfiltercat:historyfiltercat},
        success: function(response) {    
            $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function() {
                compareWidget.init();
                progress.hide();
                history.pushState(undefined, '', url);
                if ($('.slide-container').length) {

                    $('.slide-container').slick({
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        swipe: false,
                        infinite: false,
                        responsive: [{
                            breakpoint: 1001,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                margin: 5,
                                infinite: true,
                                swipe: true
                            }
                        }]
                    });
                }
                productTile.init();
                // update UI
                if ($(document.body).attr('data-tile_isList') == 'true') {
                    $('.search-result-content').addClass('wide-tiles');
                    $('.toggle-grid .viewlist').addClass('wide');
                    $('.toggle-grid .viewmosa').removeClass('wide');
                    $('.grid-tile .push-item').closest('.grid-tile').addClass('hide');
                }
                app.init();
                // init slot content after refine
                initSlotsContent();
                lazyload.init();
                if ($(window).width() <= 1200) {
                    $('.menu-swipe').css('padding-top', '50px');
                }
                var $headePlpBloc1 = $(".header-plp-bloc-1");
                var spanNav = "<span></span>";
                var owl_cp5 = $headePlpBloc1.find('.cp_5_container .owl-carousel');
                var owl_cp3 = $headePlpBloc1.find('.cp_3_container .owl-carousel');
                var owl_cp4 = $headePlpBloc1.find('.cp_4_container .owl-carousel');
                var owl_cp14 = $headePlpBloc1.find('.layout_carousel_cp14 .owl-carousel');
                if(owl_cp5.length) {
                    owl_cp5.owlCarousel({
                        loop:false,
                        nav:true,
                        margin:10,
                        responsiveClass:true,
                        navText: [spanNav,spanNav],
                        responsive:{
                            0:{
                                items:2,
                                nav:false,
                                dots:true
                            },
                            600:{
                                items:2,
                                nav:false,
                                dots:true
                            },
                            1000:{
                                items:4,
                                nav:true,
                                loop:false
                            }
                        }
                    });
                }
                if(owl_cp3.length) {
                    owl_cp3.owlCarousel({
                        loop:false,
                        nav:true,
                        margin:10,
                        responsiveClass:true,
                        navText: [spanNav,spanNav],
                        responsive:{
                            0:{
                                items:2,
                                nav:false,
                                dots:true
                            },
                            600:{
                                items:2,
                                nav:false,
                                dots:true
                            },
                            1000:{
                                items:3,
                                nav:true,
                                loop:false
                            }
                        }
                    });
                }
                if(owl_cp4.length) {
                    owl_cp4.owlCarousel({
                        loop:false,
                        nav:false,
                        margin:60,
                        responsiveClass:true,
                        responsive:{
                            0:{
                                items:1,
                                loop:false,
                                nav:false,
                                dots:false,
                                stagePadding: 55,
                                margin:20
                            },
                            600:{
                                items:2,
                                loop:false,
                                nav:false,
                                dots:false,
                                stagePadding: 55,
                                margin:20
                            },
                            1000:{
                                items:2,
                                nav:false,
                                loop:false
                            }
                        }
                    });
                }
                if(owl_cp14.length) {
                    owl_cp14.owlCarousel({
                        loop:false,
                        nav:true,
                        margin:20,
                        responsiveClass:true,
                        items:3,
                        dots:false,
                        navText: [spanNav,spanNav],
                        responsive:{
                            0:{
                                items:1,
                                nav:false,
                                loop:true,
                                dots:false,
                                stagePadding: 55,
                                margin:8
                            },
                            600:{
                                items:2,
                                nav:true,
                                dots:false
                            },
                            1000:{
                                items:3,
                                nav:true,
                                loop:false,
                                dots:false,
                            }
                        }
                    });
                }

                if($headePlpBloc1.length) {
                    $headePlpBloc1.find("a").on("click", function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.href = $(this).attr("href");
                    });
                }
                reorganiseFilter();
                updateAddToCartPosition();
                updateProductImagesHeight();
            });
        }
    });
}

function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }

    progress.show($('.search-result-content'));
    if (url.indexOf(Urls.homeUrl) !== -1) {
        window.location.href = url;
        return;
    }
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function() {
        compareWidget.init();
        progress.hide();
        history.pushState(undefined, '', url);
        if ($('.slide-container').length) {

            $('.slide-container').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                swipe: false,
                infinite: false,
                responsive: [{
                    breakpoint: 1001,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        margin: 5,
                        infinite: true,
                        swipe: true
                    }
                }]
            });
        }
        productTile.init();
        // update UI
        if ($(document.body).attr('data-tile_isList') == 'true') {
            $('.search-result-content').addClass('wide-tiles');
            $('.toggle-grid .viewlist').addClass('wide');
            $('.toggle-grid .viewmosa').removeClass('wide');
            $('.grid-tile .push-item').closest('.grid-tile').addClass('hide');
        }

        // init slot content after refine
        initSlotsContent();
        lazyload.init();
        if ($(window).width() <= 1200) {
            $('.menu-swipe').css('padding-top', '50px');
        }
        /* ***************** Script : Menu Swipe LGE_V3 ***************** */
        if ($(".menu-swipe").length && $("#pnProductNav").length) {
        var SETTINGS = {
            navBarTravelling: false,
            navBarTravelDirection: "",
            navBarTravelDistance: 150
        }
        $('.menu-swipe #pnAdvancerLeft').hide();
        var pnAdvancerLeft = document.getElementById("pnAdvancerLeft");
        var pnAdvancerRight = document.getElementById("pnAdvancerRight");
        var pnProductNav = document.getElementById("pnProductNav");
        var pnProductNavContents = document.getElementById("pnProductNavContents");
        pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
        var last_known_scroll_position = 0;
        var ticking = false;
        function doSomething(scroll_pos) {
            pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
        }
        pnProductNav.addEventListener("scroll", function() {
            last_known_scroll_position = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function() {
                    doSomething(last_known_scroll_position);
                    ticking = false;
                });
            }
            ticking = true;
        });
        pnAdvancerLeft.addEventListener("click", function() {
            if (SETTINGS.navBarTravelling === true) {
                return;
            }
            if (determineOverflow(pnProductNavContents, pnProductNav) === "left" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
                var availableScrollLeft = pnProductNav.scrollLeft;
                if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
                    pnProductNavContents.style.transform = "translateX(" + availableScrollLeft + "px)";
                } else {
                    pnProductNavContents.style.transform = "translateX(" + SETTINGS.navBarTravelDistance + "px)";
                }
                pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
                SETTINGS.navBarTravelDirection = "left";
                SETTINGS.navBarTravelling = true;
            }
            pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
        });
        pnAdvancerRight.addEventListener("click", function() {
            if (SETTINGS.navBarTravelling === true) {
                return;
            }
            if (determineOverflow(pnProductNavContents, pnProductNav) === "right" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
                var navBarRightEdge = pnProductNavContents.getBoundingClientRect().right;
                var navBarScrollerRightEdge = pnProductNav.getBoundingClientRect().right;
                var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
                if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
                    pnProductNavContents.style.transform = "translateX(-" + availableScrollRight + "px)";
                } else {
                    pnProductNavContents.style.transform = "translateX(-" + SETTINGS.navBarTravelDistance + "px)";
                }
                pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
                SETTINGS.navBarTravelDirection = "right";
                SETTINGS.navBarTravelling = true;
            }
            pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
        });
        pnProductNavContents.addEventListener(
            "transitionend",
            function() {
                var styleOfTransform = window.getComputedStyle(pnProductNavContents, null);
                var tr = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
                var amount = Math.abs(parseInt(tr.split(",")[4]) || 0);
                pnProductNavContents.style.transform = "none";
                pnProductNavContents.classList.add("pn-ProductNav_Contents-no-transition");
                if (SETTINGS.navBarTravelDirection === "left") {
                    pnProductNav.scrollLeft = pnProductNav.scrollLeft - amount;
                } else {
                    pnProductNav.scrollLeft = pnProductNav.scrollLeft + amount;
                }
                SETTINGS.navBarTravelling = false;
            },
            false
        );
        pnProductNavContents.addEventListener("click", function(e) {
            var links = [].slice.call(document.querySelectorAll(".pn-ProductNav_Link"));
            links.forEach(function(item) {
                item.setAttribute("aria-selected", "false");
            })
            e.target.setAttribute("aria-selected", "true");
        });
        function determineOverflow(content, container) {
            var containerMetrics = container.getBoundingClientRect();
            var containerMetricsRight = Math.floor(containerMetrics.right);
            var containerMetricsLeft = Math.floor(containerMetrics.left);
            var contentMetrics = content.getBoundingClientRect();
            var contentMetricsRight = Math.floor(contentMetrics.right);
            var contentMetricsLeft = Math.floor(contentMetrics.left);
            var right_shaddow = $('.menu-swipe #pnAdvancerRight');
            var left_shaddow = $('.menu-swipe #pnAdvancerLeft');
            if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
                right_shaddow.show();
                left_shaddow.show();
                return "both";
            } else if (contentMetricsLeft < containerMetricsLeft) {
                right_shaddow.hide();
                left_shaddow.show();
                return "left";
            } else if (contentMetricsRight > containerMetricsRight || contentMetricsRight < containerMetricsRight) {
                left_shaddow.hide();
                right_shaddow.show();
                return "right";
            } else {
                return "none";
            }
        }

        function centerMenuSwipeContent() {
            var menuSwipeContent =  $("#pnProductNavContents");
            var screenSize = $(window).width();
            var menuSwipeContentWidth = menuSwipeContent.width();
            var links = menuSwipeContent.find('a.pn-ProductNav_Link');
            var right_arrow = $('.menu-swipe #pnAdvancerRight');
            var widthSum = 0;
            $.each( links, ( index, item ) => {
                widthSum += $(item).outerWidth();
            });
            if(screenSize - 110 > widthSum) {
                right_arrow.hide();
            } else {
                right_arrow.show();
            }
            if(menuSwipeContentWidth < screenSize - 110) {
                menuSwipeContent.css("float", "none");
            } else {
                menuSwipeContent.css("float", "left");
            }
        }
        $( document ).ready( function () {
            centerMenuSwipeContent();
        });
        $(window).on('resize', function () {
            centerMenuSwipeContent();
        });

        (function (root, factory) {
            if (typeof define === 'function' && define.amd) {
                define(['exports'], factory);
            } else if (typeof exports !== 'undefined') {
                factory(exports);
            } else {
                factory((root.dragscroll = {}));
            }
        }(this, function (exports) {
            var _window = window;
            var _document = document;
            var mousemove = 'mousemove';
            var mouseup = 'mouseup';
            var mousedown = 'mousedown';
            var EventListener = 'EventListener';
            var addEventListener = 'add'+EventListener;
            var removeEventListener = 'remove'+EventListener;
            var newScrollX, newScrollY;
            var dragged = [];
            var reset = function(i, el) {
                for (i = 0; i < dragged.length;) {
                    el = dragged[i++];
                    el = el.container || el;
                    el[removeEventListener](mousedown, el.md, 0);
                    _window[removeEventListener](mouseup, el.mu, 0);
                    //_window[removeEventListener](mousemove, el.mm, 0);
                }
                dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
                for (i = 0; i < dragged.length;) {
                    (function(el, lastClientX, lastClientY, pushed, scroller, cont){
                        (cont = el.container || el)[addEventListener](
                            mousedown,
                            cont.md = function(e) {
                                if (!el.hasAttribute('nochilddrag') ||
                                    _document.elementFromPoint(
                                        e.pageX, e.pageY
                                    ) === cont
                                ) {
                                    pushed = 1;
                                    lastClientX = e.clientX;
                                    lastClientY = e.clientY;
                                    e.preventDefault();
                                }
                            }, 0
                        );
                        _window[addEventListener](
                            mouseup, cont.mu = function() {pushed = 0;}, 0
                        );
                        _window[addEventListener](
                            //mousemove,
                            cont.mm = function(e) {
                                if (pushed) {
                                    (scroller = el.scroller||el).scrollLeft -=
                                        newScrollX = (- lastClientX + (lastClientX=e.clientX));
                                    scroller.scrollTop -=
                                        newScrollY = (- lastClientY + (lastClientY=e.clientY));
                                    if (el == _document.body) {
                                        (scroller = _document.documentElement).scrollLeft -= newScrollX;
                                        scroller.scrollTop -= newScrollY;
                                    }
                                }
                            }, 0
                        );
                    })(dragged[i++]);
                }
            }
            if (_document.readyState === 'complete') {
                reset();
            } else {
                _window[addEventListener]('load', reset, 0);
            }
            exports.reset = reset;
        }));
    }
    /* ***************** Fin - Script : Menu Swipe LGE_V3 ***************** */
    });  
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	
    var $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function() {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle toggle refinement blocks
    $main.on('click', '.refinement h3', function() {
        $(this).toggleClass('expanded')
            .siblings('ul').toggle();
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function(e) {
        // don't intercept for category and folder refinements, as well as unselectable / breadcrumb and menu swipe
        if(!$(this).hasClass('breadcrumb-element') && !$(this).hasClass('pn-ProductNav_Link')){
            if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
                return;
            }
            e.preventDefault();
            var maincategory = $('.mainCategoryName').text().toLowerCase().trim();
            updateProductListingFilter(this.href, $(this).closest('.breadcrumb-refinement-value').text(),$(this).parent().parent().find('.breadcrumb-refinement-name').text(),Urls.deleteValue , maincategory); 
        } else {
            if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
                return;
            }
            e.preventDefault();
            updateProductListing(this.href); 
        }
        $('body').removeClass('overflow-hidden');
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function() {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if(!$(this).closest(".boxedplp")) {
            if (!params.start) {
                params.start = 0;
            }
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        if(!$(this).closest(".boxedplp")) {
            var idx = tile.data('idx') ? +tile.data('idx') : 0;
        }
        // convert params.start to integer and add index
        if(!$(this).closest(".boxedplp")) {
            params.start = (+params.start) + (idx + 1);
        }
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    var filter_input = 'input[name="marque"]';
    $main.on('change', '.sort-by select', function(e) {
            e.preventDefault();
            updateProductListing($(this).find('option:selected').val());

        })
        .on('click', '.sortbychoices li', function(e) {
            e.preventDefault();
            updateProductListing($(this).find('input[name="grid-sort-footer"]').val());
        })
        .on('click', '.sortbydeliverymode  li', function(e) {
            e.preventDefault();
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    "event": "eventGA",
                    "categoryGA": "filtre",
                    "actionGA": "utilisation",
                    "labelGA": "Mode de livraison - " + $(this).text().toLowerCase().trim(),
                    "valueGA": ""
                });
            }
            updateProductListing($(this).find('input[name="deliverymode"]').val());
        })
        .on('click', '.sortbybrand li', function(e) {
            e.preventDefault();
            if (!$(this).parent().hasClass('isNewSort') && !$(this).parent().hasClass('BIOSort')) {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        "event": "eventGA",
                        "categoryGA": "filtre",
                        "actionGA": "utilisation",
                        "labelGA": "Marque - " + $(this).text().toLowerCase().trim(),
                        "valueGA": ""
                    });
                }
                updateFilterBreadCrumb($(this));
            }
        })
        .on('click', '.isNewSort li', function(e) {
            e.preventDefault();
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    "event": "eventGA",
                    "categoryGA": "filtre",
                    "actionGA": "utilisation",
                    "labelGA": "Nouveauté - " + $(this).text().toLowerCase().trim(),
                    "valueGA": ""
                });
                updateFilterBreadCrumb($(this));
            }
        })
        .on('click', '.BIOSort li', function(e) {
            e.preventDefault();
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    "event": "eventGA",
                    "categoryGA": "filtre",
                    "actionGA": "utilisation",
                    "labelGA": "BIO - " + $(this).text().toLowerCase().trim(),
                    "valueGA": ""
                });
                updateFilterBreadCrumb($(this));
            }
            
        })
        .on('click', '.sortbynote li', function(e) {
            e.preventDefault();
            var starsCount = $(this).find("i").length;
            var note = starsCount + ' étoiles';
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    "event": "eventGA",
                    "categoryGA": "filtre",
                    "actionGA": "utilisation",
                    "labelGA": "Note - " + note,
                    "valueGA": ""
                });
                updateFilterBreadCrumb($(this));
            }
        })
        .on('click', '.sortbychoices-a', function(e) {
            e.stopPropagation();

            $('.sortbychoices-a').not(this).removeClass('expanded').next('.toggle-content').hide();
            $('.plus-activator').not($(this).parents('.afiner-group').prev()).removeClass('expanded').next('.afiner-group').hide();
            $(this).toggleClass('expanded').next('.toggle-content').toggle();
            //hide not selected filters
            var parent = $(this).closest('.sort-by');
            $('.afiner-group .sort-by').not(parent).hide();
            $(this).find('+ ul').niceScroll({
                cursorcolor: "#e6e6e6",
                cursorwidth: "3px"
            });
        })
        .on('click', '.afiner-group-mobile >li .cat-close', function(e) {
            //show the hidden refinement on the arrow back
            $('.afiner-group .sort-by').show();
            $(this).closest('.afiner-group-mobile').toggle();
        })
        .on('click', '.plus-activator', function(e) {
            e.stopPropagation();
            $('.sortbychoices-a').removeClass('expanded').next('.toggle-content').hide();
            $(this).toggleClass('expanded');
            $(this).next('.afiner-group').toggle();
        })

    .on('change', '.items-per-page select', function() {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    })

    .on('click', '.sortbyprice', function(e) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                "event": "eventGA",
                "categoryGA": "filtre",
                "actionGA": "utilisation",
                "labelGA": "Prix",
                "valueGA": ""
            });
        }
    })
    .on('click', '.sortbyrayon li', function(e) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                "event": "eventGA",
                "categoryGA": "filtre",
                "actionGA": "utilisation",
                "labelGA": "Rayon - " + $(this).text().toLowerCase().trim(),
                "valueGA": ""
            });
        }
    })
    .on('click', '.sortbystandard li', function(e) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                "event": "eventGA",
                "categoryGA": "filtre",
                "actionGA": "utilisation",
                "labelGA": "trier par - " + $(this).text().toLowerCase().trim(),
                "valueGA": ""
            });
        }
        updateFilterBreadCrumb($(this));
    });

    $(document).click(function(e) {
        $('.sortbychoices-a').not(this).removeClass('expanded').next('.toggle-content').hide();
        if ($(e.target).closest('.afiner-group').length != 0) {

        } else {
            $('.plus-activator').not(this).removeClass('expanded').next('.afiner-group').hide();
            //show the hidden refinement on the arrow back
            $('.afiner-group .sort-by').show();
        }

    });


    $(document).on('click', '.toggle-grid i', function() {
        $('.search-result-content').toggleClass('wide-tiles');
        $('.grid-tile .push-item').closest('.grid-tile').toggleClass('hide');
        $('.toggle-grid i').toggleClass('wide');
        $(document.body).attr("data-tile_isList", $('.toggle-grid .viewlist').hasClass('wide'));
        if($('.search-result-content.wide-tiles').length > 0){
            updateAddToCartPosition();
            updateProductImagesHeight();
        }
    });

    $(document).on('click', '#show-more-services', function() {

        var contentStart = $(this).attr('data-grid-start');
        var typeContent = $(this).attr('data-grid-type');
        var q = $(this).attr('data-grid-param');

        $.ajax({
            url: util.ajaxUrl(Urls.showMoreContent),
            type: 'GET',
            data: { contentStart: contentStart, typeContent: typeContent, q: q },
            dataType: 'html'
        }).done(function(response) {
            $('div.services-result-items div.row').before(response);
            var newStart = parseInt($('#show-more-services').attr('data-grid-start')) + 8;
            $('#show-more-services').data('data-grid-start', newStart);
            $('#show-more-services').attr('data-grid-start', newStart);
        });
    });

    $(document).on('click', '#show-more-magasins', function() {

        var contentStart = $(this).attr('data-grid-start');
        var typeContent = $(this).attr('data-grid-type');
        var q = $(this).attr('data-grid-param');

        $.ajax({
            url: util.ajaxUrl(Urls.showMoreContent),
            type: 'GET',
            data: { contentStart: contentStart, typeContent: typeContent, q: q },
            dataType: 'html'
        }).done(function(response) {
            $('div.magasins-result-items div.row').before(response);
            var newStart = parseInt($('#show-more-magasins').attr('data-grid-start')) + 8;
            $('#show-more-magasins').data('data-grid-start', newStart);
            $('#show-more-magasins').attr('data-grid-start', newStart);
        });
    });

    $(document).on('click', '#show-more-inspirations', function() {

        var contentStart = $(this).attr('data-grid-start');
        var typeContent = $(this).attr('data-grid-type');
        var q = $(this).attr('data-grid-param');

        $.ajax({
            url: util.ajaxUrl(Urls.showMoreContent),
            type: 'GET',
            data: { contentStart: contentStart, typeContent: typeContent, q: q },
            dataType: 'html'
        }).done(function(response) {
            $('div.inspirations-result-items div.row').before(response);
            var newStart = parseInt($('#show-more-inspirations').attr('data-grid-start')) + 8;
            $('#show-more-inspirations').data('data-grid-start', newStart);
            $('#show-more-inspirations').attr('data-grid-start', newStart);
        });
    });
}

function SeparateGridElement(){
	if ( $( "#normalpagesize" ).length ) {
		var normalpagesize = parseInt($( "#normalpagesize" ).data('count'));
		var length =  $( "#normalpagesize" ).length;
		var page = 1;
		$( ".grid-tile" ).each(function( index ) {
			var element = index + 1;
			if (index != (length - 1)) {
				if($.isNumeric(normalpagesize)){
					if( element % normalpagesize === 0){
					  $(this).after('<div class="gridproductdivider" id="gridproductdivider-'+page+'" data-page="'+page+'"></div>');
					  page++;
					}
				}
			}
		});
	}
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

// PLP Sidebar filter 
function normalizeString(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
}

$(document).on("click", ".form-check", function () {
    var checked = $(this).find(".fa");
    if (checked.hasClass("fa-square-o")) {
        checked.removeClass("fa-square-o").addClass("fa-check-square");
    } else {
        checked.removeClass("fa-check-square").addClass("fa-square-o");
    }
    $('body').removeClass('overflow-hidden');
});

$(document).on("click", ".form-check input, .sidebarAction a", function () {
    $('body').removeClass('overflow-hidden');
});

// SideBar filter Quick Search fields
$(document).on("keyup", ".sidebarSearch",  function () {
    var searchTerm = normalizeString($(this).val());
    var $ul = $(this).parent().nextAll(".sidebarListing");
    $ul.find("li").each(function () {
        var sidebarSearch = normalizeString($(this).text());
        if (sidebarSearch.includes(searchTerm)) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
});

var listCount = $(".sidebarListing li").length;
if (listCount > 10 || listCount < 10) {
    $(".sidebarListing").addClass("custom-class");
}
if ($(".vintageAttr").length > 0) {
    $(".vintageAttr").parent().addClass("vintageAttrWrapper");

}
reorganiseFilter();

// SideBar Stop Sending Form on Click Enter
$(document).on('submit','form.sidebarForm',function(e){
    e.preventDefault();
});

// Initialisation des filtres du Sidebar
$(document).on('click', '.sidebarfilter-init-filters a',function(e){
    e.preventDefault();
    var maincategory = $('.mainCategoryName').text().toLowerCase().trim();
    updateProductListingFilter($(this).attr('href'), $(this).closest('.breadcrumb-refinement-value').text(),$(this).parent().parent().find('.breadcrumb-refinement-name').text(),Urls.deleteValue , maincategory); 
});

// Update Filter Breadcrumb
function updateFilterBreadCrumb(selector){
    var filter_input = 'input[name="marque"]';
    if(selector.hasClass("ischecked")) {
        updateProductListingFilter(selector.find(filter_input).val(),selector.text().toLowerCase().trim(),selector.parent().find('.cat-head .cat-name').text(),Urls.deleteValue,$('.mainCategoryName').text());
    } else {
        updateProductListingFilter(selector.find(filter_input).val(),selector.text().toLowerCase().trim(),selector.parent().find('.cat-head .cat-name').text(),Urls.showCat,$('.mainCategoryName').text());
    }
}

// Update Filter sidebar
function reorganiseFilter(){
    if ($(".reverseOption").length > 0) {
        $(".reverseOption").each(function(){
            $(this).prependTo($(this).parent());
        })
    }
    if ($(".sidebarBody #vintage").length > 0) {
    var $list = $('.sidebarBody #vintage ul');
    var $listItems = $list.find('li');
    $listItems.each(function(index) {
        $(this).css('order', index + 1);
      });
    var totalItems = $listItems.length;


    if (totalItems > 7) {
        var itemsInFirstCol = Math.ceil(totalItems / 2);
        $list.empty();
        var $col1 = $('<div class="listItems"></div>').append($listItems.slice(0, itemsInFirstCol));
        var $col2 = $('<div class="listItems"></div>').append($listItems.slice(itemsInFirstCol));

        $list.append($col1).append($col2);
    }

function setCustomOrder() {
    var elements = $('#vintage ul li');
  
    var ageArray = [];
    var indexStartBool = true;
    var indexStart;

    elements.each(function(index, element) {
      var age = $(this).data('vl');
      if (typeof age === 'string' && (age.indexOf("an") !== -1 || age.indexOf("ans") !== -1)) {
        if (indexStartBool) {
            indexStart = index - 1;
            indexStartBool = false;
        }
       
        ageArray.push(age);
      }
    });
  
    ageArray.sort(function(a, b) {
      var aNum = parseInt(a);
      var bNum = parseInt(b);
  
      return aNum - bNum;
    });
  
    ageArray.forEach(function(age, index) {
      var element = $('[data-vl="' + age + '"]');
      if (element.length > 0) {
        element.css('order', indexStart + index + 1);
      }
    });
  }
  
  setCustomOrder();
  
    }
}

// Update AddtoCart Btn Position
function updateAddToCartPosition(){
    var textHeight;
    $(".search-result-content.wide-tiles .grid-tile").each(function(){
        textHeight = $(this).find('.name-link').height();
        $(this).find('.product-tile .icon-panier').css('margin-top', textHeight + 'px');
    })
}

// Set min height for images to prevent shift after back action
function updateProductImagesHeight(){
    if ($('.pt_product-search-result').length) { 
        var minheight = 0;
        var ww = $( window ).width();
        if(ww>=1440){
            minheight = 323;
        }else if(ww>=1024){
            minheight = (ww*22.5)/100;
        }else if(ww>=600){
            minheight = (ww*30)/100;
        }else{
            minheight = (ww*44)/100;
        }
        if (!$('.no-minheight').length > 0 && $('.search-result-content').length > 0) {
            $('.product-tile .product-image').each(function(){
                $(this).css('min-height',minheight);
            });
        } else if ($('.search-result-content').length > 0 && $('.no-minheight').length > 0 ) {
            $('.search-result-content .product-tile .product-image').each(function(){
                $(this).css('min-height',minheight);
            });
        }
    }
}

$(window).on('resize', function() {
    updateAddToCartPosition();
    updateProductImagesHeight();
});

exports.init = function() {
	
	SeparateGridElement();
    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL && $('.pt_product-search-result').length) {
        $(document).on('scroll', infiniteScroll);
    }
    // productTile.init();

    initializeEvents();
    //addToCart();

    // check if grid-tile contains more then one slotcontent 
    initSlotsContent();
    
    var url = window.location.href;
    if (url.search('page') != -1) {
    	var page = parseInt(getUrlParameter('page'));
    	var current = page-1;
    	$('body').append('<div id="scrollToPage" data-page="'+page+'" data-current="'+current+'"></div>');
    }
    
};
