'use strict';

const { find } = require('lodash');
var giftcert = require('../giftcert'),
    ajax = require('../ajax'),
    formPrepare = require('./checkout/formPrepare'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator');
   // QASAddress = require('./QASAddress');


/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {

    var $form = $('#edit-address-form');
    $form.find('input[name="format"]').remove();
    tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    $form.on('click', '.apply-button', function (e) {
        /* e.preventDefault();
         if (!$form.valid()) {
         return false;
         }
         var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
         var applyName = $form.find('.apply-button').attr('name');
         var options = {
         url: url,
         data: $form.serialize() + '&' + applyName + '=x',
         type: 'POST'
         };
         $.ajax(options).done(function (data) {
         if (typeof(data) !== 'string') {
         if (data.success) {
         dialog.close();
         page.refresh();
         } else if (data.error) {
         page.redirect(Urls.csrffailed);
         } else {
         window.alert(data.message);
         return false;
         }
         } else {
         $('#dialog-container').html(data);
         account.init();
         tooltip.init();
         }
         });*/
    })
        .on('click', '.cancel-button, .close-button', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('click', '.delete-button', function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else {
                        dialog.close();
                        page.refresh();
                    }
                });
            }
        });


    validator.init();

}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) {
        return;
    }

    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                open: initializeAddressForm
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });


}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        $(this).hide();
        $('#CreditCardForm').removeClass('hide');
        formPrepare.validateForm();
        $.ajax({
            url: util.ajaxUrl(Urls.setCardType),
        }).done(function () {
        });
        $.fn.select2.defaults.set("theme", "bootstrap");
        $('.cart-month .input-select').select2({
            minimumResultsForSearch: -1,
            placeholder: 'MM'
        });
        $('.cart-year .input-select').select2({
            minimumResultsForSearch: -1,
            placeholder: 'AAAA'
        });
        $('.create-adrs .customerAddressList').select2({
            minimumResultsForSearch: -1

        });

        $('.create-adrs .custom-input-holder').val($('.create-adrs .customerAddressList option:selected').text());
        $("span[id^='select2-customerAddressList']").html(Resources.LABEL_SELECT_ADDRESS).addClass('select-country');

        $('.create-adrs .customerAddressList').on("select2:select", function (event) {
            var sop = event.params.data.text;
            $('.create-adrs .customerAddressList option').each(function () {
                if ($(this).attr('label') == sop) {

                    $(this).attr("selected", "selected");
                } else {
                    $(this).removeAttr("selected", "selected");
                }
            });
            $('.create-adrs .custom-input-holder').val(event.params.data.text);
            //init select country label
            $("span[id^='select2-customerAddressList']").html(Resources.LABEL_SELECT_ADDRESS).addClass('select-country');

        });

//        dialog.open({
//            url: $(e.target).attr('href'),
//            options: {
//                open: initializePaymentForm
//            }
//        });
    });


    $('.edit-card-link').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            url: util.ajaxUrl(Urls.setCardType),
        }).done(function () {
        });
        var $this = $(this),
            id = $this.attr('id'),
            formId = $(this).attr('data-creditCardId');
        id = id.slice(4);
        $(this).hide();

        $.fn.select2.defaults.set("theme", "bootstrap");
        $('#' + formId + ' .cart-month .input-select').select2({
            minimumResultsForSearch: -1,
            placeholder: 'MM'
        });
        $('#' + formId + ' .cart-year .input-select').select2({
            minimumResultsForSearch: -1,
            placeholder: 'AAAA'
        });
        $('#' + formId + ' .edit-adrs .customerAddressList').select2({
            minimumResultsForSearch: -1
        });
        $('#' + formId + ' .edit-adrs .custom-input-holder').val($('#' + formId + ' .edit-adrs .customerAddressList option:selected').text());
        
        $("span[id^='select2-customerAddressList']").html(Resources.LABEL_SELECT_ADDRESS).addClass('select-country');
        $('#' + formId + ' .edit-adrs .customerAddressList').on("select2:select", function (event) {
            var sop = event.params.data.text;
            $('#' + formId + ' .edit-adrs .customerAddressList option').each(function () {
                if ($(this).attr('label') == sop) {

                    $(this).attr("selected", "selected");
                } else {
                    $(this).removeAttr("selected", "selected");
                }
            });
            $('#' + formId + ' .edit-adrs .custom-input-holder').val(event.params.data.text);
            //init select country label
            $("span[id^='select2-customerAddressList']").html(Resources.LABEL_SELECT_ADDRESS).addClass('select-country');

                    });
        
        
        $("#" + formId).find('.cart-number .field-wrapper input[type="text"]').validateCreditCard(function (result) {
            if (result.card_type) {
                if (result.card_type.name == "visa") {
                    result.card_type.name = "Visa";
                }
                $('.credit-card-type-logo').fadeIn();
                $('.credit-card-type-logo').find('img').removeAttr('src')
                    .attr('src', Urls.urlCardPicto + result.card_type.name + '.png');

                switch (result.card_type.name) {
                    case "mastercard":
                        $('.inputTypecardToSet select').val('MasterCard');
                        //console.log(result.card_type.name);
                        break;
                    case "Visa":
                        $('.inputTypecardToSet select').val('Visa');
                        //console.log(result.card_type.name);
                        break;

                    case "amex" :
                        $('.inputTypecardToSet select').val('Amex');
                        break;
                    case "discover" :
                        $('.inputTypecardToSet select').val('Discover');
                        break;
                    case "maestro" :
                        $('.inputTypecardToSet select').val('Maestro');
                        break;
                    //console.log(result.card_type.name);
                    default:

                }


            }
            else {
                $('.credit-card-type-logo').fadeOut();
            }
        });
        var url = util.appendParamToURL(Urls.editCreditCard, 'cardSubscriptionID', id);
        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data) {
                    //console.log('no data json');
                    return false;
                }
                else {
                    //console.log(data);
                    setCCFields(data, id);
                    $('.editBTnCard').on('click', function (e) {
                        var cardNumber = $("#CreditCardForm" + id).find('input[name*="_newcreditcard_number"]').val();
                        var cardOwner = $("#CreditCardForm" + id).find('input[name*="_newcreditcard_owner"]').val();
                        var cardCcv = $("#CreditCardForm" + id).find('input[name*="_newcreditcard_cvn"]').val();
                        cardNumber = cardNumber.replace(/ /g, "");
                        var cardMonth = $("#CreditCardForm" + id).find('[name$="_month"]').val();
                        var cardYear = $("#CreditCardForm" + id).find('[name$="_year"]').val();
                        var cardType = $("#CreditCardForm" + id).find('select[name$="_type"]').val();

                        if (cardNumber != "" && cardOwner != "" && cardCcv != "" ) {
                        	var data = {
                                    cardNumber: cardNumber,
                                    cardMonth: cardMonth,
                                    cardYear: cardYear,
                                    cardType: cardType,
                                    format: 'ajax'
                                };

                                $.ajax({
                                    url: Urls.verifyCard,
                                    type: "POST",
                                    data: data,
                                    success: function (data) {
                                        if (data) {
                                        	  if(data.iscardactive === 'true'){
		                                            if (data.error == true) {
		                                                if (data.code == 'CREDITCARD_INVALID_EXPIRATION_DATE') {
		                                                    $('.editcreditcardFormError').html('Cette carte de crédit est expirée');
		                                                    $('.editcreditcardFormError').removeClass('hide');
		                                                    $('.cart-number').addClass('errorCartNumber');
		                                                }
		                                                else if (data.code == 'CREDITCARD_INVALID_CARD_NUMBER') {
		                                                    $('.editcreditcardFormError').html('Numéro de carte de crédit non valide');
		                                                    $('.editcreditcardFormError').removeClass('hide');
		                                                    $('.cart-number').addClass('errorCartNumber');
		                                                }
		                                            }
		                                            else if (data.error == false) {
		                                                if (data.code == 'OK') {
		                                                    $('.editcreditcardFormError').addClass('hide');
		                                                    $('.cart-number').removeClass('errorCartNumber');
		                                                    $("#CreditCardForm" + id).submit();
		                                                }
		                                            }
                                        	  }
                                        	  else if(data.iscardactive === 'false'){
                                        		  $('.editcreditcardFormError').html('Numéro de carte de crédit non valide');
                                                  $('.editcreditcardFormError').removeClass('hide');
                                                  $('.cart-number').addClass('errorCartNumber');
                                        	  }
                                        }
//            					console.log(data);
                                    }

                                });
                        } else {
                        	$('.editcreditcardFormError').addClass('hide');
                        	$("#CreditCardForm" + id).validate().form();
                        }
                        
                    });


                }
            }
        });
    });


    if ($("#CreditCardForm").length) {
        $("#CreditCardForm").find('.cart-number .field-wrapper input[type="text"]').validateCreditCard(function (result) {
            var select = $("#CreditCardForm .inputTypecardToSet select");
            select.find('option').remove();
            if (result.card_type) {
                if (result.card_type.name == "visa") {
                    result.card_type.name = "Visa";
                }
                $('.credit-card-type-logo').fadeIn();
                $('.credit-card-type-logo').find('img').removeAttr('src')
                    .attr('src', Urls.urlCardPicto + result.card_type.name + '.png');


                switch (result.card_type.name) {
                    case "mastercard":
                        select.append('<option class="select-option" label="Master Card" value="MasterCard">Master Card</option>');
                        //console.log(result.card_type.name);
                        break;
                    case "Visa":
                        select.append('<option class="select-option" label="Visa" value="Visa">Visa</option>');
                        //console.log(result.card_type.name);
                        break;

                    case "amex" :
                        select.append('<option class="select-option" label="American Express" value="Amex">American Express</option>');
                        break;
                    case "discover" :
                    	 //console.log(result.card_type.name);
                        select.append('<option class="select-option" label="Discover" value="Discover">Discover</option>');
                        break;
                    case "maestro" :
                        select.append('<option class="select-option" label="Maestro" value="Maestro">Maestro</option>');
                        break;
                    //console.log(result.card_type.name);
                    default:

                }

            }
            else {
                $('.credit-card-type-logo').fadeOut();
            }
        });

    }


    var paymentList = $('.payment-list');
    $('button[id*="delete_card_"]').on('click', function (e) {
        $(this).hide();
        $(this).after('<div class="loading"></div>');
        if (!window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD))) {
            e.preventDefault();
            $('.loading').hide();
            $(this).show();
        }
    });

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList);
            });
    });


    $('#applyBTnCard').on('click', function (e) {
        var $form = $('.credit-card-form-detail');
        var cardNumber = $form.find('input[name*="_newcreditcard_number"]').val();
        var cardOwner = $form.find('input[name*="_newcreditcard_owner"]').val();
        var cardCcv = $form.find('input[name*="_newcreditcard_cvn"]').val();
        cardNumber = cardNumber.replace(/ /g, "");
        var cardMonth = $form.find('[name$="_month"]').val();
        var cardYear = $form.find('[name$="_year"]').val();
        var cardType = $form.find('select[name$="_type"]').val();
        if (cardNumber != "" && cardOwner != "" && cardCcv != "" ) {
        var data = {
            cardNumber: cardNumber,
            cardMonth: cardMonth,
            cardYear: cardYear,
            cardType: cardType,
            format: 'ajax'
        };

        formPrepare.validateForm();
        $.ajax({
            url: Urls.verifyCard,
            type: "POST",
            data: data,
            success: function (data) {
                if (data) {
                if(data.iscardactive === 'true'){
                    if (data.error == true) {
                        if (data.code == 'CREDITCARD_INVALID_EXPIRATION_DATE') {
                            $('.creditcardFormError').html('Cette carte de crédit est expirée');
                            $('.creditcardFormError').removeClass('hide');
                            $('.cart-number').addClass('errorCartNumber');
                        }
                        else if (data.code == 'CREDITCARD_INVALID_CARD_NUMBER') {
                            $('.creditcardFormError').html('Numéro de carte de crédit non valide');
                            $('.creditcardFormError').removeClass('hide');
                             $('.cart-number').addClass('errorCartNumber');
                        }
                    }
                    else if (data.error == false) {
                        if (data.code == 'OK') {
                        	  $('.creditcardFormError').addClass('hide');
                              $('.cart-number').removeClass('errorCartNumber');
                            $('.applyBTnCreditCard').click();
                        }
                    }
                }
           else if(data.iscardactive === 'false'){
            	 $('.creditcardFormError').html('Numéro de carte de crédit non valide');
                 $('.creditcardFormError').removeClass('hide');
                 $('.cart-number').addClass('errorCartNumber');
            }
              //  console.log(data);

            }
            }
        });
        
        }
        else{
        	$('.creditcardFormError').addClass('hide');
        	$form.validate().form();
        }
    });


}

/**
 *
 * @param data
 * @returns
 */
function setCCFields(data, id) {
    var $creditCard = $("#CreditCardForm" + id);
    $creditCard.find('input[name$="newcreditcard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
//    $creditCard.find('input[name*="_creditCard_number"]').closest('.form-caption').text(data.cardNumber);
    $creditCard.find('.card-number-label').text(data.cardNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');

    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    $creditCard.removeClass('hide');
}


function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

}

function registerOptin() {
    $('.optin-checked').click(function () {
        var disableOptin;
        if (!$(this).is(':checked')) {
            //console.log('unchecked');
            disableOptin = "true";
        }
        else {
            disableOptin = "false";
        }

        util.addLoader('.account-content');
        $.ajax({
            url: util.ajaxUrl(Urls.registerMyOptin),
            type: 'POST',
            data: {channel: $(this).val(), disableOptin: disableOptin},
            dataType: 'json',
            success: function (data) {
                setTimeout(function () {
                    util.removeLoader('.account-content');
                },5000);

            },
            error: function () {
                setTimeout(function () {
                    util.removeLoader('.account-content');
                }, 2000);
            }

        });
    });
}

function registerOptinAccount() {
    $('.optin-account-checked').click(function () {
        var disableOptin;
        if (!$(this).is(':checked')) {
            disableOptin = "true";
        }
        else {

            disableOptin = "false";
        }
        $.ajax({
            url: util.ajaxUrl(Urls.registerOptinInAccount),
            type: 'POST',
            data: {channel: $(this).val(), disableOptin: disableOptin},
            dataType: 'json'

        }).done(function (data) {

            //console.log('sucess');
        });
    });
}

// select credit card from list
function selectaddress() {
    $('.customerAddressList').on('change', function () {
        var addressID = $(this).val();
        //console.log(addressID);
        var url = util.appendParamToURL(Urls.addressSelectCC, 'addressID', addressID);
        $.ajax({
            url: url,
        }).done(function () {

        });
    });
}


function initLoadMoreOrders() {
    $('#show-more-orders').on('click', function (e) {
        //get the loading div ****************************************************tmp************************************
        var loadingOrders = $('.infinite-scroll-placeholder');
        // get url hidden in DOM
        var gridUrl = loadingOrders.data('grid-url');
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                $('ul.search-result-items.completedOrders').append(response);
                var newStart = parseInt(loadingOrders.data('grid-start')) + 2;
                loadingOrders.data('grid-start', newStart);
                loadingOrders.attr('data-grid-start', newStart);
                newStart = 'start=' + newStart;
                gridUrl = gridUrl.replace(/start=\d+/, newStart);
                loadingOrders.data('grid-url', gridUrl);
                loadingOrders.attr('data-grid-url', gridUrl);
                var isToLoadOrder = $(response).filter("#loadMoreOrders");
                //hide button load more button if no more orders to load :)
                if (isToLoadOrder.val() === 'false') {
                    $('#show-more-orders').hide();
                }

            }
        });
    });
}

function initShowOrderDetails() {
    var isMobile = function () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
    }
    $(document).on('click', '.order-link, .order-link-history', function () {
        $('body').addClass('active');
        var $container = $(this).parents('li').find('.order-details');
        $('.overlay').fadeIn();
        $container.fadeIn();
        if (!isMobile()) {
            $container.find('.product-details-content').niceScroll({
                cursorcolor: "#e6e6e6",
                cursorwidth: "3px"
            });

        }

        var serviceInputs = [];
        $container.find('.isService-order').each(function (index, value) {
            serviceInputs.push($(this).val());
        });
        var service = serviceInputs.includes("service");
        var simpleProduct = serviceInputs.includes("simpleProduct");
        if(service && !simpleProduct) {
            $container.find('.order-details-shpping').hide();
            $container.find('.order-details-adress').hide();
            $container.find('.order-details-downloads').hide();
        }

        return;
    });

    $(document).on('click', '.order-details .close , .overlay , .order-details .back-link', function (e) {
        $('body').removeClass('active');
        $('.overlay').fadeOut();
        $('.order-details').fadeOut();
        
        if (!isMobile()) {
            $(".product-details-content").getNiceScroll().remove();

        }
        $('.js-order-details-content').delay(1000).show(0);
        $('.js-order-return-content > .right,.js-order-return-content > .left').empty();
    });


    $(document).on('click', '.invoice-link', function () {

        var orderId = $(this).attr("data-order-id");
        var email = $(this).attr("data-email");
        var postbox = $(this).attr("data-post-box");
        var invoiceType = $(this).attr("data-type");
        var aajaxUrl = util.ajaxUrl(Urls.invoicePDF);

        if(invoiceType=='avoir'){
            var aajaxUrl = util.ajaxUrl(Urls.avoirPDF);
        }

        $.ajax({
            url: aajaxUrl,
            type: 'GET',
            data: {orderId: orderId, email: email, postbox: postbox},
            dataType: 'json'
        }).done(function (data) {
            var ProductType = data.header.productTypes;
            var containsOnlyServices = false;
            if(($.inArray("isService", ProductType) != -1) && ($.inArray("isSimple", ProductType) != -1)) {
                containsOnlyServices = false;
            } else if(($.inArray("isService", ProductType) === -1) && ($.inArray("isSimple", ProductType) != -1)) {
                containsOnlyServices = false;
            } else if(($.inArray("isService", ProductType) != -1) && ($.inArray("isSimple", ProductType) === -1)) {
                containsOnlyServices = true;
            }


            var columnsContentData = data.body.columnsContentData;

            var contentData = data.body.contentData;

            var doc = new jsPDF('p', 'pt');
            doc.setFont('verdana');
            var pageMargin = 40;
            var totalPagesExp = "{total_pages_count_string}";

            var firstTime = true;

            var pageContent = function (contentData) {
                //Logo LGEP
                var base64Img = "";
                var isLBM = data.header.isLBM;
                if(isLBM) {
                    base64Img = data.header.LBMLogo;
                    var xOffset = (doc.internal.pageSize.width / 2) - (50 / 2);
                    doc.addImage(base64Img, 'JPEG', xOffset, 15, 50, 45);
                } else {
                    base64Img = data.header.LGEPLogo;
                    var xOffset = (doc.internal.pageSize.width / 2) - (161 / 2);
                    doc.addImage(base64Img, 'JPEG', xOffset, 15, 161, 30);
                }

                //Ligne separatrice
                doc.setLineWidth(0.2);
                doc.setDrawColor(200, 200, 200);
                doc.line(contentData.settings.margin.left, 65, doc.internal.pageSize.width - contentData.settings.margin.left, 65);

                pageMargin = contentData.settings.margin.left;

                //numero de facture
                doc.setFontType('bold');
                doc.setFontSize(16);
                var text = data.header.headerInvoiceNumber;
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                doc.text(text, xOffset, 95);

                //client et date
                doc.setFontType('normal');
                doc.setFontSize(10);
                var text = data.header.headerInvoiceOwner;
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                doc.text(text, xOffset, 110);

                //adresse de livraison libelle
                if(!containsOnlyServices) {
                    doc.setFontType('bold');
                    doc.setFontSize(12);
                    var text = data.header.headerShippingAdress;
                    doc.text(text, contentData.settings.margin.left, 140);
                }

                var shippingYOffset = 153;
                
                //Name
                if(!containsOnlyServices) {
                    doc.setFontType('normal');
                    doc.setFontSize(7);
                    var text = data.header.shippingAdressLine1;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);
                
                    //Company
                    text = data.header.shippingAdressLineCompany;
                    if (text !== '' && text!==null) {
                        shippingYOffset = shippingYOffset + 10;
                        doc.text(text, contentData.settings.margin.left, shippingYOffset);	
                    }

                    //Adresse
                    shippingYOffset = shippingYOffset + 10;
                    var text = data.header.shippingAdressLine2;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);

                    //Adresse 2
                    var text = data.header.shippingAdressLine5;
                    if (text != '') {
                        shippingYOffset = shippingYOffset + 10;
                        doc.text(text, contentData.settings.margin.left, shippingYOffset);
                    }
                    
                    //Complement 3
                    var text = data.header.shippingAdressLine6;
                    if (text != '') {
                        shippingYOffset = shippingYOffset + 10;
                        doc.text(text, contentData.settings.margin.left, shippingYOffset);
                    }
                
                    //Code postal
                    shippingYOffset = shippingYOffset + 10;
                    var text = data.header.shippingAdressLine3;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);

                    //Pays
                    shippingYOffset = shippingYOffset + 10;
                    var text = data.header.shippingAdressLine4;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);
                }
                if(containsOnlyServices) {
                    shippingYOffset = shippingYOffset + 10;
                }
                //Mode de paiement libelle +15
                doc.setFontType('bold');
                doc.setFontSize(12);
                shippingYOffset = shippingYOffset + 15;
                var text = data.header.headerBillingMethod;
                doc.text(text, contentData.settings.margin.left, shippingYOffset);

                //Mode de paiement +13
                doc.setFontType('normal');
                doc.setFontSize(7);
                shippingYOffset = shippingYOffset + 13;
                var text = data.header.headerBillingMethodValue;
                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                
                if(!containsOnlyServices) {
                    //Mode de livraison libelle +15
                    doc.setFontType('bold');
                    doc.setFontSize(12);
                    shippingYOffset = shippingYOffset + 15;
                    var text = data.header.headerShippingMethod;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);

                    //Mode de livraison +13
                    doc.setFontType('normal');
                    doc.setFontSize(7);
                    shippingYOffset = shippingYOffset + 13;
                    var text = data.header.headerShippingMethodValue;
                    doc.text(text, contentData.settings.margin.left, shippingYOffset);
                }
                doc.setFontType('bold');
                var offset1 = doc.getStringUnitWidth(data.header.headerBillingAdress) * 12;
                doc.setFontType('normal');
                var offset2 = doc.getStringUnitWidth(data.header.billingAdressLine1) * 7;
                var offset3 = doc.getStringUnitWidth(data.header.billingAdressLine2) * 7;
                var offset4 = doc.getStringUnitWidth(data.header.billingAdressLine3) * 7;
                var offset5 = doc.getStringUnitWidth(data.header.billingAdressLine4) * 7;
                var choosenOffset = Math.max(offset1, offset2, offset3, offset4, offset5);
                
                
                //adresse de facturation libelle
                doc.setFontType('bold');
                doc.setFontSize(12);
                var text = data.header.headerBillingAdress;
                //xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                xOffset = doc.internal.pageSize.width - choosenOffset - contentData.settings.margin.left;
                doc.text(text, xOffset, 140);

                var billingYOffset = 153;
                //Name
                doc.setFontType('normal');
                doc.setFontSize(7);
                var text = data.header.billingAdressLine1;
                doc.text(text, xOffset, billingYOffset);
                
                //Company
                text = data.header.billingAdressLineCompany;
                if (text !== '' && text!==null) {
                	billingYOffset = billingYOffset + 10;
                    doc.text(text, xOffset, billingYOffset);
                }

                //Adresse 1
                billingYOffset = billingYOffset + 10;
                var text = data.header.billingAdressLine2;
                doc.text(text, xOffset, billingYOffset);
                
                //Adresse 2
                var text = data.header.billingAdressLine5;
                if (text != '') {
                	billingYOffset = billingYOffset + 10;
                	doc.text(text, xOffset, billingYOffset);
                }
                
                //Complement 3
                var text = data.header.billingAdressLine6;
                if (text != '') {
                	billingYOffset = billingYOffset + 10;
                	doc.text(text, xOffset, billingYOffset);
                }
                
                //Code postal
                billingYOffset = billingYOffset + 10;
                var text = data.header.billingAdressLine3;
                doc.text(text, xOffset, billingYOffset);

                //Pays
                billingYOffset = billingYOffset + 10;
                var text = data.header.billingAdressLine4;
                doc.text(text, xOffset, billingYOffset);

                //Ligne separatrice + 11
                doc.setLineWidth(0.2);
                doc.setDrawColor(200, 200, 200);
                shippingYOffset = shippingYOffset + 11;
                doc.line(doc.internal.pageSize.width / 4, shippingYOffset, doc.internal.pageSize.width / 4 * 3, shippingYOffset);

                //numero de commande + 30
                doc.setFontType('bold');
                doc.setFontSize(16);
                shippingYOffset = shippingYOffset + 30;
                var text1 = data.header.headerOrderNumber;
                var text2 = data.header.headerOrderNumberValue;
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text1) * doc.internal.getFontSize() / 2) - (doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / 2);
                doc.text(text1, xOffset, shippingYOffset);
                xOffset = xOffset + (doc.getStringUnitWidth(text1) * doc.internal.getFontSize());
                doc.setTextColor('#fd712b');
                doc.text(text2, xOffset, shippingYOffset);

                //date facture + 15
                doc.setFontType('normal');
                doc.setFontSize(10);
                shippingYOffset = shippingYOffset + 15;
                var text = data.header.headerInvoiceDate;
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                doc.setTextColor('#000000');
                doc.text(text, xOffset, shippingYOffset);

                // FOOTER
                var str = "Page " + contentData.pageCount;
                var strCalcul = "Page " + contentData.pageCount;
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + " sur " + totalPagesExp;
                    strCalcul = str + " sur 2";
                    if (!firstTime) {
                        str = "Page " + totalPagesExp + " sur " + totalPagesExp;
                    }
                    firstTime = false;
                }


                doc.setFontType('normal');
                doc.setFontSize(6);
                var footerText = data.footer.footerText1;
                if(isLBM) {
                    footerText = "";
                } else {
                    footerText = data.footer.footerText1;
                }
                
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / 2);
                doc.text(footerText, xOffset, doc.internal.pageSize.height - 52);
                doc.setLineWidth(0.2);
                doc.line(contentData.settings.margin.left, doc.internal.pageSize.height - 45, doc.internal.pageSize.width - contentData.settings.margin.left, doc.internal.pageSize.height - 45);
                doc.setFontSize(10);
                doc.setFontStyle('bold');
                doc.text(data.footer.footerText2, contentData.settings.margin.left, doc.internal.pageSize.height - 30);
                doc.setFontSize(6);
                doc.setFontStyle('normal');
                var footerText2 = data.footer.footerText3;
                var splitTitle = doc.splitTextToSize(footerText2, doc.internal.pageSize.width - (contentData.settings.margin.left * 2));
                doc.text(splitTitle, contentData.settings.margin.left, doc.internal.pageSize.height - 20);
                //Numero de page

                doc.text(str, doc.internal.pageSize.width - contentData.settings.margin.left - 38, doc.internal.pageSize.height - 10);

            };

            var tableYOffset = 310;
            
            var text = data.header.shippingAdressLine5;
            if (text != '') {
            	tableYOffset = tableYOffset + 10;
            
            }
            
            var text = data.header.shippingAdressLine6;
            if (text != '') {
            	tableYOffset = tableYOffset + 10;
            }
            
            var contentOptions = {
                startY: tableYOffset,
                addPageContent: pageContent,
                margin: {bottom: 100, top: 310},
                headerStyles: {
                    fillColor: [255, 255, 255],
                    fontSize: 7,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'bold',
                    halign: 'center',
                    valign: 'middle'
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    fontSize: 7,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'normal'
                },
                alternateRowStyles: {
                    fillColor: [255, 255, 255]
                },
                styles: {
                    font: 'verdana',
                    lineColor: [0, 0, 0],
                    lineWidth: 0.1
                },
                columnStyles: {
                    puht: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    tva: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    qty: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    puttc: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    remise: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    total_ttc: {
                        halign: 'center',
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    code_article: {
                        halign: 'left',
                        columnWidth: 90,
                        valign: 'middle'
                    },
                    designation: {
                        halign: 'left',
                        columnWidth: 120,
                        valign: 'middle'
                    }
                }
            }

            doc.autoTable(columnsContentData, contentData, contentOptions);

            //Recap Tva
            let finalY = doc.autoTable.previous.finalY;
            if (finalY > 620) {
                doc.addPage();
            }
            doc.setFontType('bold');
            doc.setFontSize(10);
            if (finalY > 620) {
                doc.text(40, 345, data.body.tvaTitleLabel);
            } else {
                doc.text(40, finalY + 35, data.body.tvaTitleLabel );
            }


            var recapTVAColumns = data.body.recapTVAColumns;

            var recapTVAData = data.body.recapTVAData;

            var recapTVAOptions = {
                startY: finalY + 45,
                tableWidth: 130,
                headerStyles: {
                    fillColor: false,
                    fontSize: 12,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'bold',
                    halign: 'left',
                    valign: 'middle'
                },
                bodyStyles: {
                    fillColor: false,
                    fontSize: 7,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'normal'
                },
                alternateRowStyles: {
                    fillColor: false
                },
                styles: {
                    font: 'verdana',
                    lineColor: [0, 0, 0]
                },
                columnStyles: {
                    taux: {
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    mttva: {
                        columnWidth: 'auto',
                        valign: 'middle'
                    }
                },
                drawCell: function (cell, data) {
                    doc.setLineWidth(0.2);
                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                },
                drawHeaderCell: function (cell, data) {
                    doc.setLineWidth(0.2);
                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                }
            }

            if (finalY > 620) {
                recapTVAOptions.startY = 355;
                recapTVAOptions.addPageContent = pageContent;
            }

            doc.autoTable(recapTVAColumns, recapTVAData, recapTVAOptions);


            var recapHTColumns = data.body.recapHTColumns;

            var recapHTData = data.body.recapHTData;

            if(containsOnlyServices) {
                delete recapHTData[1];
                delete recapHTData[2];
            }

            var recapHTOptions = {
                startY: finalY + 23,
                tableWidth: (doc.internal.pageSize.width / 2) - (pageMargin ),
                margin: {left: (doc.internal.pageSize.width / 2)},
                showHeader: 'never',
                headerStyles: {
                    fillColor: false,
                    fontSize: 12,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'bold',
                    halign: 'left',
                    valign: 'middle'
                },
                bodyStyles: {
                    fillColor: false,
                    fontSize: 7,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'normal'
                },
                alternateRowStyles: {
                    fillColor: false
                },
                styles: {
                    font: 'verdana',
                    lineColor: [0, 0, 0]
                },
                columnStyles: {
                    key: {
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    value: {
                        columnWidth: 'auto',
                        valign: 'middle',
                        halign: 'right'
                    }
                },
                drawCell: function (cell, data) {
                    if (data.row.index == 0) {
                        doc.setLineWidth(1.2);
                        doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                        if (data.column.dataKey === 'key') {
                            doc.setFontType('bold');
                            doc.setFontSize(10);
                        } else if (data.column.dataKey === 'value') {
                            doc.setFontType('bold');
                            doc.setFontSize(10);
                            doc.setTextColor('#fd712b');
                        }
                    } else if (data.row.index == 1) {
                        //doc.setFontSize(8);
                    } else if (data.row.index == 2 || data.row.index == 3) {
                        doc.setFontType('bold');
                        doc.setFontSize(8);
                    }
                }
            }

            if (finalY > 620) {
                recapHTOptions.startY = 333;
            }

            doc.autoTable(recapHTColumns, recapHTData, recapHTOptions);

            let finalRecapHTY = doc.autoTable.previous.finalY;

            var recapTTCColumns = data.body.recapTTCColumns;

            var recapTTCData = data.body.recapTTCData;

            var recapTTCOptions = {
                startY: finalRecapHTY + 10,
                tableWidth: (doc.internal.pageSize.width / 2) - (pageMargin ),
                margin: {left: (doc.internal.pageSize.width / 2)},
                showHeader: 'never',
                headerStyles: {
                    fillColor: false,
                    fontSize: 12,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'bold',
                    halign: 'left',
                    valign: 'middle'
                },
                bodyStyles: {
                    fillColor: false,
                    fontSize: 7,
                    textColor: 0,
                    font: 'verdana',
                    fontStyle: 'normal'
                },
                alternateRowStyles: {
                    fillColor: false
                },
                styles: {
                    font: 'verdana',
                    lineColor: [0, 0, 0]
                },
                columnStyles: {
                    key: {
                        columnWidth: 'auto',
                        valign: 'middle'
                    },
                    value: {
                        columnWidth: 'auto',
                        valign: 'middle',
                        halign: 'right'
                    }
                },
                drawCell: function (cell, data) {
                    if (data.row.index == 0) {
                        doc.setLineWidth(1.2);
                        doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);                       
                        doc.setFontType('bold');
                        doc.setFontSize(10);
                        doc.setTextColor('#fd712b');
                    } else if (data.row.index == 1) {
                        doc.setLineWidth(0.2);
                        doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                    }
                }
            }

            doc.autoTable(recapTTCColumns, recapTTCData, recapTTCOptions);

            // Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                doc.putTotalPages(totalPagesExp);
            }

            // Output as Data URI
            if(invoiceType=='avoir'){
                doc.save("retour_" + orderId + ".pdf");
            }else{
                doc.save("facture_" + orderId + ".pdf");
            }
        });

        return;
    });

}

function limitInput(){

    $("input[name$='_profile_customer_firstName']").attr('maxlength',25);
    $("input[name$='_profile_customer_lastName']").attr('maxlength',25);
    $("input[name$='_profile_customer_email']").attr('maxlength',50);
    $("input[name$='_profile_customer_phoneMobile']").attr('maxlength',15);
    $("input[name$='_profile_customer_emailconfirm']").attr('maxlength',50);
    $("input[name$='_profile_login_password']").attr('maxlength',255);
    $("input[name$='_profile_login_passwordconfirm']").attr('maxlength',255);
}
function patternValidation(number, pattern)
{
    const inputErr = $("<span id='profile_input_error' class='error bold text-left w-100'>"+ Resources.INVALID_PHONE  +"</span>");

    if(number)
    {
        if(pattern.test(number))
        {
            $("#profile_input_error").remove();
        } else {
            $(document).find("input[name$='_profile_customer_phoneMobile']").parent().append(inputErr).find('.error').addClass('customtop');
        }
    }
}

function phoneValid()
{
    var pattern = /^0[0-9]{1,14}$/;

    var number = $("input[name$='_profile_customer_phoneMobile']").val();
    if ($("#profile_input_error").length){
        $("#profile_input_error").remove();
    }
    patternValidation(number,pattern);
}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    phoneValid();
    initPaymentEvents();
    registerOptin();
    registerOptinAccount();
    initLoadMoreOrders();
    initShowOrderDetails();
    login.init();
    selectaddress();
    SymbolDelete();
    emojiDelete();
    controlAge();
    limitInput();
    controlPhone();

   $('.customerAddressList').trigger('change');

}

var account = {
    init: function () {

        $.fn.select2.defaults.set("theme", "bootstrap");
        $('.selectmycountry .input-select').select2({
            minimumResultsForSearch: -1
        });
        var cc = $('.selectmycountry .input-select').val();
        if($('.phone-'+cc).length) {
            $('.phone-'+cc).show();
        }
        else {
            $('.phone-FR').show();
        }


        $('.selectcastate .input-select , .selectusstate .input-select').select2({
            minimumResultsForSearch: -1
        });

        $('.country-input-holder').val($('.selectmycountry .input-select option:selected').text());
        //init select country label
        $('#select2-dwfrm_profile_address_countryCode-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');

        $(' .selectmycountry .input-select').on("select2:select", function (event) {
            var sop = event.params.data.text;
            var codeCountry = event.params.data.id;
            $('.localPhone').hide();
            if ($('.phone-' + codeCountry).length) {
                $('.phone-' + codeCountry).show();
            }
            else {
                $('.phone-FR').show();
            }
            $('.selectmycountry .input-select option').each(function () {
                if ($(this).attr('label') == sop) {

                    $(this).attr("selected", "selected");
                } else {
                    $(this).removeAttr("selected", "selected");
                }
            });
            $('.country-input-holder').val(event.params.data.text);
            //init select country label
            $('#select2-dwfrm_profile_address_countryCode-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');
        });
        
        $("input[name*='_address_firstName']").attr({
    		'maxlength' : 25
    	});
        
        $("input[name*='_address_lastName']").attr({
    		'maxlength' : 25
    	});
        
        $("input[name*='_address_address2']").attr({
    			'maxlength' : 35
    		});
        $("input[name*='_address_address1']").attr({
    		'maxlength' : 35
    	});
        
        
        $("input[name*='_address_city']").attr({
    		'maxlength' : 40
    	});
        
        
        $("input[name*='_address_postal']").attr({
    		'maxlength' : 10
    	});

        initializeEvents();
        giftcert.init();
        // QASAddress.init('dwfrm_profile_address_', 'profile');

        if ($('#dwfrm_profile_address_countryCode').length > 0) {
            if ($('#dwfrm_profile_address_countryCode').val().toUpperCase() === 'CA') {
                $('.customer-state-ca').removeClass('hide');
                $('.customer-state-us').addClass('hide');
            }
            else if ($('#dwfrm_profile_address_countryCode').val().toUpperCase() === 'US') {
                $('.customer-state-us').removeClass('hide');
                $('.customer-state-ca').addClass('hide');
            }
            else {
                $('.customer-state-us').addClass('hide');
                $('.customer-state-ca').addClass('hide');
            }
        }

        $(document).on('change', '#dwfrm_profile_address_countryCode', function () {
            if ($(this).val() != '' && $(this).val().toUpperCase() === 'FR') {
                $('.customer-state-us').addClass('hide');
                $('.customer-state-ca').addClass('hide');
                //QASAddress.init('dwfrm_profile_address_', 'profile');
                //QASAddress.enable();
            }
            else if ($(this).val() != '' && $(this).val().toUpperCase() === 'US') {
                //QASAddress.disable();
                $('.customer-state-us').removeClass('hide');
                $('.customer-state-ca').addClass('hide');
            }
            else if ($(this).val() != '' && $(this).val().toUpperCase() === 'CA') {
                //QASAddress.disable();
                $('.customer-state-us').addClass('hide');
                $('.customer-state-ca').removeClass('hide');
            }
            else {
                //QASAddress.disable();
                $('.customer-state-us').addClass('hide');
                $('.customer-state-ca').addClass('hide');
            }
        });
        formPrepare.init({
            formSelector: 'form[id="CreditCardForm"]',
            continueSelector: '[id="applyBTnCard"]'
        });
        
        $('input').each(function(){
        	var hasAttrMaxlength = $(this)[0].hasAttribute('maxlength');
            if( hasAttrMaxlength ){
            	$(this).keyup(function(e) {
            		var max = $(this).attr('maxlength');
        		    if ($(this).val().length > max) {
        		    	$(this).val($(this).val().substr(0, max));
        		    }
            	});
            }
        });
        $('#RegistrationForm').on('submit', function(){
            phoneValid();
        });
        $("input[name$='_profile_customer_phoneMobile']").on("change",function(){
            phoneValid();
        });

    },
    initCartLogin: function () {
        login.init();
    }
};
function SymbolDelete() {
    $('#edit-address-form').find("input").on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9-, ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
           event.preventDefault();
           return false;
        }
    });
    return true;
}

function emojiDelete() {
    var ranges = ['[\u1000-\uFFFF]'] //Contains all emojis
    var specialChars = /[?;:§!@#~^°µ<>{}\[\]|\\\/\*\$%\^\&*\)\(+=_-]+/;
    $('#edit-address-form').find("input").on('keyup', function (event) {
        //remove smily
        var str = $(this).val();
        str = str.replace(new RegExp(ranges.join('|'), 'ig'), '');
        // remove special Chars
        str = str.replace(specialChars,'');
        $(this).val(str);
    });
    return true;
}

function calculateAge(strDate) {
    strDate = strDate.split('/');
    var birthMonth = strDate[0]-1,
        birthDay = strDate[1],
        now = new Date(),
        nowMonth = now.getMonth(),
        nowDay = now.getDate(),
        age = now.getFullYear()-strDate[2];

    if( nowMonth < birthMonth || nowMonth == birthMonth && nowDay < birthDay) {
        age--;
    }
    return age;
}
function controlAge() {
    $('#RegistrationForm button').on('click', function(e) {
        var birthday = $('#dwfrm_profile_customer_birthday').val();
        var age = calculateAge(birthday);
        var ageControl = 18;
        if( age < ageControl ) {
            $('.minor-msg').removeClass('hide');
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".minor-msg").offset().top - 250
            }, 1000);
            e.preventDefault();
        } else {
            $('.minor-msg').addClass('hide');
        }
    });
}

function controlPhone() {
    $('#RegistrationForm button').on('click', function(e) {
    var pattern =/^0[0-9]{1,14}$/;
    var number = $("input[name$='_profile_customer_phoneMobile']").val();
         $("#profile_input_error").remove();

     if ($("#profile_input_error").length){
        $("#profile_input_error").remove();
        }
        const inputErr = $("<span id='profile_input_error' class='error bold text-left w-100'>"+ Resources.INVALID_PHONE  +"</span>");
            if(number)
                {
        if(pattern.test(number))
        {
            $("#profile_input_error").remove();
        } else {
            $(document).find("input[name$='_profile_customer_phoneMobile']").parent().append(inputErr).find('.error').addClass('customtop');
            e.preventDefault();

        }
    }

    });
}
module.exports = account;
