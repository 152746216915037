'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util');

var shippingMethods;
const $shippingMethodList = $('#shipping-method-list'); 


/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */

function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');

    // indicate progress
    progress.show($summary);

    // load the updated summary area

    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();


    });
}


function showProductsSummary() {

    $('.see-products').on('click', function () {
        $('.overlay').fadeIn(500, function () {
            $('#secondary').addClass('active');
            $("html , body").animate({scrollTop: 0}, "slow");
        });

        return;

    });


    $('.checkout-mini-cart i ,  .back-link ').on('click', function () {
        $('.overlay').fadeOut();
        $('#secondary').removeClass('active');
    });
}

function updateSummaryWithShippingMethod(el) {

    var $summary = $('#secondary.summary');


    // indicate progress
    progress.show($summary);

    // load the updated summary area

    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        var name = el.find('.shpping-method-name').html(),
            price = el.find('.shipping-method-price').html();

        if (typeof name !== 'undefined' && typeof price !== 'undefined') {
            $('.pt_checkout .primary-focus #secondary')
                .append('<div class="overlay"></div>')
                .find('#shipping-method').after('<div class="mini-summary-shipping-method">' + name + price + '</div>');
        }


        showProductsSummary();

    });

    formPrepare.validateForm();
    $("#dwfrm_singleshipping_shippingAddress_shippingMethodID-error").remove();

    //verify if there is a shipping method checked
    var $shippingmethodselector = $shippingMethodList.find('[name$="_shippingMethodID"][checked="checked"]');
    $shippingmethodselector = $shippingmethodselector ? $shippingmethodselector : '';
    disableSMPrices($shippingmethodselector);
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var stateCode;
    if ($form.find('select[id$="_state"]') != null) {
        stateCode = $form.find('select[id$="_state"]').val();
    }
    else if ($form.find('select[id$="_stateCA"]').val() != null) {
        stateCode = $form.find('select[id$="_stateCA"]').val();

    }
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: stateCode,
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            var el = $('input[value = ' + shippingMethodID + ']').parents('.eligiblemethod');

            updateSummaryWithShippingMethod(el);

            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();


            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethodColissimo(shippingMethodID, pointretraitId, deliveryAddress, relayPointType) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMColissimo, {
        shippingMethodID: shippingMethodID,
        colissimoRequestId: pointretraitId,
        relayPointType: relayPointType,
        deliveryAddress: deliveryAddress
    });
    ajax.getJson({
        url: url,
        callback: function (data) {
            var el = $('input[value = ' + shippingMethodID + ']').parents('.eligiblemethod'); 
            updateSummaryWithShippingMethod(el);
            //updateSummary();

            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

        }
    });
}

/**
 * @function
 * @description disable shipping method prices not selected
 * @param
 */
function disableSMPrices($this) {
    var $pricessibblingstodisable = $shippingMethodList.find('[name$="_shippingMethodID"]');
    if ($this) {
        var id = $this.attr('id');
        $this.siblings().find('.shipping-method-price').removeClass('text-grey');
        $pricessibblingstodisable = $pricessibblingstodisable.not('#' + id);
    }

    $pricessibblingstodisable.each(function () {
        $(this).siblings().find('.shipping-method-price').addClass('text-grey');
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */

function tooltipShow() {
    $('.primary-content #shipping-method-list .form-row.form-indent').each(function (index, value) {

        var el = $(this).find('.custom-tooltip'),
            toShow = el.find('.custom-tooltip-content .surcharge-product').length;

        if (toShow > 0) {
            $('.primary-content .form-row-' + index).find('.custom-tooltip').show()
                .mouseover(function () {
                    $(this).find('.custom-tooltip-content').addClass('activeToolTip')
                }).mouseout(function () {
                $(this).find('.custom-tooltip-content').removeClass('activeToolTip')
            })
        }
    });
}

function populatePointRelais(src, isNewAdress) {
    var dest = $('.relais-form'),
        data = {};
    if (!isNewAdress) {
        data.address = src.find('.data-hidden .address').text();
        data.postalCode = src.find('.data-hidden .postalCode').text();
        data.city = src.find('.data-hidden .city').text();

    }
    else {
        data.address = $('#dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_address1').val();
        data.postalCode = $('#dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_postal').val();
        data.city = $('#dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_city').val();

    }

    dest.find('#dwfrm_retraitcolissimo_address1').val(data.address);
    dest.find('#dwfrm_retraitcolissimo_postalCode').val(data.postalCode);
    dest.find('#dwfrm_retraitcolissimo_city').val(data.city);


}

function findValueInArray(value,arr){ 
	var result = "noexist";
	for(var i=0; i<arr.length; i++){
		var name = arr[i];
		if(name === value){
			result = "exist";
			break;
		}
	}
	return result;
}
function eachinput(){
	var isEmpty_ = [];
	$('.shipping-address-form input.required').each(function(i) {
        // If value is not empty
		  
			  if ($(this).val().length === 0) {
				isEmpty_[i] = 'existval';
			  }else{
				isEmpty_[i] = true;
			  }
  });
  return isEmpty_;
}
//display shiping methode only if all requeired dield is not empty
function DSM_requeriedF(){
	var isEmpty = [];
	$('.shipping-address-form input.required').keyup(function() {
		var isEmpty = eachinput();
		var findV = findValueInArray('existval', isEmpty);
		
		var countrycodeselected = false;
		    countrycodeselected = $('.input-select.country').attr('data-info');
		    
		if(findV === "noexist" && countrycodeselected){
			
			$shippingMethodList.fadeIn('fast');
		}else{
			$shippingMethodList.hide();
		}
		
	}).keyup(); 
} 

function selectPays(countrycode){
	var isEmpty = eachinput();
	var findV = findValueInArray('existval', isEmpty);
	
	if(findV === "noexist" && countrycode){
		
		$shippingMethodList.fadeIn('fast');
	}else{
		$shippingMethodList.hide();
	}
}
function DSM_chekedbox(){
		function chekboxstatus(){
			 if($(this).is(":checked")) {
		        	$shippingMethodList.fadeIn('fast');
		        }else{
		        	$shippingMethodList.hide();
		        }
		}
		chekboxstatus();
	    $('#shipping_radio0').change(function() {
	    	chekboxstatus();
	    });
}

function updateShippingMethodList() {
     
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        return;
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
            	
            	var countrycode = true;
            	selectPays(countrycode);
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
            	
            	var countrycode = $('.shipping-address-form select').find('option:selected').val();
            	selectPays(countrycode);
                
                // rebind the radio buttons onclick function to a handler.

                if ($shippingMethodList.find('[name$="_shippingMethodID"]:checked').hasClass('ispointretrait')) {
                    $('.relais-container').removeClass('hide');
                    $('.relais-container').addClass('show');
                    $('input[name=choixrelaisvalue]').removeClass('hide');
                    $('input[name=choixrelaisvalue]').addClass('show');

                } else {
                    $('.relais-container').removeClass('show');
                    $('.relais-container').addClass('hide');
                    $('input[name=choixrelaisvalue]').removeClass('show');
                    $('input[name=choixrelaisvalue]').addClass('hide');
                }

                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {

                    if ($(this).is(':checked') && $(this).hasClass('ispointretrait')) {

                        var src = "";

                        var listCheckbox = $('.shipping-address-form  .list-blocs-width-check-list'),
                            isNewAdress = false;

                        if (!$('.shipping-address-form .civility-radios').hasClass('hidden')) {
                            isNewAdress = true;
                        }


                        if (isNewAdress) {
                            src = '#newAdress';
                        }
                        else {
                            listCheckbox.find('.checkbox').each(function () {
                                if ($(this).find('input.input-radio').is(':checked')) {
                                    src = $(this);
                                }
                            });
                        }

                        populatePointRelais(src, isNewAdress);
                        $('.relais-container').removeClass('hide');
                        $('.relais-container').addClass('show');
                        $('input[name=choixrelaisvalue]').removeClass('hide');
                        $('input[name=choixrelaisvalue]').addClass('show');
                        if($(this).closest('.form-row').hasClass('eligiblemethod')){
                            updateSummaryWithShippingMethod($(this).parents('.eligiblemethod'));
                        }

                    }else {
                        $('.relais-container').removeClass('show');
                        $('.relais-container').addClass('hide');
                        $('input[name=choixrelaisvalue]').removeClass('show');
                        $('input[name=choixrelaisvalue]').addClass('hide');
                        if($(this).closest('.form-row').hasClass('eligiblemethod')){
                            selectShippingMethod($(this).val());
                        }

                        disableSMPrices($(this));
                    }

                    checkShippingRadio();

                });


                // update the summary

                progress.hide();
                tooltip.init();
                tooltipShow();
                updateSummaryWithShippingMethod($(this).find('[name$="_shippingMethodID"]:checked').parents('.eligiblemethod'));
                


                //if nothing is selected in the shipping methods select the first one
                /*if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                 $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                 }*/
                $(document).on('click', '.choixrelais:not(.text-grey)',
                    function (e) {
                        e.preventDefault();
                        //$('.choixrelais').parents('.radio').find('.input-radio').removeAttr('checked');
                        var el = $(this).attr('for');
                        $('#' + el).attr('checked', 'checked');
                        var shippingMId = $('.ispointretrait').val();
                        selectShippingMethodColissimo(shippingMId, $(this).prev('input[name=pointrelais]').val(), $(this).find('input:hidden[class="deliveryaddresstxt"]').val(), $(this).find('input:hidden[class="relaypointtype"]').val());
                        if ($(this).prev('input[name=pointrelais]').val() != '') {
                            $('input[name=choixrelaisvalue]').val($(this).prev('input[name=pointrelais]').val());
                        }
                        updateSummaryWithShippingMethod($(this).parents('.eligiblemethod'));
                        disableSMPrices($(this));

                    });

                // Active confirm Buttton after loading Shipping Methods
                $('button[name$="shippingbilling_save"]').removeAttr("disabled");
//               

            });
        }
    });
}

function checkShippingRadio() {
    var shippingmethodradio = $('[name$="_shippingMethodID"]:checked');
    
    if (shippingmethodradio.length < 1) {
        $('#dwfrm_shippingmethode-error').removeClass('hide');
        $('#dwfrm_shippingmethode-error').addClass('show');
    } else {
        $('#dwfrm_shippingmethode-error').removeClass('show');
        $('#dwfrm_shippingmethode-error').addClass('hide');
    }
}

exports.init = function () {

    $('.addtoAdressBook .field-wrapper > .input-checkbox').on('change', function () {
        var state = $(this).is(':checked');
        var el = $(this).parents('.addtoAdressBook').next();
        if (!state) {
            el.find('input').removeAttr('required');
            el.hide();
        }
        else {
            el.find('input').attr('required', 'required');
            el.show();
        }
    });


    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector: '[id$="shippingbilling"]'
    });

    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );

    $('.address').on('click', 'button[name$="shippingbilling_save"]', function () {
        checkShippingRadio();
    });
    
    $("input[name*='_addressFields_firstName']").attr({
		'maxlength' : 25
	});
    
    $("input[name*='_addressFields_lastName']").attr({
		'maxlength' : 25
	});
    $("input[name*='_addressFields_companyName']").attr({
    	'maxlength' : 25
	});    
    $("input[name*='_addressFields_address2']").attr({
			'maxlength' : 35
		});
    $("input[name*='_addressFields_address1']").attr({
		'maxlength' : 35
	});
    
    
    $("input[name*='_addressFields_city']").attr({
		'maxlength' : 40
	});

    $("input[name*='_addressFields_postal']").attr({
		'maxlength' : 10
	});

    $("input[name*='_addressFields_phone']").attr({
		'maxlength' : 15
	});

    giftMessageBox();
    updateShippingMethodList();
    DSM_requeriedF();
    DSM_chekedbox();
    $.fn.select2.defaults.set("theme", "bootstrap");
    $('.shipping-address-form .select-country .input-select.country').select2({
        minimumResultsForSearch: -1
    });
    $('.billing-address-form .input-select.country').select2({
        minimumResultsForSearch: -1
    });
    $(' .shipping-address-form .select-country .input-select.country').on("select2:select", function (event) {
        $('.shipping-address-form .select-country').find('input').val(event.params.data.text);
        $('.shipping-address-form .input-select.country').attr('data-info', 'true');
        //init select country label
        $('#select2-dwfrm_shippingbilling_singleshipping_shippingAddress_addressFields_country-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');
    });
    $('.shipping-address-form .select-country .input-select.country').on('select2:close', function (e) {
        updateShippingMethodList();
    });
    $(' .billing-address-form .input-select.country').on("select2:select", function (event) {
        $('.billing-address-form .select-country').find('input').val(event.params.data.text);
        //init select country label
        $('#select2-dwfrm_shippingbilling_billing_billingAddress_addressFields_country-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');
    });


    $(".pt_checkout .form-horizontal .form .form-row .field-wrapper input.input-text").on('focus', function () {

        var elem = $(this);
        setTimeout(function () {
            //elem.parents('.form-row').find('label ').addClass('animate-label');
            elem.parent().addClass("focused");
        }, 0);
    });
    $(".pt_checkout .form-horizontal .form .form-row .field-wrapper input.input-text").on('focusout', function () {
        var elem = $(this);
        setTimeout(function () {
            //elem.parents('.form-row').find('label').removeClass('animate-label');
            elem.parent().removeClass("focused");
        }, 0);
    });
    
    
    
    $('input').each(function(){
    	var hasAttrMaxlength = $(this)[0].hasAttribute('maxlength');
        if( hasAttrMaxlength ){
        	$(this).keyup(function(e) {
        		var max = $(this).attr('maxlength');
    		    if ($(this).val().length > max) {
    		    	$(this).val($(this).val().substr(0, max));
    		    }
        	});
        }
    });

};

exports.updateShippingMethodList = updateShippingMethodList;
