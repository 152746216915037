'use strict';
exports.init = function() {
	// PRODUCT SELECTION
	$(".product-selection").hide();
	$(".product-selection").first().addClass('active-selection');
	$(".product-selection").first().show();
	// Next Event Handler
	$('.show-more-selection').on('click', showMore);// call function showMore
	// Show more selection
	function showMore() {
		$(".active-selection").next().addClass("active-selection");
		$(".active-selection").show();
		if ($('.product-selection').last().hasClass('active-selection')) {
			$('.show-more-selection').addClass('hide');
		}
	}
	// PUSH DATALAYER TO GOOGLE-TAG-MANAGER ON CLICK CONTENTSLOT Vitr1
	$('.big-content').on('click', function(e) {
		if (typeof dataLayer !== 'undefined') {
			var id = $(this).attr('data-id');
			var name = $(this).attr('data-name');
			var brand = $(this).attr('data-creative');
			var position = $(this).attr('data-position');
			dataLayer.push({
				"event" : "promotionClick",
				"ecommerce" : {
					"promoClick" : {
						"promotions" : [ {
							"id" : id,
							"name" : name,
							"brand" : brand,
							"position" : position
						} ]
					}
				}
			});
		}

	});
	// PUSH DATALAYER TO GOOGLE-TAG-MANAGER ON CLICK CONTENTSLOT
	// Vitr2/Vitr3/Vitr4 (small-content)
	$('.small-content').on('click', function(e) {
		if (typeof dataLayer !== 'undefined') {
			var id = $(this).attr('data-id');
			var name = $(this).attr('data-name');
			var brand = $(this).attr('data-creative');
			var position = $(this).attr('data-position');
			dataLayer.push({
				"event" : "promotionClick",
				"ecommerce" : {
					"promoClick" : {
						"promotions" : [ {
							"id" : id,
							"name" : name,
							"brand" : brand,
							"position" : position
						} ]
					}
				}
			});
		}
	});
	// PUSH PROMOTIONS IN HOMEPAGE TO GOOGLE TAG MANAGER
	function pushPromoViewHomePage() {
		if (typeof dataLayer !== 'undefined') {
			var tabPromos = [];

			$('.home-content .big-content').each(function(e) {
				var id = $(this).attr('data-id');
				var name = $(this).attr('data-name');
				var brand = $(this).attr('data-creative');
				var position = $(this).attr('data-position');
				var promo = {
					id : id,
					name : name,
					creative : brand,
					position : position
				};

				tabPromos.push(promo);

			});

			$('.small-content').each(function(e) {
				var id = $(this).attr('data-id');
				var name = $(this).attr('data-name');
				var brand = $(this).attr('data-creative');
				var position = $(this).attr('data-position');
				var promo = {
					id : id,
					name : name,
					creative : brand,
					position : position
				};

				tabPromos.push(promo);
			});

			if (tabPromos.length > 0) {
				// CHECK IF ecommerce EXIST
				var isEcommerce = false;
				for ( var e in dataLayer) {
					if (dataLayer[e].ecommerce) {
						dataLayer[e].ecommerce.promoView = {
							promotions : tabPromos
						};
						isEcommerce = true;
						return;
					}
				}
				//in case of no ecommerce object
				dataLayer.push({ecommerce :  {promoView : {promotions : tabPromos}}});
			}

		}

	}

	pushPromoViewHomePage();
};
