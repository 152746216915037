'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[0-9]{1,14}$/, // /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^\d{2}(?:-\d{4}-\d{4}|\d{8}|\d-\d{3,4}-\d{4})$/,
        cn: /.*/,
        gb: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][A-Z]{2}[A-Z]?|GIR 0AA)$|(^([CESW][CW0-9]?[0-9][A-Z] [0-9][A-Z][A-Z])$)|(^(\CF\d+\w*)$)/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    noSpecialChar: /^[a-zA-ZÀ-ÿ0-9][a-zA-ZÀ-ÿ0-9 .,'-]*$/,
    phonevalidator: /^0[0-9]{1,14}$/,
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var i =0;
var specialChars = "<>@!#$%^&*()_[]{}?:;|'\"\\,./~`-=²éàùÜÝÞßàáÓÇÉÆËÉÅÙÎÓÍÊÆÇÅàÖááÖÆáÓáÖâÍÆËÆÉÓÔÓÎÅËÊÖáÓïÓÛâÍÖÖáÓÕÉÅÕÖáÉÆÓÎÆêÓÊÅËÚÓáÓÅðñÌáÉÆ×ÅíÊÓÐÆáØÖÕûÅÎÅáÅËÓíüáýËÓÇÅËÊÖÚÅÆËÛÖÕÜøÊÖïñëöàöìöóóòõïùìëìíîïðñëöýÿïüïóëúû¡¢£¤¥!";
	var check = function(string){
	    for(i = 0; i < specialChars.length;i++){
	        if(string.indexOf(specialChars[i]) > -1){
	            return true;
	        }
	    }
	    return false;
	};
var validatePhone = function (value, el) {
	
	//check if we are on checkout page then disable phone control :form parent by class
	var isCheckoutPage = false;
	if ($(el).closest('.checkout-billing').length > 0 || $(el).closest('.checkoutlogin').length > 0) {
		isCheckoutPage = true;		
	}
	
	var id = $(el).attr('id');
	var alphabet="";
	var addresstype = id.indexOf('billing') !== -1 ? 'billing' : 'shipping';
	
	var selector = '.country';
	if (id.indexOf('billing') !== -1  || id.indexOf('shipping') !== -1 ) {
		selector = 'select[id$="'+addresstype+'Address_addressFields_country"]';
	}else{
		selector = 'select[id$="dwfrm_profile_address_countryCode"]';
	}
    var country = $(el).closest('form').find(selector);
    if (country.length === 0 || country.val() === "" || (!regex.phone[country.val().toLowerCase()] && !isCheckoutPage) ) {
        return true;
    }
    if (value.match(/[a-z]/i)) {
         alphabet = "yes";
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = isCheckoutPage ? true : rgx.test($.trim(value));
    var countPlus = 0;
    var pos = value.indexOf("+");
    if(pos > 0){
    	isValid = false;
    }
    
    while ( pos != -1 ) {
    	countPlus++;
       pos = value.indexOf( "+",pos + 1 );
    }
    
	if(check(value) == false){
	    //console.log('Pas de caractére spécial!');
	}else{
		isValid = false;
	}
    if(value.length > 20 || countPlus > 1 || alphabet ==="yes"){
    	isValid = false;
    }
    return isOptional || isValid;
};

var validateVisa = function (value, el) {
	value = value.replace(/ /g,""); 
	if (!(value.length == 13 || value.length == 16 || value.length == 19)) {
		return false;
	}
	
    return true;
};

var validateAmex = function (value, el) {
	
	value = value.replace(/ /g,""); 
	if (value.length != 15) {
		return false;
	}
	
    return true;
};


var validateMasterCard = function (value, el) {
	
	value = value.replace(/ /g,""); 
	if (value.length != 16) {
		return false;
	}
	
    return true;
};


var validateCcvVisa = function (value, el) {
	value = value.replace(/ /g,""); 
	if (value.length != 3) {
		return false;
	}
	
    return true;
};

var validateCcvAmex = function (value, el) {
	
	value = value.replace(/ /g,""); 
	if (value.length != 4) {
		return false;
	}
	
    return true;
};


var validateCcvMasterCard = function (value, el) {
	
	value = value.replace(/ /g,""); 
	if (value.length != 3) {
		return false;
	}
	
    return true;
};

$.validator.addMethod('email', function (value, element) {
    return this.optional(element) || /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(value);
}, Resources.VALIDATE_EMAIL);

var validatePostal = function (value, el) {
	var id = $(el).attr('id');
	var addresstype = id.indexOf('billingAddress') !== -1 ? 'billing' : 'shipping';
	
	var selector = '.country';
	if (id.indexOf('billingAddress') !== -1  || id.indexOf('shippingAddress') !== -1 ) {
		selector = 'select[id$="'+addresstype+'Address_addressFields_country"]';
	} else {
		selector = 'select[id$="dwfrm_profile_address_countryCode"]';
	}
	var country = $(el).closest('form').find(selector);
	if (country.length === 0 || country.val() === "" || !regex.postal[country.val().toLowerCase()]) {
	    return true;
	}
	
	var rgx = regex.postal[country.val().toLowerCase()];
	var isOptional = this.optional(el);
	var isValid = rgx.test($.trim(value));
	if(country.val().toLowerCase() != "jp"){
		if(check(value) == false) {
			   //console.log('Pas de caractére spécial!');
			} else {
				isValid = false;
			}
	}
	
	if(value.length > 20){
		isValid = false;
	}
	return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * Add visa card number validation method to jQuery validation plugin.
 * Text fields must have 'visa' css class to be validated as phone
 */ 
$.validator.addMethod('visa', validateVisa, Resources.INVALID_VISA);

/**
 * Add amex card number validation method to jQuery validation plugin.
 * Text fields must have 'amex' css class to be validated as phone
 */
$.validator.addMethod('amex', validateAmex, Resources.INVALID_AMEX);

/**
 * Add master card number validation method to jQuery validation plugin.
 * Text fields must have 'mastercard' css class to be validated as phone
 */
$.validator.addMethod('mastercard', validateMasterCard, Resources.INVALID_MASTERCARD);

/**
 * Add visa ccv card number validation method to jQuery validation plugin.
 * Text fields must have 'ccvvisa' css class to be validated as phone
 */ 
$.validator.addMethod('ccvvisa', validateCcvVisa, Resources.INVALID_CCV_VISA);

/**
 * Add amex ccv card number validation method to jQuery validation plugin.
 * Text fields must have 'ccvamex' css class to be validated as phone
 */
$.validator.addMethod('ccvamex', validateCcvAmex, Resources.INVALID_CCV_AMEX);

/**
 * Add master ccv card number validation method to jQuery validation plugin.
 * Text fields must have 'ccvmastercard' css class to be validated as phone
 */
$.validator.addMethod('ccvmastercard', validateCcvMasterCard, Resources.INVALID_CCV_MASTERCARD);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add postal validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as phone
 */
$.validator.addMethod('postal', validatePostal, Resources.INVALID_POSTAL);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * Add no special char validation method to jQuery validation plugin.
 * Text fields must have 'no-special-char' css class to be validated
 */
$.validator.addMethod('nospecialchar', function (value) {
    if ($.trim(value) === '') {
        return true;
    }
    var isValid = regex.noSpecialChar.test($.trim(value)); 
    return isValid;
}, Resources.VALIDATE_SPECIALCHARCHECK);

$.validator.addMethod('phonevalidator', function (value) {
    if ($.trim(value) === '') {
        return true;
    }
    var isValid = regex.phonevalidator.test(value); 
    if(isValid == false && $('#edit-address-form').length > 0){
         $('span.error').css('margin-top','50px;');
    }
    return isValid;
}, Resources.INVALID_PHONE);

$.validator.addMethod('cityMaxLength', function (value) {
    if ($.trim(value) === '') {
        return true;
    }
    var isValid = true;
    if(value.length < 41){
        isValid = true; 
    }else{
        isValid = false; 
    }
    
    return isValid;
}, Resources.VALIDATE_EMPTY);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN),
    visa: Resources.VALIDATE_VISA,
    amex: Resources.VALIDATE_AMEX,
    mastercard: Resources.VALIDATE_MASTERCARD,
    ccvvisa: Resources.VALIDATE_CCV_VISA,
    ccvamex: Resources.VALIDATE_CCV_AMEX,
    ccvmastercard: Resources.VALIDATE_CCV_MASTERCARD,
    nospecialchar: Resources.VALIDATE_SPECIALCHARCHECK,
    phonevalidator: Resources.VALIDATE_NUMBER
    
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
