'use strict';

var progress = require('../progress'); 

var colissimo = {
	init : function searchPointRetrait() {
		
		$(document).on('click', '.retraitColissimoSearch, .retraitColissimoEdit',
				function(e) {

					var _address = $('#dwfrm_retraitcolissimo_address1').val();
					var _cp = $('#dwfrm_retraitcolissimo_postalCode').val();
					var _city = $('#dwfrm_retraitcolissimo_city').val();
					var data = {
						address : _address,
						cp : _cp,
						city : _city
					};
					progress.show($('.relais-form .pointrelaiscontainer'));
					$.ajax({
						url : Urls.searchPointRetraitColissimo,
						type : "POST",
						data : data,
						success : function(result) {
							if (result) {
							//	$('.relais-form .pointrelaiscontainer').html('');
								
								$('.relais-form .pointrelaiscontainer').empty().append(result);
								
								$('.relais-container').addClass('isactive');

								var $container = $('.pointrelaiscontainer');
						        
								$container.niceScroll({
									cursorcolor: "#e6e6e6",
									cursorwidth: "3px"
								});

								var allpointrelais = $('.pointrelaiscontainer .radio.iscongetotal');
						        allpointrelais.each(function () {
						           $(this).find('input').attr('disabled','disabled');
						           $(this).find('label').addClass('text-grey');
						        });
						        
								
							}

						}

					});
				});

	}

}
module.exports = colissimo;