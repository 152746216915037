'use strict';

var progress = require('../progress'),
    util = require('../util');

    function updateUrlPageParam(currentPage) {
        var absUrl = window.location.pathname;
        if(window.location.search.indexOf("page="+currentPage) < 0){
            if (currentPage > 1) {
                var url = absUrl + "?page=" + currentPage;
                window.history.pushState({ path: url }, '', url);
            } else {
                window.history.pushState({ path: absUrl }, '', absUrl);
            }
        }
    }

    function updateUrlPageOnScroll() {
        var scrollPreviousPosition = $(window).scrollTop();
        var scrollDirection = 'up';
        var scrollCurrentPosition = $(window).scrollTop();
        if (scrollCurrentPosition > scrollPreviousPosition) {
            scrollDirection = 'down';
        }
        var productGridRange = [];
        var grids = document.getElementsByClassName('store-page');
        Array.prototype.forEach.call(grids, function (element) {
            var top = element.offsetTop;
            var bottom = top + element.offsetHeight;
            productGridRange.push({ from: top, to: bottom });
        });
        const targetGrid = productGridRange.map(e => (scrollCurrentPosition >= e.from) && (scrollCurrentPosition <= e.to));
        var index = targetGrid.indexOf(true);
        var element = $(grids[index]);
        var page = parseInt(element.find("input#currentPage").val());
        if (page >= 0) {
            updateUrlPageParam(page + 1);
        }
        scrollPreviousPosition = $(window).scrollTop();
    }

function isNearFooter(offset = 250) {
    var footerTop = $('.footer_v3').offset().top;
    var viewportBottom = $(window).scrollTop() + $(window).height();
    return (viewportBottom + offset) >= footerTop;
}

function contentInfiniteScroll() {
	updateUrlPageOnScroll();
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && isNearFooter(250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');
 

        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('div.search-result-content').append(html);
            $('#show-more-content').hide();
            var newStart = parseInt(loadingPlaceHolder.attr('data-start')) + 6;
            var dataFinish = parseInt(loadingPlaceHolder.attr('data-finish')) ;
            var currentPage = newStart / 6;
            if( newStart < dataFinish){
            	loadingPlaceHolder.attr('data-start', newStart);
                loadingPlaceHolder.attr('data-loading-state', 'unloaded');
            	newStart = 'start=' + newStart; 
            	gridUrl = gridUrl.replace(/start=\d+/, newStart);
            	loadingPlaceHolder.attr('data-grid-url', gridUrl); 
            }else{
            		$('#show-more-content').hide();
            }
                    	
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }

                fillEndlessScrollChunk(response);
                

                
            }
        });
    }
}


/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
}

exports.init = function () {

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        if($(".store-page").length > 0) {
            $(document).on('scroll', contentInfiniteScroll);
        }   
    } else {
        $(document).on('click', '#show-more-content', contentInfiniteScroll);
    }

    // contact form
    var $recaptcha = document.querySelector('.g-recaptcha #g-recaptcha-response');
    if($recaptcha) {
        $recaptcha.setAttribute("required", "required");
    }
    // traiteur form
    var $recaptchatraiteur = document.querySelector('.g-recaptcha #g-recaptcha-response-1');
    if($recaptchatraiteur) {
        $recaptchatraiteur.setAttribute("required", "required");
    }
    // Cadeau form
    var $recaptchacadeau = document.querySelector('.g-recaptcha #g-recaptcha-response-2');
    if($recaptchacadeau) {
        $recaptchacadeau.setAttribute("required", "required");
    }
    initializeEvents();
};
