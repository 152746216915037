'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    minicart = require('../../minicart'),
    addToWishlist = require('./addToWishlist'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    formPrepare = require('./../checkout/formPrepare');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
}

function pushDataLayerProductClick() {
    if (typeof dataLayer !== 'undefined') {

        //Social Network Share
        $('.social-list > li > a').on('click', function (e) {
            //e.preventDefault();
            var sn = $(this).attr('data-share');
            dataLayer.push({
                "event": "eventGA",
                "categoryGA": "social",
                "actionGA": "share",
                "labelGA": sn,
                "valueGA": ""
            });
        });


        var tiles = $('.product-tile');
        tiles.on('click', function (e) {
            var name = $(this).attr('data-itemname');
            var id = $(this).attr('data-itemid');
            var price = parseFloat($(this).attr('data-itemprice'));
            var brand = $(this).attr('data-itembrand');
            var variant = $(this).attr('data-itemvariant');
            var category = $(this).attr('data-itemcategory');
            var position = parseInt($(this).attr('data-itemposition'));
            var list = $(this).attr('data-itemlist');
            dataLayer.push({
                "event": "productClick",
                "ecommerce": {
                    'click': {
                        "actionField": {"list": list},
                        "products": [{
                            "name": name,
                            "id": id,
                            "price": price,
                            "brand": brand,
                            "category": category,
                            "variant": variant,
                            "position": position
                        }]
                    }
                }
            });
            return;
        });

    }

}
pushDataLayerProductClick();


function splitValNutirion() {
    var $el = $('.product-overlay-content .nutritionalValues');
    var string = $el.text();
    var result = string.split('|');
    var temp = result.map(function (v, i, a) {
        return v.split(":");
    });
    $el.empty();
    $.each(temp, function (index, value) {
        $el.append('<div> <div class="left">' + value[0] + ' <span class="n-value">' + value[1] + '</span></div></div>');


    });

}


/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    splitValNutirion();

    // add to wishlist
    addToWishlist();
    availability();
    variant();
    image();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    if($('.js-personalize').length) {
        $('.js-personalize').show();
    }

    if ($('.not-in-stock').length) {

        formPrepare.init({
            formSelector: 'form[id="MailAlertInStockForm"]',
            continueSelector: '[name="dwfrm_mailalertinstock_confirm"]'
        });

        $('.showPopup').on('click', function () {
            $('.popup-content.before').fadeIn(400, function () {
                $('.popup-content.after').addClass('hide').removeClass('animated');
            });
            $('body , html').addClass('removeOverflow');
            $('.product-col-2 .popupAgeContainer').fadeIn();
            $('.pt_product-details .overlay-popup').fadeIn();

            $('#MailAlertInStockForm').find('input[name*=email]').val('');
            $('input.input-text').parent('.field-wrapper').prev('label').children('.form-name').removeClass('show');
            $('input.input-text').parent('.field-wrapper').prev('label').children('.form-name').addClass('hide');
            $('.popupAgeContainer').find('.content-asset').removeClass('spacebottom');

        });

        $('.product-col-2 .popupAgeContainer .popup-header i').on('click', function () {
            $('body , html').removeClass('removeOverflow');
            $('.product-col-2 .popupAgeContainer').fadeOut();
            $('.pt_product-details .overlay-popup').fadeOut();
        });

        $('#MailAlertInStockForm').on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            formPrepare.validateForm();

            if ($('button[name=dwfrm_mailalertinstock_confirm]').is(":disabled")) {
                return;
            }
            $('.popupAgeContainer').append('<div class="loader"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
            e.preventDefault();

            $.ajax({
                type: $form.attr('method'),
                url: Urls.alertStockForm,
                data: $form.serialize(),
                success: function (data) {
                    if (data.ErrorCode == 'formnotvalid') {
                        $('.popupAgeContainer').find('.loader').remove();
                    } else {
                        $('.popup-content.before').fadeOut(400, function () {
                            $('.popupAgeContainer').find('.loader').remove();
                            $('.popup-content.after').removeClass('hide').addClass('animated');
                            if (typeof dataLayer !== 'undefined') {
                                var productName = $('.DLProductName').text();
                                dataLayer.push({
                                    "event": "eventGA",
                                    "categoryGA": "stock",
                                    "actionGA": "alert",
                                    "labelGA": productName,
                                    "valueGA": ""
                                });
                            }
                        });
                    }
                },
                error: function (err) {
                    $('.popupAgeContainer').find('.loader').remove();
                }
            });
        });
    }    
}


if($('#radiocontrol_0').length)
    {
        var priceSale = $('.price-standard').text();

        
    }

function mobilePriceSpaceShow(price_orig, price_mob, price, tether)
{
    if($('#radiocontrol_0').length)
    {
  
    }
    else {
        if(!$(price_orig).closest('.product-set-details')) {
            $(price_orig).hide();
        }
        $(".price_mobile").remove();
        $(tether).append("<div class='"+price_mob+"' >"+price+"</div>");
    }

    if($('.lotPurchaseSets').length>0){
        var lotMobPriceContainer = $('<div>').addClass('lotPurchaseSetsMob');
        lotMobPriceContainer.append($('.lotPurchaseSets .lotbox-price').html());
        $('.price_mobile').append(lotMobPriceContainer);
        if($('.lotPurchaseSets').hasClass('active')){
            $('.product-price.price_mobile').find('.mobile_price').hide();
            $('.product-price.price_mobile').find('.product-weight_mobile').hide();
            $('.product-price.price_mobile').find('.lotPurchaseSetsMob').show();
        }else{
            $('.product-price.price_mobile').find('.mobile_price').show();
            $('.product-price.price_mobile').find('.product-weight_mobile').show();
            $('.product-price.price_mobile').find('.lotPurchaseSetsMob').hide();
        }
    }
    
}
function mobilePriceSpaceHide(price_orig)
{
    $( ".price_mobile" ).remove(); 
    $(price_orig).show(); 
}

var mobilePriceDisplay = function()
{
    if($('#radiocontrol_0').length)
    {
        return;
        var const_price = ".pdp-main .product-col-2 .product-radio-select label.active .product-price";
    }
    else {
        var const_price = ".pdp-main .product-col-2 .product-price";
    }
    
    const price_mobile = "bloc product-price price_mobile px-0";
    var pricesticky = ".product-quantity-v3";
    if($(const_price).find('.price-sale').html())
    {
        var price = $(const_price).find('.price-sale').html();
    }
    else
    {
        var price = $(const_price).html();
    }
    
	var $inventory_label = $(".product-add-to-cart .inventory label");
   
    if (!price== undefined)
    {
        if(price.includes("-")){
        //code master
        if($('#radiocontrol_0').length)
        {
            var priceElem = "<div class='mobile_pricedown ml-3'>"+$('.product-radio-select label.active .product-price-flex').html()+ $('.product-radio-select label.active .card-text').show().html()+ "</div>";
        }
        else {
            var priceElem = "<span class='mobile_spanprice'>"+price+"</span>";
        }
    }
        var width_w = $( window ).width();
        if (width_w < 1001)
        {          
            mobilePriceSpaceShow(const_price,price_mobile,priceElem,pricesticky);
		}
        else
        {
            mobilePriceSpaceHide(const_price);
        }
    }
    else
    {
        // code variant/normal product

        var product_promo = $(".price.price-standard").html();
        if(product_promo == null){
            product_promo = "";
        }else{
           product_promo = "<span class='price price-standard '>"+product_promo+"</span>";
        }

        var product_weight = $(".product-weight").html();
        if(product_weight == null){
            product_weight = "";
        }else{
           product_weight = "<div class='product-weight_mobile'>"+product_weight+"</div>";
        }
        
        if($('#radiocontrol_0').length)
        {
            var priceElem = "<div class='mobile_price text-accent'>"+price+ "<span class='ml-3 price price-standard '>"+priceSale+"</span></div>";
        }
        else {
            if(product_promo){
                var priceElem = "<div class='mobile_price text-accent'>"+price+ "<span class='ml-3 price price-standard '>"+$(".price.price-standard").html()+"</span></div>";
                $('.left').addClass("text-accent");
            }else{
                var priceElem = "<div class='mobile_price'>"+price+"</div>";
            }
            
        }

        var compElem = priceElem+product_weight;
        var width_w = $( window ).width();
        if (width_w < 992)
        {
            mobilePriceSpaceShow(const_price,price_mobile,compElem,pricesticky);
            if (!$('.inventory').hasClass('not-in-stock')) {
                $inventory_label.text("QTE"); 
            }
            else if($('.inventory').hasClass('not-in-stock'))
            {
                $(".product-quantity").css('padding-bottom', '50px');
            }
		}
        else
        {
            var quantity = $('.inventory label').text();
            $inventory_label.text(quantity);
            mobilePriceSpaceHide(const_price);
        }
    }

}

$(window).on("load resize",function() {
    mobilePriceDisplay(); 
});
    if($('#radiocontrol_0').length)
        {
            if($('.radiocontrol_1').width() > 225){
               $('.product-radio-select').css('max-width','580px')
               $('.radiocontrol_0').parent().css('flex','1');
               $('.radiocontrol_1').parent().css('flex','1');
            }
            else {
                $('.product-radio-select').css('max-width','480px')
                $('.radiocontrol_0').parent().css('flex','1');
               $('.radiocontrol_1').parent().css('flex','1');
            }
        }


        $(document).on( "click", '.add-to-cart',function(e) {
            if( $('.radiocontrol_0').hasClass("active")){
                $(".isLot").val(0);
            }
            else {
                $(".isLot").val(1);
            }
           
            });


function displayqteonclickadd(e){
	const invt = ".pdp-main .product-col-2 .product-add-to-cart .inventory";
	const invt2 = ".pdp-main .product-col-2 .product-add-to-cart .inventory .value-quantity";
    const product_add_to_cart = ".pdp-main .product-col-2 .product-add-to-cart";
    var toggleshow = $(".product-quantity");
    var vqte = $('.quantityInBasket');
    var valueQ_ = '.value-quantity';
    var productACC = $('.product-acc'); 
    if(productACC.length){
        toggleshow = productACC.find(".product-quantity");
        vqte = productACC.find('.quantityInBasket');
        valueQ_ = '.product-acc .value-quantity';
    }
    if($('.popin-personalize').length){
        toggleshow = $('.popin-personalize').find(".product-quantity");
        vqte = $('.popin-personalize').find('.quantityInBasket');
        valueQ_ = '.popin-personalize .value-quantity';
    }
	const vqte_bl = $(valueQ_);
	const mobilestiky = $(".mobilestiky");
	const addQ = $("#addQ");
	const pointer_events = "pointer-events";
	  var v1 = vqte.text();  
	      v1 = parseInt(v1);
	  var v2 = vqte_bl.text();  
      	  v2 = parseInt(v2);

    var vmax = $('.maximumOrderQuantity').text();

    if($('.product-acc').length){
        vmax = $('.product-acc').find('.maximumOrderQuantity').text();
    }

    if($('.popin-personalize').length){
        vmax = $('.popin-personalize').find('.maximumOrderQuantity').text();
    }
    
    vmax = parseInt(vmax);

     function valueQ(){
        
    	 var valueQte = $(valueQ_).html();
	    	 function showerror(e){
	    		 if ( v1 >= vmax ) {
                    toggleshow.addClass("maxQ_show");
                    $(product_add_to_cart+" .error").removeClass('hide');
                    $(product_add_to_cart+" .error").addClass('show');
                   
	    	    }else{
	    	    	toggleshow.removeClass("maxQ_show");
                    $(product_add_to_cart+" .error").addClass('hide');
	    	    }
	    	 }
    	    
    	    function showerror_bl(){
    	    	if(vmax == 2){
    	    		if ( v2 >= vmax ) {
    	    			addQ.css(pointer_events, "none");
    	    			toggleshow.addClass("maxQ_show");
    	    			mobilestiky.addClass('show');
    	    	    }else{
    	    	    	toggleshow.removeClass("maxQ_show");
    	    	    	mobilestiky.addClass('hide');
    	    	    	addQ.removeAttr(pointer_events);
    	    	    }
    	    	}else{
    	    		if(vmax != 0){
        	    		if ( v2+1 >= vmax ) {
        	    			addQ.css(pointer_events, "none");
        	    			toggleshow.addClass("maxQ_show");
        	    			mobilestiky.addClass('show');
        	    	    }else{
        	    	    	toggleshow.removeClass("maxQ_show");
        	    	    	mobilestiky.addClass('hide');
        	    	    	addQ.removeAttr(pointer_events);
        	    	    }
        	    	}
    	    	} 
	    	 }
    	    showerror_bl();
    	    
    	    $(document).on('click', "#addQtocart", function() {
    	    	v1 = parseInt(vqte.text());
    	    	showerror();
    	    });

            $(document).on('click', ".removeQ1", function() {
                $('.maximumOrderQuantitymsg').removeClass("d-block");
                $('.maximumOrderQuantitymsg').addClass("d-none");
    	    });
    	    function showerror_master(){
                var valueQte_master = parseInt($(valueQ_).html());
                var vmax_master = parseInt($('.maximumOrderQuantity').text());
	    		 if ( valueQte_master >= vmax_master ) {
                    $('.maximumOrderQuantitymsg').addClass("d-block");
                    $('.maximumOrderQuantitymsg').removeClass("d-none");
	    	    }
	    	
            }
        $(document).on('click', addQ, function() {
                
    	    	v2 = parseInt(vqte_bl.text());
    	    	if(vmax == 2 && v2 == 1){
    	    		v2++;
                }
    	    	showerror_bl();
    	    });
    	    
    	    if (valueQte > 0 ) {
    	    	var qte = parseInt(valueQte);
    	    	$(invt2).html(qte);
    	    	$(".displayqte").hide();
                $(".add-to-cart-2").show();
    	    	$(invt).show();
    	    }
    		$(document).on('click', ".displayqte", function() {
                if(!$(this).hasClass('btn-personalize') || !$(this).hasClass('invalidPersonnalisation')){
                    $(this).hide();
                    $(".add-to-cart-2").show();
                    $(invt).show('slow'); 
                }
    		});
     }
    
     valueQ();
 	$( window ).resize(function() {
 		var width_w = $( window ).width();
		if (width_w < 992) {
			valueQ();
		}
 	});

}
displayqteonclickadd();

//plp display qte in click

// pop-in lbm - reservation
var reservationBtn = $('.reservation-btn');
var overlay = $('.overlay-popup');  
var popinReservation = $('.popin-reservation');
var reservationContainer = $('.reservation-container');
function showPopinReservation(){
    reservationBtn.click(function(){
        overlay.show();
        popinReservation.show();
        reservationContainer.show();
    });
    $('.fa-close').click(function(){
        overlay.hide();
        popinReservation.hide();
    });
}
showPopinReservation();

var btnPersonalize = $('.btn-personalize');
var autocomplete = $('#autocomplete');
var popinPostalCode = $('#persoPostalCode');
function showPopinPersonalize(){
    $('#personalize-btn, #personalize-btn-2').click(function(){
        $('.overlay-popup').show();
        $('body , html').addClass('bodyOverflow');
        $('.perso-container').show();
        btnPersonalize.show();
        $('span.error').addClass('hide');
        $(".add-succes").hide();
        $(".msg-addr").hide();
        $('.plus-moins').hide();
        $('.qte-perso').hide();
        $('.product-price').show();
        if(autocomplete.val() === "" || popinPostalCode.val() === ""){
            btnPersonalize.prop("disabled", true);
        }
        
    });
    $('.fa-close, .overlay-popup').click(function(){
        $('body , html').removeClass('bodyOverflow');
        $('.overlay-popup').hide();
        $('.perso-container').hide();
        $('.product-price').hide();
        $('#personalize-btn').removeClass('hidebtn');
    });
}
showPopinPersonalize();
// On Click Add to card Trigger Close Popup Personnalize
$(document).on('click','.popin-personalize #add-to-cart',function(){
    if( $('.popin-personalize .fa-close').length && $(window).width() < 1024 ) {
        $('.popin-personalize .fa-close').trigger('click');
    }
});
if($('#pdpMain').length > 0){
    $(document).on('keyup','#autocomplete, #persoPostalCode',function(){
        if(autocomplete.val() != "" && popinPostalCode.val() != ""){
            btnPersonalize.prop("disabled", false);
        } else {
            btnPersonalize.prop("disabled", true);
        }
        if(!$('#invalidPostalCode').hasClass('d-none')){
            $('#invalidPostalCode').addClass('d-none');
        }
        if(!$('#invalidLocation').hasClass('d-none')){
            $('#invalidLocation').addClass('d-none');
        }
    });
    var ac = new google.maps.places.Autocomplete(
        (document.getElementById('autocomplete')), {
            types: ['geocode']
        });

    ac.addListener('place_changed', function() {
        var place = ac.getPlace();

        if (!place.geometry) {
            console.error("Place details are incomplete or invalid:", place);
            return;
        }
        var cityName = null;
        var addressComponents = place.address_components;
        if (addressComponents) {
            for (var i = 0; i < addressComponents.length; i++) {
                var component = addressComponents[i];
                if (component.types.includes('locality')) {
                    cityName = component.long_name;
                    break;
                }
            }
        }
        if(cityName){
            $('#autocomplete').val(cityName);
        } else {
            $('#autocomplete').val(place.formatted_address);
        }
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': place.formatted_address }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var latitude = results[0].geometry.location.lat();
                var longitude = results[0].geometry.location.lng();
                $.ajax({
                    url: util.ajaxUrl(Urls.calculateDistanceToReimsinKM),
                    type: "GET",
                    data: { lat: latitude, lng: longitude },
                    success: function(response) {
                        $('.distance').html('Distance depuis Reims - ' + response + ' KM');
                        $('.distance').css('border', '1px solid #ccc');
                        if(autocomplete.val() != "" || popinPostalCode.val() != ""){
                            btnPersonalize.prop("disabled", false);
                        }
                    },
                    error: function(xhr, status, error) {
                        console.error('Error calculating distance:', error);
                    }
                });
            } else {
                console.error('Geocode error:', status);
            }
        });

        $('#autocomplete').on('input', function() {
            btnPersonalize.prop("disabled", true);
        });

        // Function to handle btnPersonalize functionality
        $('.btn-personalize').on('click',function() {
            if($(this).hasClass('invalidPersonnalisation')){
                $(this).removeClass('invalidPersonnalisation')
            }
            $("#left").css('height', '623px');
        });

        // Initialize autocomplete properties
        $(".sansgravure").prop("checked", false);
        $(".gravure-text").val(cityName);
    });

}

var isSingleLine = true;
var maxLengthInitial = parseInt($('.gravure-text').attr('maxlength'));

$(".gravure-text").keyup(function(e) {
    var textValue = $(this).val();
    var currentLength = textValue.length;
    var maxLength = parseInt($(this).attr('maxlength'));
    var $placeholder = $(this).attr('placeholder');
    
    // Determine whether the text is on one line or multiple lines
    var containsLineBreak = textValue.includes('\n');
    var newIsSingleLine = !containsLineBreak;
    
    // Check if the state has changed
    if (isSingleLine !== newIsSingleLine) {
        isSingleLine = newIsSingleLine; // Update the state
        
        // Handle state change: single line to multiple lines or vice versa
        if (isSingleLine) {
            
            // Reset maxlength to initial value when going back to one line
            $(this).attr('maxlength', maxLengthInitial);
        } else{
            if (currentLength < maxLength) {
                $(this).attr('maxlength', maxLength + 1);
            }
        }
    }
    
    // Convert text to uppercase or lowercase based on data attribute
    if ($(this).data('gravuretextetype') === 'Majuscule') {
        $(this).val($(this).val().toUpperCase());
    } else if ($(this).data('gravuretextetype') === 'Minuscule') {
        $(this).val($(this).val().toLowerCase());
    }

    $(this).attr('placeholder', $placeholder);
});

function engravingRegexPDP() {
    
    $('.gravure-text').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9!@é'àç?èù#$&\\s+()-`.+,/\"]*$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });
    return true;
}
engravingRegexPDP();

$(document).on('click','#removeQ_perso',function(){
    var pid = $('#pid-perso').val();
    $('.popin-personalize').find('.error').removeClass('show');
    $('.popin-personalize').find('.error').addClass('hide');
    var val_quantity = ".value-quantity";
    var customizedText = $(".gravure-text").val();
    var v = $(val_quantity).html();
    var quantityInBasket = '.quantityInBasket';
    if ($('#add-to-cart').length) {
        if (parseInt(v) === parseInt(1)) {
            $('.inventory').hide();
            $(".displayqte").show();
        }
        $.ajax({
            url: util.ajaxUrl(Urls.removeGravurePersonnalizedItem),
            type: "GET",
            data: {pid: pid, customizedText: customizedText},
            success: function (responseRemoving) {
                $.ajax({
                    url: util.ajaxUrl(Urls.checkCustomizedItem),
                    type: "GET",
                    data: {pid: pid, customizedText: customizedText},
                    success: function (response) {
                        if(response.IsExist === true) {
                            $(quantityInBasket).html(v - 1);
                            $(val_quantity).html(v - 1);
                        } else {
                            $(quantityInBasket).html(v - 1);
                            $(val_quantity).html(v - 1);
                            if (parseInt(v) === parseInt(1)) {
                                $('.inventory').hide();
                                $(".displayqte").show();
                            }
                        }
                    }
                });
                if(responseRemoving!==''){
                    minicart.show(responseRemoving);
                }
            }
        });
    }
});

function plp_addtocartclick(){
	
	
	$('body').on( "click", '.product-tile span .add-to-cart-tile-img', function() {
		const addcart_plp = '.addcart_plp';
		$(this).parent().switchClass( "show", "hide" );
        $(this).parent().parent().find(addcart_plp).switchClass( "hide", "show", 500, "easeInOutQuad" ).show('slow');
	}); 
}
plp_addtocartclick();

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        $(function () {
            $('.slider-for .item:empty').remove();
            if ($('.slider-for .item').length > 1) {
            	$('.slider-nav').removeClass('hide');
                $('.slider-for').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    fade: true,
                    accessibility: false,
                    swipe: false,
                    prevArrow: '<button class="slide-arrow prev-arrow"></button>',
                    nextArrow: '<button class="slide-arrow next-arrow"></button>',
                    responsive: [
                        {
                            breakpoint: 1001,
                            settings: {
                                dots: true
                            
                            }
                        }

                    ]
                });
                $('.slider-nav').slick({
                    slidesToShow: 3,
                    accessibility: false,
                    slidesToScroll: 1,
                    dots: false,
                    centerMode: false,
                    arrows: true,
                    vertical: true,
                    verticalSwiping: true
                });
                $('.slider-nav .slick-arrow').on('click', function () {
                    $('.slider-nav .slick-slide').each(function () {
                        $(this).removeClass('slick-current');
                    });
                });
                $('.slider-nav .slick-slide').on('click', function () {

                    $('.slider-for').slick('slickGoTo', $(this).data('slickIndex'));
                    $('.slider-nav .slick-slide').each(function () {
                        $(this).removeClass('slick-current');
                    });
                    $(this).addClass('slick-current');
                });

                $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    $('.slick-track').find('.item').each(function(){
                        var item = $(this);
                        var productimgdiv = item.find('.product-image div');
                        var indeximg = item.data('slick-index');
                        if(indeximg === nextSlide && productimgdiv.length>0 ){ 
                            var classes = productimgdiv.attr('class');
                            var itemprop = productimgdiv.attr('itemprop');
                            var alt = productimgdiv.attr('alt');
                            var datasrc = productimgdiv.attr('data-src');
                            var datazoomimage = productimgdiv.attr('data-zoom-image');
                            var datalgimg = productimgdiv.attr('data-lgimg');
                            var newcontent = '<img class="'+classes+'" itemprop="'+itemprop+'" alt="'+alt+'" src="'+datasrc+'" data-src="'+datasrc+'" data-zoom-image="'+datazoomimage+'"  data-lgimg="'+datalgimg+'" />';                    
                            item.find('.product-image').html(newcontent);
                            if($(window).width() > 1024){
                                var zoomImg = $('.js-zoom-large');
                                if(zoomImg.length){
                                    zoomImg.elevateZoom({
                                        cursor: "crosshair",
                                        zoomWindowWidth: 660,
                                        zoomWindowHeight: 660,
                                        borderSize: 0,
                                        zoomWindowPosition: "product-zoom-container",
                                        zoomWindowOffetx: 20,
                                        zoomLens: false,
                                        lensBorder:0,
                                        easing : true
                                    });
                
                                }
                                zoomImg.hover(function (e) {
                                    $('body').toggleClass('removeScroll');
                                });
                
                            }
                        }
                    });
                });

                $('.primary-image').on( "click", function() {
                    $("img.lazy").trigger("appear");
                });
            }
            else {
                $('.slider-nav').hide();
            }
            /*$('.zoom-btn').on('click', function () {
                var srcBigImage = '';
                if ($('.slider-for .item').length > 1) {
                    srcBigImage = $('.product-primary-image   .slick-current ').find('img.big-image').attr('src');
                }
                else {
                    srcBigImage = $('.product-primary-image  .slider-for ').find('img.big-image').attr('src');
                }

                $('body').addClass("zoom-mode").css({
                    'overflow-y': 'hidden'
                });
                $('.product-image-overlay').fadeIn().append("<div class='content'><img src=" + srcBigImage + " ></div>");
                return;
            });*/

            $('.product-image-overlay .close').on('click', function () {
                $('body').removeClass("zoom-mode").css({
                    'overflow-y': 'auto'
                });
                $('.product-image-overlay .content').remove();
                $('.product-image-overlay').fadeOut();
                return;
            });

            var longDesc = $('.product-desc-long article'),
                showMore = $('.show-more'),
                showLess = $('.show-less'),
                currentPrice = $('.product-price .price-sale-formated').html(),
                addedPrice = $('.addedPrice').html(),
                valueQ = $('.value-quantity'),
                // checkbox = $('#graver'),
                // checkbox = $('.checkboxInput'),
                container = $('.cs-select'),
                item = $('.cs-options > ul  > li'),
                holder = container.find('.cs-placeholder'),
                accordion = $('.product-detail-accordion'),
                accordionItem = accordion.find('ul.list > li'),
                overlay = $('.overlay');

            function productDetailsLogic() {
                accordionItem.each(function (value, index) {
                    var el = $(this);
                    el.on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        $('body ').addClass('active')
                            .css({
                                'overflow-y': 'hidden'
                            });
                        $('.overlay').fadeIn();
                        el.find('.product-accordion-content')
                            .fadeIn()
                            .find('.product-overlay').animate({
                            opacity: 1
                        }, 'fast');

                        return;
                    });
                });


                    $('.close-product-overlay , .product-col-1.product-image-container .overlay, .minicart-header .overlay').on('click', function (e) {
                        if ($('body').hasClass('active')) {
                        e.preventDefault();
                        e.stopPropagation();
                        $('body ').removeClass('active')
                            .css({
                                'overflow-y': 'auto'
                            });
                        overlay.fadeOut();
                        accordionItem.find('.product-accordion-content')
                            .fadeOut()
                            .find('.product-overlay').animate({
                            opacity: 0
                        }, 50);
                        return;
                        }
                    });
            }

            //Select custom
            if (!container.hasClass('cs-active')) {
                $('.cs-placeholder').on('click', function (event) {
                    event.stopPropagation();
                    event.preventDefault();
                    if (container.hasClass('cs-active')) {
                        container.removeClass('cs-active');
                    }
                    else {
                        container.addClass('cs-active');
                        var top = holder.offset().top,
                            left = holder.offset().left,
                            width = holder.width();
                        $('.product-variations .cs-skin-underline.cs-active .cs-options').css({
                            left: left,
                            top: top + 45 + 'px',
                            width: width
                        });
                    }
                });
            }
            else {
                return;
            }
            if (!container.hasClass('cs-active')) {
                $(document).keyup(function (e) {
                    if (e.keyCode === 27) container.removeClass('cs-active');
                });
                $('body').on('click', function () {
                    container.removeClass('cs-active');
                });
                item.on('click', function () {
                    if (!container.hasClass('cs-active')) {
                        return;
                    }
                    var thisContent = $(this).html();
                    //  price = $(this).find('.value').html();
                    //price = parseFloat(price) + parseFloat(addedPrice);
                    /*    currentPrice = price;
                     if(checkbox.is(':checked')) {
                     price = parseFloat(price) + parseFloat(addedPrice);
                     }*/
                    container.removeClass('cs-active');
                    holder.find('.spanContent').empty().append(thisContent);
                    //$('.product-price .price-sale .price-sale-formated').html(price);

                });
            }
            //Options Message à Graver
            var customizedTextElement = $('textarea[name=customizedText]');
            let customizedText = null;
            
            const checkboxMessageLabel = $('#product-grave-content-message-option > fieldset > div > label');
            const spanMessageAvecGravure = checkboxMessageLabel.not('.sansgravure').find('.addedPrice');
            const textMessageLabel = checkboxMessageLabel.not('.sansgravure').contents().filter(function() {
                return this
            }).toArray();
            const optionsMessageDivContainer = $('.product-grave-message');
            const optionsMessageInputs = $('input[name=messageText]');
            const clearMessageOption = $('.clear-option-message');
            const uncheckedMessage = $('span.messageAGraver').not('.sansgravure');
            let checkedItemMessage = null;
            let optionMessage = null;
            
            
            //Checkbox Graver            
            $(document).on('click', '.product-grave-content > fieldset > label', function () {                
                if (!$(this).prev().is(':checked')) {
                    $('.product-grave-textarea').fadeIn();
                    $(this).prev().attr('checked');
                }
                else {
                    $('.product-grave-textarea').fadeOut();
                    $(this).prev().removeAttr('checked');
                    $('textarea[name=customizedText]').val('');
                }
                $(this).prev().trigger('click');
                //  var  newPrice = parseFloat(addedPrice) + parseFloat(currentPrice),
                //  oldPrice = parseFloat(newPrice) - parseFloat(addedPrice);
                /*
                 if(!checkbox.is(':checked')) {
                 $('.price-sale-formated').empty().text(newPrice.toFixed(2));
                 }
                 else {
                 $('.price-sale-formated').empty().text(oldPrice.toFixed(2));
                 }*/    
            });



            // Check Label Message 
            checkboxMessageLabel.on('click', function (e) {
                e.preventDefault();
                clearMessageOption.removeClass('hide');
                if (!$(this).prev().is(':checked')) {
                    optionsMessageDivContainer.removeClass('hide');
                    $(this).prev().prop('checked', false);
                    $(this).parents('.product-grave-option1').addClass('graveur-added');
                }
                else {
                    checkedItemMessage = Array.from($(optionsMessageInputs)).find(function(itemInput){
                      return $(itemInput).is(':checked');  
                    });
                    if(checkedItemMessage) {
                        if(optionsMessageDivContainer.hasClass('hide')){
                            optionsMessageDivContainer.removeClass('hide');
                            clearMessageOption.removeClass('hide');
                        } else {
                            optionsMessageDivContainer.addClass('hide');
                            clearMessageOption.addClass('hide');
                        }
                    } else {
                        $(this).prev().prop('checked', false);
                        optionsMessageDivContainer.addClass('hide');
                    }
                }
            });

            // Handle Message Options Background Color
            $(optionsMessageInputs).on('click', function(e){
                let inputSansGravure = $('#product-grave-content-message-option > fieldset').find('input.hide');
                let labelSansGravure = $('#product-grave-content-message-option > fieldset').find('label.hide');
                var customizedTextElement =  $('textarea[name=customizedText]');
                $(this).parents('.pdpForm').find('.gravure-required').html("false");
                $(this).parents('.product-grave-option1').removeClass('graveur-added');
                Array.from($(optionsMessageInputs)).forEach(function(optionInput) {
                    $(optionInput).parent().css("background-color", "#ffffff");
                });
                $(this).parent().css("background-color", "#f2f2f2");
                if(!(optionsMessageDivContainer.hasClass('hide'))){
                    optionsMessageDivContainer.addClass('hide');
                }
                checkboxMessageLabel.prev().prop('checked', true);
                if(!(clearMessageOption.hasClass('hide'))){
                    $(clearMessageOption).addClass('hide');
                }
                checkboxMessageLabel.text($(this).val());
                checkboxMessageLabel.prepend(spanMessageAvecGravure);
                optionMessage = $(this).val();
                if(optionDestinataire !== null){
                    customizedText = optionMessage + ' ' + optionDestinataire;
                } else {
                    customizedText = optionMessage;
                }
                customizedTextElement.text(customizedText);
                if(inputSansGravure){
                    inputSansGravure.remove();
                }
                if(labelSansGravure){
                    labelSansGravure.remove();  
                }
            });

            // Clear Messages Options
            clearMessageOption.on('click', function (e) {
                let checkBoxOptionInput = checkboxMessageLabel.prev();
                if($(this).parents(".pdpForm").find('.gravure-required-text').text() === "required"){
                    $(this).parents('.pdpForm').find('.gravure-required').text('true');
                }
                $(this).parents('.product-grave-option1').find('.product-grave-message').addClass('hide');
                if($(this).parents('li.attribute').find('.destinataireLabel').contents()[1].textContent !== uncheckedDestinataire.text()){
                    $(this).parents('li.attribute').find('.product-grave-option1').addClass('graveur-added');
                } else {
                    $(this).parents('li.attribute').find('.product-grave-option1').removeClass('graveur-added');
                }
                if(!(optionsMessageDivContainer.hasClass('hide'))){
                    optionsMessageDivContainer.addClass('hide');
                }
                checkBoxOptionInput.prop('checked', false);
                $(this).addClass('hide');
                let inputRadioOption = Array.from($(optionsMessageInputs)).find(function(item){
                    return $(item).is(':checked');
                })
                $(inputRadioOption).prop('checked', false);
                $(inputRadioOption).parent().css("background-color", "#ffffff");
                $(this).parents('.product-grave-content-message').find('label').not('.sansgravure').contents()[1].textContent = uncheckedMessage.text();    
                $('.price_mobile').show();
                var customizedTextElement =  $('textarea[name=customizedText]');
                customizedTextElement.remove();
                $('.product-grave-textarea').append("<textarea class='gravure-text' name='customizedText'></textarea> ");
                $(this).parents('.attribute').find('.clear-option-destinataire').trigger('click');
            });
            
            //Options Destinataire à Graver
            const checkboxDestinataireLabel = $('#product-grave-content-destinataire-option > fieldset > div > label');
            if(checkboxDestinataireLabel.length > 0){
                var spanDestinataire = checkboxDestinataireLabel.find('span');
                var textDestinataireLabel = checkboxDestinataireLabel.contents().filter(function() {
                    return this
                })[1].textContent;
            }
            const optionsDestinataireDivContainer = $('.product-grave-destinataire');
            const optionsDestinataireInputs = $('input[name=destinataireText]');
            if(optionsDestinataireInputs.length > 0){
                var checkedItem =  Array.from($(optionsDestinataireInputs)).find(function(optionInput) {
                        $(optionInput).is(':checked');
                    });
            }
            const clearDestinataireOption = $('.clear-option-destinataire');
            const uncheckedDestinataire = $('span.destinataireAGraver');
            let checkedItemDestinataire = null;
            let optionDestinataire = null;

            // Check Label Destinataire
            checkboxDestinataireLabel.on('click', function (e) {
                e.preventDefault();
                clearDestinataireOption.removeClass('hide');
                if (!($(this).prev().is(':checked'))) {
                    optionsDestinataireDivContainer.removeClass('hide');
                    if($(this).parents('li.attribute').find('label').not('.sansgravure').contents()[1].textContent === uncheckedMessage.text()){
                        $(this).parents('li.attribute').find('.product-grave-option1').addClass('graveur-added');
                    }
                }
                else {
                    checkedItemDestinataire = Array.from($(optionsDestinataireInputs)).find(function(itemInput){
                      return $(itemInput).is(':checked');  
                    });
                    if(checkedItemDestinataire) {
                        $(this).prev().click();
                        if(optionsDestinataireDivContainer.hasClass('hide')){
                            optionsDestinataireDivContainer.removeClass('hide');
                            clearDestinataireOption.removeClass('hide');
                        } else {
                            optionsDestinataireDivContainer.addClass('hide');
                            clearDestinataireOption.addClass('hide');
                        }
                    } else {
                        $(this).prev().removeAttr('checked');
                        optionsDestinataireDivContainer.addClass('hide');
                    }
                }
            });

            // Handle Destinataire Options Background Color
            $(optionsDestinataireInputs).on('click', function(e){
                if(checkboxDestinataireLabel.length > 0){
                    var spanDestinataire = checkboxDestinataireLabel.find('span');
                    var textDestinataireLabel = checkboxDestinataireLabel.contents().filter(function() {
                        return this
                    })[1].textContent;
                }
                let inputSansGravure = $('#product-grave-content-message-option > fieldset').find('input.hide');
                let labelSansGravure = $('#product-grave-content-message-option > fieldset').find('label.hide');
                Array.from($(optionsDestinataireInputs)).forEach(function(optionInput) {
                    $(optionInput).parent().css("background-color", "#ffffff");
                });
                $(this).parent().css("background-color", "#f2f2f2");
                if(!(optionsDestinataireDivContainer.hasClass('hide'))){
                    optionsDestinataireDivContainer.addClass('hide');
                }
                checkboxDestinataireLabel.prev().prop('checked', true);
                if(!(clearDestinataireOption.hasClass('hide'))){
                    $(clearDestinataireOption).addClass('hide');
                }
                checkboxDestinataireLabel.text($(this).val());
                checkboxDestinataireLabel.prepend(spanDestinataire);
                optionDestinataire = $(this).val();
                if(optionMessage !== null && optionMessage !== uncheckedMessage.text()){
                    customizedText = optionMessage + ' ' + optionDestinataire;
                    customizedTextElement.text(customizedText);
                } else {
                    $(this).parents('.product-grave-option2').find('.clear-option-destinataire').trigger('click');
                }
                if(inputSansGravure){
                    inputSansGravure.remove();
                }
                if(labelSansGravure){
                    labelSansGravure.remove();  
                }
            });
            
            // Clear Destinataire Options
            clearDestinataireOption.on('click', function (e) {
                let checkBoxOptionInput = checkboxDestinataireLabel.prev();
                $(this).parents('.product-grave-option2').find('.product-grave-destinataire').addClass('hide');
                if(!(optionsDestinataireDivContainer.hasClass('hide'))){
                    optionsDestinataireDivContainer.addClass('hide');
                }
                if($(this).parents('li.attribute').find('label').not('.sansgravure').contents()[1].textContent !== uncheckedMessage.text()){
                     $(this).parents('li.attribute').find('.product-grave-option1').removeClass('graveur-added');
                }
                checkBoxOptionInput.prop('checked', false);
                $(this).addClass('hide');
                let inputRadioOption = Array.from($(optionsDestinataireInputs)).find(function(item){
                    return $(item).is(':checked');
                })
                $(inputRadioOption).prop('checked', false);
                $(inputRadioOption).parent().css("background-color", "#ffffff");
                $(this).parents('.product-grave-content-destinataire').find('label').contents()[1].textContent = uncheckedDestinataire.text();
                $('.price_mobile').show();
                customizedTextElement.text(optionMessage);                
            });

            var ranges = [
                '[\u1000-\uFFFF]' //Contains all emojis
               ];
            $('body').on('keyup', 'textarea[name=customizedText]', function (event) {
                //remove smily
                var str = $(this).val();
                str = str.replace(new RegExp(ranges.join('|'), 'ig'), '');
                // remove special characters 
                str = str.replace(/[§@#~^°µ<>{}[\]|\$%\^\&(]+/, '');
                $(this).val(str);
            });

            //Quantity logic
            $('#addQ').on('click', function () {
                var maxOrderQuantity = parseInt($('#maximumOrderQuantity').val());
                var atsValue = parseInt($('#maximumOrderQuantity').data('available'));
                var q = $('#Quantity').val();
                var quantityInBasket = $('.quantityInBasket').html();
                var maximumOrderQuantity = $('.maximumOrderQuantity').html();
                if($('.product-acc').length){
                    quantityInBasket = $('.product-acc').find('.quantityInBasket').html();
                    maximumOrderQuantity = $('.product-acc').find('.maximumOrderQuantity').html();
                }
                if($('.popin-personalize').length){
                    quantityInBasket = $('.popin-personalize').find('.quantityInBasket').html();
                    maximumOrderQuantity = $('.popin-personalize').find('.maximumOrderQuantity').html();
                }
                var t = parseInt(q) + parseInt(quantityInBasket);

                if (maximumOrderQuantity != 0 && atsValue != 0) {
                	var minMaxOrderAndATS =Math.min(maximumOrderQuantity,atsValue);
                    if (t >= minMaxOrderAndATS) {
                        valueQ.html(parseInt(valueQ.text()));
                        $('.inventory .error').addClass('show');

                    }
                    else {
                        valueQ.html(parseInt(valueQ.text()) + parseInt(1));
                        $('input[name="Quantity"]:not(.qtyinproducttile)').val(parseInt(q) + parseInt(1));
                    }
                }else if(atsValue != 0){
                	var minMaxOrderAndATS =atsValue;
                    if (t >= minMaxOrderAndATS) {
                        valueQ.html(parseInt(valueQ.text()));
                        $('.inventory .error').addClass('show');
                    }
                    else {
                        valueQ.html(parseInt(valueQ.text()) + parseInt(1));
                        $('input[name="Quantity"]:not(.qtyinproducttile)').val(parseInt(q) + parseInt(1));
                    }
                }


                else {
                    $('input[name="Quantity"]:not(.qtyinproducttile)').val(parseInt(q) + parseInt(1));
                    valueQ.html(parseInt(q) + parseInt(1));
                }

            });


            $(document).on('click', '#removeQ', function () {                
            	$('#addQ').css("pointer-events", "auto");
                $('.inventory .error').removeClass('show');
                $('.mobilestiky').addClass('hide');
                if (parseInt(valueQ.html()) > parseInt(1)) {
                    var q = $('#Quantity').val();
                    $('input[name="Quantity"]:not(.qtyinproducttile)').val(parseInt(q) - parseInt(1));
                    valueQ.html(q);
                }
            });
            
            


            productDetailsLogic();
            if($(window).width() > 1024){
                var zoomImg = $('.js-zoom-large');
                if(zoomImg.length){
                    zoomImg.elevateZoom({
                        cursor: "crosshair",
                        zoomWindowWidth: 660,
                        zoomWindowHeight: 660,
                        borderSize: 0,
                        zoomWindowPosition: "productuuid",
                        zoomWindowOffetx: 20,
                        zoomLens: false,
                        lensBorder:0,
                        easing : true
                    });

                }
                zoomImg.hover(function (e) {
                    $('body').toggleClass('removeScroll');
                });

            }





/**************************************************************************/
/**************************** ZOOM IMAGE PDP ******************************/

function zoomimgcustom() {
    var itemZoom = 25;

    for (let index = 1; index <= itemZoom; index++) {
      $('.img-zoom').append('<li data-zoom="'+index+'" ></li>');
    }
    
    $('.img-zoom li').each(function( index ) {
      var count = index + 1;
      var zoomId = $(this).data('zoom');
      
      $(this).hover(function(){
        $(this).parent('ul').addClass('zoom-in pos-'+zoomId);
      },function(){
          $(this).parent('ul').removeClass('zoom-in pos-'+zoomId);
      })
      
    });
}


$( window ).resize(function() {
    $('.product-primary-image').css('height', $('.primary-image.img-responsive').width() + 'px')
});
$('.product-primary-image').css('height', $('.primary-image.img-responsive').width() + 'px')
$('.primary-image.img-responsive.img-zoomevent,.img-zoomevent-icon').on('click', function () {    
    zoomimgcustom();
    $('.product-primary-image .cat-close').show();
    $('.product-primary-image .slider-nav').hide();
    $('.return-back').hide();
    $('.product-image .img-zoomevent-icon').addClass('hide');
});
function zoomImgPdp(){
    const paths = document.querySelectorAll('.zone-panzoom img')
    paths.forEach((elem) => {

        var panzoom = Panzoom(elem, {
            minScale: 1,
            maxScale: 3
        });

        // mobile case
        if($(window).width() < 768) {
            panzoom = Panzoom(elem, {
                minScale: 1,
                maxScale: 3,
                contain: 'outside',
                startX: -100
            });               
        }

        const parent = elem.parentElement
        parent.addEventListener('wheel', panzoom.zoomWithWheel);
    });
}

$('.panzoom').on('click', function () { 
    $('.marque, .heart, .return-back, .product-etiqs').hide();
    $('.zone-panzoom').addClass('show');
    $('.product-primary-image .cat-close').show();
    $('.product-image').addClass('large');
    $('.product-primary-image').addClass('fixed');
    $('.product-primary-image.fixed .cat-close').show();
    $('.slide-arrow').addClass('view-2');
    zoomImgPdp();
});

$('.product-primary-image .cat-close').on('click', function () { 
    $('.marque,  .heart, .return-back').show();
    $('.zone-panzoom').removeClass('show');
    $(this).hide();
    $('.product-image').removeClass('large');
    $('.product-primary-image').removeClass('fixed');
    $('.slide-arrow').removeClass('view-2');
});


$('.product-primary-image .cat-close').on('hover', function () {
    zoomimgcustom();
});


$('.product-primary-image .cat-close').on('click', function () {


    $('.product-image .img-zoom').empty();
    $('.product-primary-image .slider-nav').show();
    $('.return-back').show();
    $('.product-primary-image .cat-close').hide();
    $('.product-image .img-zoomevent-icon').removeClass('hide'); 
    $('.img-zoomevent-icon').show();
});




$( ".product-col-1.product-image-container " )
  .mouseleave(function() {
    if($('.cat-close').length) {
        $('.product-image .img-zoom').empty();
        $('.product-primary-image .slider-nav').show();
        $('.return-back').show();
        if($(window).width() >= 767){
            $('.product-primary-image .cat-close').hide();
        }
        $('.img-zoomevent-icon').show();
        $('.product-image .img-zoomevent-icon').removeClass('hide'); 
    }
    
  })

            $(document).on('click','.slide-arrow.view-2',function(){
                $('.product-primary-image .cat-close').show();
            });

            $(document).on('touchstart','.slide-arrow.view-2',function(){
                $(this).trigger('click');
            });

            
            // add space if picto's length is more than 2
            if($('.product-image-container .product-newpicto').find('.product-picto').length >= 3) {
                $('.return-back').addClass("return-picto");
                $('.product-image-container .product-newpicto').addClass("top-picto");
              }

        }());
    }
};
module.exports.mobilePriceDisplay = mobilePriceDisplay;
module.exports = product;
