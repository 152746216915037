'use strict';
var util = require('../util');

var tempRetunPage = {
    init: function initReturn (){
            var $myElement = $(".testClass");
            var orders = $myElement.find('.test').toArray();
            function makeAjaxCall(orderId, url) {
                return new Promise(function(resolve, reject) {
                    $.ajax({
                        url: url,
                        type: 'GET',
                        data: {orderId: orderId},
                        dataType: 'json',
                        error: function(error) {
                            console.error('Error processing item');
                            reject(error);
                        }
                    }).done(function (data) {
                        var invoiceData = data;
                        var ProductType = invoiceData.header.productTypes;
                        var containsOnlyServices = false;
                        if(($.inArray("isService", ProductType) != -1) && ($.inArray("isSimple", ProductType) != -1)) {
                            containsOnlyServices = false;
                        } else if(($.inArray("isService", ProductType) === -1) && ($.inArray("isSimple", ProductType) != -1)) {
                            containsOnlyServices = false;
                        } else if(($.inArray("isService", ProductType) != -1) && ($.inArray("isSimple", ProductType) === -1)) {
                            containsOnlyServices = true;
                        }
            
                        var columnsContentData = invoiceData.body.columnsContentData;
            
                        var contentData = invoiceData.body.contentData;
            
                        var doc = new jsPDF('p', 'pt');
                        doc.setFont('verdana');
                        var pageMargin = 40;
                        var totalPagesExp = "{total_pages_count_string}";
            
                        var firstTime = true;
                        var pageContent = function (contentData) {
                            //Logo LGEP
                            var base64Img = "";
                            var isLBM = invoiceData.header.isLBM;
                            if(isLBM) {
                                base64Img = invoiceData.header.LBMLogo;
                                var xOffset = (doc.internal.pageSize.width / 2) - (50 / 2);
                                doc.addImage(base64Img, 'JPEG', xOffset, 15, 50, 45);
                            } else {
                                base64Img = invoiceData.header.LGEPLogo;
                                var xOffset = (doc.internal.pageSize.width / 2) - (161 / 2);
                                doc.addImage(base64Img, 'JPEG', xOffset, 15, 161, 30);
                            }
            
                            //Ligne separatrice
                            doc.setLineWidth(0.2);
                            doc.setDrawColor(200, 200, 200);
                            doc.line(contentData.settings.margin.left, 65, doc.internal.pageSize.width - contentData.settings.margin.left, 65);
            
                            pageMargin = contentData.settings.margin.left;
                            //numero de facture
                            doc.setFontType('bold');
                            doc.setFontSize(16);
                            var text = invoiceData.header.headerInvoiceNumber;
                            xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                            doc.text(text, xOffset, 95);
                            //client et date
                            doc.setFontType('normal');
                            doc.setFontSize(10);
                            var text = invoiceData.header.headerInvoiceOwner;
                            xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                            doc.text(text, xOffset, 110);
                            //adresse de livraison libelle
                            if(!containsOnlyServices) {
                                doc.setFontType('bold');
                                doc.setFontSize(12);
                                var text = invoiceData.header.headerShippingAdress;
                                doc.text(text, contentData.settings.margin.left, 140);
                            }
            
                            var shippingYOffset = 153;
                            //Name
                            if(!containsOnlyServices) {
                                doc.setFontType('normal');
                                doc.setFontSize(7);
                                var text = invoiceData.header.shippingAdressLine1;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                //Company
                                text = invoiceData.header.shippingAdressLineCompany;
                                if (text !== '' && text!==null) {
                                    shippingYOffset = shippingYOffset + 10;
                                    doc.text(text, contentData.settings.margin.left, shippingYOffset);	
                                }
                                //Adresse
                                shippingYOffset = shippingYOffset + 10;
                                var text = invoiceData.header.shippingAdressLine2;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                //Adresse 2
                                var text = invoiceData.header.shippingAdressLine5;
                                if (text != '') {
                                    shippingYOffset = shippingYOffset + 10;
                                    doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                }
                                //Complement 3
                                var text = invoiceData.header.shippingAdressLine6;
                                if (text != '') {
                                    shippingYOffset = shippingYOffset + 10;
                                    doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                }
                                //Code postal
                                shippingYOffset = shippingYOffset + 10;
                                var text = invoiceData.header.shippingAdressLine3;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                //Pays
                                shippingYOffset = shippingYOffset + 10;
                                var text = invoiceData.header.shippingAdressLine4;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                            }
                            if(containsOnlyServices) {
                                shippingYOffset = shippingYOffset + 10;
                            }
                            //Mode de paiement libelle +15
                            doc.setFontType('bold');
                            doc.setFontSize(12);
                            shippingYOffset = shippingYOffset + 15;
                            var text = invoiceData.header.headerBillingMethod;
                            doc.text(text, contentData.settings.margin.left, shippingYOffset);
                            //Mode de paiement +13
                            doc.setFontType('normal');
                            doc.setFontSize(7);
                            shippingYOffset = shippingYOffset + 13;
                            var text = invoiceData.header.headerBillingMethodValue;
                            doc.text(text, contentData.settings.margin.left, shippingYOffset);
                            
                            if(!containsOnlyServices) {
                                //Mode de livraison libelle +15
                                doc.setFontType('bold');
                                doc.setFontSize(12);
                                shippingYOffset = shippingYOffset + 15;
                                var text = invoiceData.header.headerShippingMethod;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                                //Mode de livraison +13
                                doc.setFontType('normal');
                                doc.setFontSize(7);
                                shippingYOffset = shippingYOffset + 13;
                                var text = invoiceData.header.headerShippingMethodValue;
                                doc.text(text, contentData.settings.margin.left, shippingYOffset);
                            }
                            doc.setFontType('bold');
                            var offset1 = doc.getStringUnitWidth(invoiceData.header.headerBillingAdress) * 12;
                            doc.setFontType('normal');
                            var offset2 = doc.getStringUnitWidth(invoiceData.header.billingAdressLine1) * 7;
                            var offset3 = doc.getStringUnitWidth(invoiceData.header.billingAdressLine2) * 7;
                            var offset4 = doc.getStringUnitWidth(invoiceData.header.billingAdressLine3) * 7;
                            var offset5 = doc.getStringUnitWidth(invoiceData.header.billingAdressLine4) * 7;
                            var choosenOffset = Math.max(offset1, offset2, offset3, offset4, offset5);
                            
                            //adresse de facturation libelle
                            doc.setFontType('bold');
                            doc.setFontSize(12);
                            var text = invoiceData.header.headerBillingAdress;
                            //xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                            xOffset = doc.internal.pageSize.width - choosenOffset - contentData.settings.margin.left;
                            doc.text(text, xOffset, 140);
            
                            var billingYOffset = 153;
                            //Name
                            doc.setFontType('normal');
                            doc.setFontSize(7);
                            var text = invoiceData.header.billingAdressLine1;
                            doc.text(text, xOffset, billingYOffset);
                            //Company
                            text = invoiceData.header.billingAdressLineCompany;
                            if (text !== '' && text!==null) {
                                billingYOffset = billingYOffset + 10;
                                doc.text(text, xOffset, billingYOffset);
                            }
                            //Adresse 1
                            billingYOffset = billingYOffset + 10;
                            var text = invoiceData.header.billingAdressLine2;
                            doc.text(text, xOffset, billingYOffset);
                            //Adresse 2
                            var text = invoiceData.header.billingAdressLine5;
                            if (text != '') {
                                billingYOffset = billingYOffset + 10;
                                doc.text(text, xOffset, billingYOffset);
                            }
                            //Complement 3
                            var text = invoiceData.header.billingAdressLine6;
                            if (text != '') {
                                billingYOffset = billingYOffset + 10;
                                doc.text(text, xOffset, billingYOffset);
                            }
                            //Code postal
                            billingYOffset = billingYOffset + 10;
                            var text = invoiceData.header.billingAdressLine3;
                            doc.text(text, xOffset, billingYOffset);
                            //Pays
                            billingYOffset = billingYOffset + 10;
                            var text = invoiceData.header.billingAdressLine4;
                            doc.text(text, xOffset, billingYOffset);
                            //Ligne separatrice + 11
                            doc.setLineWidth(0.2);
                            doc.setDrawColor(200, 200, 200);
                            shippingYOffset = shippingYOffset + 11;
                            doc.line(doc.internal.pageSize.width / 4, shippingYOffset, doc.internal.pageSize.width / 4 * 3, shippingYOffset);
                            //numero de commande + 30
                            doc.setFontType('bold');
                            doc.setFontSize(16);
                            shippingYOffset = shippingYOffset + 30;
                            var text1 = invoiceData.header.headerOrderNumber;
                            var text2 = invoiceData.header.headerOrderNumberValue;
                            xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text1) * doc.internal.getFontSize() / 2) - (doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / 2);
                            doc.text(text1, xOffset, shippingYOffset);
                            xOffset = xOffset + (doc.getStringUnitWidth(text1) * doc.internal.getFontSize());
                            doc.setTextColor('#fd712b');
                            doc.text(text2, xOffset, shippingYOffset);
                            //date facture + 15
                            doc.setFontType('normal');
                            doc.setFontSize(10);
                            shippingYOffset = shippingYOffset + 15;
                            var text = invoiceData.header.headerInvoiceDate;
                            xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
                            doc.setTextColor('#000000');
                            doc.text(text, xOffset, shippingYOffset);
                            // FOOTER
                            var str = "Page " + contentData.pageCount;
                            var strCalcul = "Page " + contentData.pageCount;
                            // Total page number plugin only available in jspdf v1.0+
                            if (typeof doc.putTotalPages === 'function') {
                                str = str + " sur " + totalPagesExp;
                                strCalcul = str + " sur 2";
                                if (!firstTime) {
                                    str = "Page " + totalPagesExp + " sur " + totalPagesExp;
                                }
                                firstTime = false;
                            }
            
                            doc.setFontType('normal');
                            doc.setFontSize(6);
                            var footerText = invoiceData.footer.footerText1;
                            if(isLBM) {
                                footerText = "";
                            } else {
                                footerText = invoiceData.footer.footerText1;
                            }
                            xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / 2);
                            doc.text(footerText, xOffset, doc.internal.pageSize.height - 52);
                            doc.setLineWidth(0.2);
                            doc.line(contentData.settings.margin.left, doc.internal.pageSize.height - 45, doc.internal.pageSize.width - contentData.settings.margin.left, doc.internal.pageSize.height - 45);
                            doc.setFontSize(10);
                            doc.setFontStyle('bold');
                            doc.text(invoiceData.footer.footerText2, contentData.settings.margin.left, doc.internal.pageSize.height - 30);
                            doc.setFontSize(6);
                            doc.setFontStyle('normal');
                            var footerText2 = invoiceData.footer.footerText3;
                            var splitTitle = doc.splitTextToSize(footerText2, doc.internal.pageSize.width - (contentData.settings.margin.left * 2));
                            doc.text(splitTitle, contentData.settings.margin.left, doc.internal.pageSize.height - 20);
                            //Numero de page
                            doc.text(str, doc.internal.pageSize.width - contentData.settings.margin.left - 38, doc.internal.pageSize.height - 10);
            
                        };
            
                        var tableYOffset = 310;
                        
                        var text = invoiceData.header.shippingAdressLine5;
                        if (text != '') {
                            tableYOffset = tableYOffset + 10;
                        
                        }
                        var text = invoiceData.header.shippingAdressLine6;
                        if (text != '') {
                            tableYOffset = tableYOffset + 10;
                        }
                        
                        var contentOptions = {
                            startY: tableYOffset,
                            addPageContent: pageContent,
                            margin: {bottom: 100, top: 310},
                            headerStyles: {
                                fillColor: [255, 255, 255],
                                fontSize: 7,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'bold',
                                halign: 'center',
                                valign: 'middle'
                            },
                            bodyStyles: {
                                fillColor: [255, 255, 255],
                                fontSize: 7,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'normal'
                            },
                            alternateRowStyles: {
                                fillColor: [255, 255, 255]
                            },
                            styles: {
                                font: 'verdana',
                                lineColor: [0, 0, 0],
                                lineWidth: 0.1
                            },
                            columnStyles: {
                                puht: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                tva: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                qty: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                puttc: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                remise: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                total_ttc: {
                                    halign: 'center',
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                code_article: {
                                    halign: 'left',
                                    columnWidth: 90,
                                    valign: 'middle'
                                },
                                designation: {
                                    halign: 'left',
                                    columnWidth: 120,
                                    valign: 'middle'
                                }
                            }
                        }
            
                        doc.autoTable(columnsContentData, contentData, contentOptions);
                        //Recap Tva
                        let finalY = doc.autoTable.previous.finalY;
                        if (finalY > 620) {
                            doc.addPage();
                        }
                        doc.setFontType('bold');
                        doc.setFontSize(10);
                        if (finalY > 620) {
                            doc.text(40, 345, invoiceData.body.tvaTitleLabel);
                        } else {
                            doc.text(40, finalY + 35, invoiceData.body.tvaTitleLabel );
                        }
            
                        var recapTVAColumns = invoiceData.body.recapTVAColumns;
            
                        var recapTVAData = invoiceData.body.recapTVAData;
            
                        var recapTVAOptions = {
                            startY: finalY + 45,
                            tableWidth: 130,
                            headerStyles: {
                                fillColor: false,
                                fontSize: 12,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'bold',
                                halign: 'left',
                                valign: 'middle'
                            },
                            bodyStyles: {
                                fillColor: false,
                                fontSize: 7,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'normal'
                            },
                            alternateRowStyles: {
                                fillColor: false
                            },
                            styles: {
                                font: 'verdana',
                                lineColor: [0, 0, 0]
                            },
                            columnStyles: {
                                taux: {
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                mttva: {
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                }
                            },
                            drawCell: function (cell, invoiceData) {
                                doc.setLineWidth(0.2);
                                doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                            },
                            drawHeaderCell: function (cell, invoiceData) {
                                doc.setLineWidth(0.2);
                                doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                            }
                        }
            
                        if (finalY > 620) {
                            recapTVAOptions.startY = 355;
                            recapTVAOptions.addPageContent = pageContent;
                        }
            
                        doc.autoTable(recapTVAColumns, recapTVAData, recapTVAOptions);
            
                        var recapHTColumns = invoiceData.body.recapHTColumns;
            
                        var recapHTData = invoiceData.body.recapHTData;
            
                        if(containsOnlyServices) {
                            delete recapHTData[1];
                            delete recapHTData[2];
                        }
                        var recapHTOptions = {
                            startY: finalY + 23,
                            tableWidth: (doc.internal.pageSize.width / 2) - (pageMargin ),
                            margin: {left: (doc.internal.pageSize.width / 2)},
                            showHeader: 'never',
                            headerStyles: {
                                fillColor: false,
                                fontSize: 12,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'bold',
                                halign: 'left',
                                valign: 'middle'
                            },
                            bodyStyles: {
                                fillColor: false,
                                fontSize: 7,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'normal'
                            },
                            alternateRowStyles: {
                                fillColor: false
                            },
                            styles: {
                                font: 'verdana',
                                lineColor: [0, 0, 0]
                            },
                            columnStyles: {
                                key: {
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                value: {
                                    columnWidth: 'auto',
                                    valign: 'middle',
                                    halign: 'right'
                                }
                            },
                            drawCell: function (cell, invoiceData) {
                                
                                if (invoiceData.row.index == 0) {
                                    doc.setLineWidth(1.2);
                                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                                    if (invoiceData.column.dataKey === 'key') {
                                        doc.setFontType('bold');
                                        doc.setFontSize(10);
                                    } else if (invoiceData.column.dataKey === 'value') {
                                        doc.setFontType('bold');
                                        doc.setFontSize(10);
                                        doc.setTextColor('#fd712b');
                                    }
                                } else if (invoiceData.row.index == 1) {
                                    //doc.setFontSize(8);
                                } else if (invoiceData.row.index == 2 || invoiceData.row.index == 3) {
                                    doc.setFontType('bold');
                                    doc.setFontSize(8);
                                }
                            }
                        }

                        if (finalY > 620) {
                            recapHTOptions.startY = 333;
                        }
                        doc.autoTable(recapHTColumns, recapHTData, recapHTOptions);
                        
                        let finalRecapHTY = doc.autoTable.previous.finalY;
            
                        var recapTTCColumns = invoiceData.body.recapTTCColumns;
            
                        var recapTTCData = invoiceData.body.recapTTCData;
                        var recapTTCOptions = {
                            startY: finalRecapHTY + 10,
                            tableWidth: (doc.internal.pageSize.width / 2) - (pageMargin ),
                            margin: {left: (doc.internal.pageSize.width / 2)},
                            showHeader: 'never',
                            headerStyles: {
                                fillColor: false,
                                fontSize: 12,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'bold',
                                halign: 'left',
                                valign: 'middle'
                            },
                            bodyStyles: {
                                fillColor: false,
                                fontSize: 7,
                                textColor: 0,
                                font: 'verdana',
                                fontStyle: 'normal'
                            },
                            alternateRowStyles: {
                                fillColor: false
                            },
                            styles: {
                                font: 'verdana',
                                lineColor: [0, 0, 0]
                            },
                            columnStyles: {
                                key: {
                                    columnWidth: 'auto',
                                    valign: 'middle'
                                },
                                value: {
                                    columnWidth: 'auto',
                                    valign: 'middle',
                                    halign: 'right'
                                }
                            },
                            drawCell: function (cell, invoiceData) {
                                if (invoiceData.row.index == 0) {
                                    doc.setLineWidth(1.2);
                                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);                       
                                    doc.setFontType('bold');
                                    doc.setFontSize(10);
                                    doc.setTextColor('#fd712b');
                                } else if (invoiceData.row.index == 1) {
                                    doc.setLineWidth(0.2);
                                    doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
                                }
                            }
                        }
            
                        doc.autoTable(recapTTCColumns, recapTTCData, recapTTCOptions);

                        // Total page number plugin only available in jspdf v1.0+
                        if (typeof doc.putTotalPages === 'function') {
                            doc.putTotalPages(totalPagesExp);
                        }
                        // Output as invoiceData URI
                        doc.save("retour_" + orderId + ".pdf");

                        resolve();
                    
                    });
                });
            }
            async function processItems() {
                for(var i = 0; i < orders.length; i++){
                    var orderId = orders[i];
                    orderId = $(orderId).html();
                    var aajaxUrl = util.ajaxUrl(Urls.oldOrderReturns);
                    try {
                        await makeAjaxCall(orderId, aajaxUrl);
                    } catch (error) {
                        console.error('Error occurred while processing item', itemsArray[i], ':', error);
                    }
                }
            }

            processItems();
            return;

    }
}

module.exports = tempRetunPage;