'use strict';
//var QASContactAddress = require('./QASContactAddress');

var btnEnvoyer = $('#caSubmit');
var maxLength = 15
$("#phone").attr({
    'maxlength' : maxLength
});
btnEnvoyer.on('click',function(e){
    var input_form_nl= $('#caContactForm .chmap-required ');
    input_form_nl.each(function(){
        var phone = $('#phone');
        var phonePattern = /^0[0-9]{1,14}$/;
        var validPhone = false;
        if($(this).val() == "" || !$(this).val().length){
            if(!$(this).next('.error-contact-form.required').length){
                $(this).after('<div class="error-contact-form required" >'+Resources.ERROR_REQUIRED+'</div>');
            }
        } else {
            $(this).next('.error-contact-form.required').remove();
        }
        if(phonePattern.test(phone.val())===false){
            e.preventDefault();
            var error = phone.parents(".caPhoneContainer").find(".error-contact-form.patternerror");
            validPhone = patternValidation(phone.val(),phonePattern,phone,error);
        }
        if(phone.val()){
            var error = phone.parents(".caPhoneContainer").find(".error-contact-form.patternerror");
            validPhone = patternValidation(phone.val(),phonePattern,phone,error);
        }
        if(phone.val() && phone.val() != "") {
            validPhone = patternValidation(phone.val(),phonePattern,phone,phone.parents(".caPhoneContainer").find(".error-contact-form.patternerror"));
            if( !validPhone) {
                e.preventDefault();
            }
        } else {
            phone.parents(".caPhoneContainer").find(".error-contact-form.patternerror").addClass("d-none");
            if( !validPhone) {
                e.preventDefault();
            }
        }

        var checkCivility= $(this).find('input[type="radio"]');
        var checkedRadios;

        if(checkCivility.length > 0) {
            if(!checkCivility.is(":checked") && !checkedRadios && $(this).closest(".chmap-required").next('.error-contact-form.required').hasClass("d-none")) {
                $(this).closest(".chmap-required").next('.error-contact-form.required').addClass("d-block").removeClass("d-none");
                checkedRadios = true;
            } else {
                $(this).closest(".chmap-required").next('.error-contact-form.required').removeClass("d-block").addClass("d-none");
            }
        }
    }); 
});

var btnEnvoyerCadeau = $('#caSubmitCadeau');
btnEnvoyerCadeau.on('click',function(e){
    var input_form_nl= $('#caContactFormCadeau .chmap-required ');
    input_form_nl.each(function(){
        var phone = $('#phoneCadeau');
        var phonePattern = /^0[0-9]{9}$/;
        var validPhone = false;
        if($(this).val() == "" || !$(this).val().length){
            if(!$(this).next('.error-contact-form.required').length){
                $(this).after('<div class="error-contact-form required" >'+Resources.ERROR_REQUIRED+'</div>');
            }
        } else {
            $(this).next('.error-contact-form.required').remove();
        }
        if(phonePattern.test(phone.val())===false){
            e.preventDefault();
            var error = phone.parents(".caPhoneContainerCadeau").find(".error-contact-form.patternerror");
            validPhone = patternValidation(phone.val(),phonePattern,phone,error);
        }
        if(phone.val()){
            var error = phone.parents(".caPhoneContainerCadeau").find(".error-contact-form.patternerror");
            validPhone = patternValidation(phone.val(),phonePattern,phone,error);
        }
        if(phone.val() && phone.val() != "") {
            validPhone = patternValidation(phone.val(),phonePattern,phone,phone.parents(".caPhoneContainerCadeau").find(".error-contact-form.patternerror"));
            if( !validPhone) {
                e.preventDefault();
            }
        } else {
            phone.parents(".caPhoneContainerCadeau").find(".error-contact-form.patternerror").addClass("d-none");
            if( !validPhone) {
                e.preventDefault();
            }
        }

        var checkCivility= $(this).find('input[type="radio"]');
        var checkedRadios;

        if(checkCivility.length > 0) {
            if(!checkCivility.is(":checked") && !checkedRadios && $(this).closest(".chmap-required").next('.error-contact-form.required').hasClass("d-none")) {
                $(this).closest(".chmap-required").next('.error-contact-form.required').addClass("d-block").removeClass("d-none");
                checkedRadios = true;
            } else {
                $(this).closest(".chmap-required").next('.error-contact-form.required').removeClass("d-block").addClass("d-none");
            }
        }
    }); 
});
function patternValidation(value, pattern, input, error) {
    if(value) {
        if(pattern.test(value)) {
            error.addClass("d-none");
            return true;
        }
        else {
            error.removeClass("d-none");
            return false;
        }
    }
}
var contact = {

	    init:function contactForm() {
	 $.fn.select2.defaults.set("theme", "bootstrap");
	    $('.select-request-object .input-select').select2({
	        minimumResultsForSearch: -1,
	        placeholder: 'Objet de votre demande'
	    });
     $('.selectmycountry .input-select').select2({
        minimumResultsForSearch: -1
      });

    $('.country-input-holder').val($('.selectmycountry .input-select option:selected').text());
    $('.subject-input-holder').val(Resources.LABEL_SELECT_SUB);

    //init select country label
    $('#select2-dwfrm_contactForm_contactForm_countryCode-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');

    $('.selectmycountry .input-select').on("select2:select", function (event) {
        var sop = event.params.data.text;
        $('.selectmycountry .input-select option').each(function(){
            if($(this).attr('label')==sop){

                $(this).attr("selected","selected");
            }else{
                $(this).removeAttr("selected","selected");
            }
        });
        $('.country-input-holder').val(event.params.data.text);
        //init select country label
        $('#select2-dwfrm_contactForm_contactForm_countryCode-container').html(Resources.LABEL_SELECT_CTRY).addClass('select-country');
    });

            $('#select2-dwfrm_contactForm_contactForm_contactRequestObject-container').addClass('select-subject');
            $('.select-request-object .input-select').on("select2:select", function (event) {
                var sop = event.params.data.text;
                $('.select-request-object .input-select option').each(function(){
                    if($(this).attr('label')==sop){
                        $(this).attr("selected","selected");
                    }else{
                        $(this).removeAttr("selected","selected");
                    }
                });
             //   $('.subject-input-holder').val(event.params.data.text);
                $('#select2-dwfrm_contactForm_contactForm_contactRequestObject-container').addClass('select-subject');
            });



    //QASContactAddress.init('dwfrm_contactForm_contactForm_', 'profile');

    /*$(document).on('change', '#dwfrm_contactForm_contactForm_countryCode', function() {
    	if ($(this).val() != '' && $(this).val().toUpperCase() === 'FR') {
    		QASContactAddress.init('dwfrm_contactForm_contactForm_', 'profile');
    		QASContactAddress.enable();
    	} else {
    		QASContactAddress.disable();
    	}
    });
    */
    $('#dwfrm_contactForm, #dwfrm_contactFormLBM').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        if ($form.valid()) {
            $('.form-gift').animate({
                scrollTop: 0
            },{ duration: "fast" ,
                complete: function () {
                    $('.form-gift').append('<div class="loader"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                    $('.form-gift').addClass('removeOverflow');
                    var response = grecaptcha.getResponse(0);
                    var captchaValid = false;
                    $.ajax({
                        url: Urls.checkRecaptcha,
                        type: 'get',
                        async: false,
                        dataType: 'json',
                        data: {
                            'responseRecaptcha' : response
                        },
                        success: function (response) {
                            if(response.Success == 'false') {
                            $('.form-gift').find('.loader').remove();
                            $('.form-gift').removeClass('removeOverflow');
                            captchaValid = false;
                            $('.recaptcha-error').removeClass('d-none');
                            } else if(response.Success == 'true') {
                            captchaValid = true;
                            if($('.recaptcha-error').hasClass('d-none') === false)
                            {
                                $('.recaptcha-error').addClass('d-none');
                            }
                            }
                        }
                    });
                    if(captchaValid){
                        $.ajax({
                            url: Urls.saveContactRequest,
                            type: "POST",
                            data: $form.serialize(),
                            success: function (data) {
                                if (data) {
                                    if (data.error == true) {
                                        return false;
                                    } else if (data.error == false) {
                                        page.refesh(url);
                                    }
                                }
                                if(data == '') {
                                    setTimeout(function () {
                                        $('.form-gift ').find('.loader').remove();
                                        $('.form-gift form').fadeOut();
                                        $('.overlay-popup').fadeIn();
                                        $('.confirmGiftForm').fadeIn();
                                    }, 2000);

                                }
                            }

                        });
                    }
                }
            });

            return;
        }
    });

    // pagedesigner catering form submission

    $('#formulaire_traiteur').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        if ($form.valid()) {
            var $elem, captchaPos;
            if($('.form-gift').length){
                $elem = $('.form-gift');
                captchaPos = 1;
            } else {
                $elem = $('#formulaire_traiteur');
                captchaPos = 0;
            }
            $('html, body').animate({
                scrollTop: $elem.offset().top
            }, { duration: "fast" ,
                complete: function () {
                   $elem.append('<div class="loader"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                   $elem.addClass('removeOverflow');
                    var response = grecaptcha.getResponse(captchaPos);
                    var captchaValid = false;
                    $.ajax({
                        url: Urls.checkRecaptcha,
                        type: 'get',
                        async: false,
                        dataType: 'json',
                        data: {
                            'responseRecaptcha' : response
                        },
                        success: function (response) {
                            if(response.Success == 'false') {
                                $elem.find('.loader').remove();
                                $elem.removeClass('removeOverflow');
                                captchaValid = false;
                            } else if(response.Success == 'true') {
                                captchaValid = true;
                                $('.recaptcha-error').addClass('d-none');

                                if($('.recaptcha-error').hasClass('d-none') === false)
                                {
                                    $('.recaptcha-error').addClass('d-none');
                                }
                            }
                        }
                    });
                    if(captchaValid){
                        $.ajax({
                            url: Urls.validateFormTraiteur,
                            type: "POST",
                            data: $form.serialize(),
                            success: function (data) {
                                if (data) {
                                    if (data.error == true) {
                                        return false;
                                    } else if (data.error == false) {
                                        page.refesh(url);
                                    }
                                }
                                if (data == '') {
                                    setTimeout(function () {
                                       $elem.find('.loader').remove();
                                        $('#formulaire_traiteur').fadeOut();
                                        $('.overlay-popup').removeClass('hide');
                                        $('.overlay-popup').fadeIn();
                                        $('.confirmGiftForm').removeClass('hide');
                                        $('.confirmGiftForm').fadeIn();
                                        $('#formulaire_traiteur').addClass('formSent');
                                        // Reset reCAPTCHA here
                                        grecaptcha.reset(captchaPos);
                                    }, 2000);
            
                                }
                            }

                        });
                    }
                }
            });

            return;
        }
    });

    // pagedesigner business gift form submission

    $('#formulaire_cadeau_affaire').on('submit', function (e) {

        e.preventDefault();
        var $form = $(this);
        if ($form.valid()) {
            $('.form-gift').animate({
                scrollTop: 0
            },{ duration: "fast" ,
                complete: function () {
                    $('.form-gift').append('<div class="loader"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                    $('.form-gift').addClass('removeOverflow');
                    var response = grecaptcha.getResponse(2);
                    var captchaValid = false;
                    $.ajax({
                        url: Urls.checkRecaptcha,
                        type: 'get',
                        async: false,
                        dataType: 'json',
                        data: {
                            'responseRecaptcha' : response
                        },
                        success: function (response) {
                            if(response.Success == 'false') {
                            $('.form-gift').find('.loader').remove();
                            $('.form-gift').removeClass('removeOverflow');
                            captchaValid = false;
                            $('.recaptcha-error').removeClass('d-none');
                            } else if(response.Success == 'true') {
                            captchaValid = true;
                            $('.recaptcha-error').addClass('d-none');

                            if($('.recaptcha-error').hasClass('d-none') === false)
                            {
                                $('.recaptcha-error').addClass('d-none');
                            }
                            }
                        }
                    });
                    if(captchaValid){
                        $.ajax({
                            url: Urls.validateFormulaireCadeauAffaire,
                            type: "POST",
                            data: $form.serialize(),
                            success: function (data) {
                                if (data) {
                                    if (data.error == true) {
                                        return false;
                                    } else if (data.error == false) {
                                        page.refesh(url);
                                    }
                                }
                                if (data == '') {
                                    setTimeout(function () {
                                        $('.form-gift').find('.loader').remove();
                                        $('#formulaire_cadeau_affaire').fadeOut();
                                        $('.overlay-popup').removeClass('hide');
                                        $('.overlay-popup').fadeIn();
                                        $('.confirmGiftForm').removeClass('hide');
                                        $('.confirmGiftForm').fadeIn();
                                        $('#formulaire_cadeau_affaire').addClass('formSent');
                                    }, 2000);
            
                                }
                            }

                        });
                    }
                }
            });

            return;
        }
    });

    function isEmpty(value) {
        return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
    }

    var isValidDate = function (value, userFormat) {
        var

        userFormat = userFormat || 'dd/mm/yyyy', // default format

        delimiter = /[^dmy]/.exec(userFormat)[0],
        theFormat = userFormat.split(delimiter),
        theDate = value.split(delimiter),

        isDate = function (date, format) {
          var m, d, y
          for (var i = 0, len = format.length; i < len; i++) {
            if (/m/.test(format[i])) m = date[i]
            if (/d/.test(format[i])) d = date[i]
            if (/y/.test(format[i])) y = date[i]
          }
          return (
            m > 0 && m < 13 &&
            y && y.length === 4 &&
            d > 0 && d <= (new Date(y, m, 0)).getDate()
          )
        }

        return isDate(theDate, theFormat)
    }

    $('.gft-form form').on('submit', function (e) {
        var $form = $(this);
        var response = grecaptcha.getResponse(2);
        var $deliveryDate = $(".dateInput input[type='text']");
        if ($form.valid() && !isEmpty(response)) {
            if($('.recaptcha-error').hasClass('d-none') === false){
                $('.recaptcha-error').addClass('d-none');
            }
            if($deliveryDate.length > 0) {
                if($deliveryDate.closest(".dateOnlyInput")) {
                    if($deliveryDate.closest(".dateOnlyInput").find(".error").length > 0) {
                        var $errorMsgDeliveryDate = $deliveryDate.closest(".dateOnlyInput").find(".error");
                        var $deliveryDateValue = $deliveryDate.val();
                        if(!isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                            $errorMsgDeliveryDate.removeClass("d-none");
                            e.preventDefault();
                        } else if(isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                            $errorMsgDeliveryDate.addClass("d-none");
                        }
                    }
                }
            }
            var $formContainer = $form.closest('.form-gift');
            $formContainer.animate({
                    scrollTop: 0
            },{duration: "fast" ,
                complete: function () {
                    $formContainer.append('<div class="loader loader-gray"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                    $formContainer.addClass('removeOverflow');
                    $formContainer.find('.loader').remove();
                    $('#formulaire_cadeau_affaire').fadeOut();
                    $('.overlay-popup').removeClass('hide');
                    $('.overlay-popup').fadeIn();
                    $('.confirmGiftForm').removeClass('hide');
                    $('.confirmGiftForm').fadeIn();
                    $('#formulaire_cadeau_affaire').addClass('formSent');
                    if($deliveryDate.length > 0) {
                        if($deliveryDate.closest(".dateOnlyInput")) {
                            if($deliveryDate.closest(".dateOnlyInput").find(".error").length > 0) {
                                var $deliveryDateValue = $deliveryDate.val();
                                if(!isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                    $formContainer.find('.loader').remove();
                                    $('#formulaire_cadeau_affaire').fadeOut();
                                    $('.overlay-popup').addClass('hide');
                                    $('.overlay-popup').fadeOut();
                                    $('.confirmGiftForm').addClass('hide');
                                    $('.confirmGiftForm').fadeOut();
                                    $('#formulaire_cadeau_affaire').removeClass('formSent');
                                    $formContainer.removeClass("removeOverflow");
                                } else if(isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                    $formContainer.append('<div class="loader loader-gray"><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>');
                                    $formContainer.addClass('removeOverflow');
                                    $formContainer.find('.loader').remove();
                                    $('#formulaire_cadeau_affaire').fadeOut();
                                    $('.overlay-popup').removeClass('hide');
                                    $('.overlay-popup').fadeIn();
                                    $('.confirmGiftForm').removeClass('hide');
                                    $('.confirmGiftForm').fadeIn();
                                    $('#formulaire_cadeau_affaire').addClass('formSent');
                                }
                            }
                        }
                    }
                }
            });
        } else {
            e.preventDefault();
            if(isEmpty(response)) {
                if($('.recaptcha-error').hasClass('d-none')){
                    $('.recaptcha-error').removeClass('d-none');
                }
                if($deliveryDate.length > 0) {
                    if($deliveryDate.closest(".dateOnlyInput").length > 0) {
                        if($deliveryDate.closest(".dateOnlyInput").find(".error").length > 0) {
                            var $errorMsgDeliveryDate = $deliveryDate.closest(".dateOnlyInput").find(".error");
                            var $deliveryDateValue = $deliveryDate.val();
                            if(!isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                $errorMsgDeliveryDate.removeClass("d-none");
                            } else if(isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                $errorMsgDeliveryDate.addClass("d-none");
                            }
                        }
                    }
                }
            } else {
                if($deliveryDate.length > 0) {
                    if($deliveryDate.closest(".dateOnlyInput").length > 0) {
                        if($deliveryDate.closest(".dateOnlyInput").find(".error") .length > 0) {
                            var $errorMsgDeliveryDate = $deliveryDate.closest(".dateOnlyInput").find(".error");
                            var $deliveryDateValue = $deliveryDate.val();
                            if(!isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                $errorMsgDeliveryDate.removeClass("d-none");
                            } else if(isValidDate($deliveryDateValue, "dd/mm/yyyy") && !isEmpty($deliveryDateValue)) {
                                $errorMsgDeliveryDate.addClass("d-none");
                            }
                        }
                    }
                }
            }
        }
    });

    $('#overlay-giftForm').add('#cdClose').on('click', function()
    {
        $('.confirmGiftForm').fadeOut();
        $('.confirmGiftForm').addClass('hide');
        $('.overlay-popup').fadeOut();
        $('.overlay-popup').addClass('hide');
        $('#formulaire_cadeau_affaire').trigger('reset');        
        $('#formulaire_cadeau_affaire').fadeIn();
        $('.gift-overlay').trigger('click');
        $('.form-gift').removeClass('removeOverflow');
    });

    $('#overlay-cateringForm').add('#trClose').on('click', function()
    {
        $('.confirmGiftForm').fadeOut();
        $('.confirmGiftForm').addClass('hide');
        $('.overlay-popup').fadeOut();
        $('.overlay-popup').addClass('hide');
        $('#formulaire_traiteur').trigger('reset');        
        $('#formulaire_traiteur').fadeIn();
        $('.gift-overlay').trigger('click');
        $('.form-gift').removeClass('removeOverflow');
    });

    $('#trMinus').on('click', function()
    {
        var value = parseInt($('#trNumberOfPpl').val());
        if(value > 0)
        {
            value = value - parseInt(1);
            $('#trNumberOfPpl').val(value);
        }
    });

    $('#trPlus').on('click', function()
    {
        var value = parseInt($('#trNumberOfPpl').val());
        value = value + parseInt(1);
        $('#trNumberOfPpl').val(value);
    });
    $('#cdMinus').on('click', function()
    {
        var value = parseInt($('#cdGiftNumber').val());
        if(value > 0)
        {
            value = value - parseInt(1);
            $('#cdGiftNumber').val(value);
        }
    });

    $('#cdPlus').on('click', function()
    {
        var value = parseInt($('#cdGiftNumber').val());
        value = value + parseInt(1);
        $('#cdGiftNumber').val(value);
    });
}
}
module.exports = contact;