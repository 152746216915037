'use strict';

var util = require('../../util'),
    ajax = require('../../ajax');

/**
 * @function
 * @description performs a generic ajax cart submit
 * */

var ajaxrq;
var currentproductuuid;
function postAjaxCartForm(action, uuid) {
    var cartRow = $(".cart-table").find(".cart-row[data-uuid='" + uuid + "']");
    cartRow.find(".product-price").html('<div class="loading"></div>');
    cartRow.find(".price-option").hide();
	var $form = $('[name="dwfrm_cart"]');
    var data = ajax.appendParameterToAjaxRequestData($form.serialize(), action);
    ajaxrq = $.ajax({
        url : $form.attr('action'),
        type: "POST",
        data: data,
        success: function(result){
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.minicartShowAjax),
                data: {
                    updatecarttype : 'ajax',
                    productuuid : uuid
                },
                success: function(response){
                    var uuid_price = $(response).find(".mini-cart-product-"+uuid).find('.mini-cart-price').html();
                    var price_unadjusted = $(response).find(".mini-cart-product-"+uuid).find('.price-unadjusted').html();
                    var option_price_adjusted = $(response).find(".mini-cart-product-"+uuid).find('.additonalOption').find('.mini-cart-price').html();
                    var order_total = $(response).find('.mini-cart-subtotals').find('.value').html();
                    var total_qty = $(response).find('.minicart-quantity').html();
                    var qty_product = $(response).find(".mini-cart-product-"+uuid).find('.mini-cart-pricing.qte .qty_prdct').html();
                    var qtyRow = cartRow.find('.value-quantity').text();
                    var engraving_price = cartRow.find('input[name="optionPrice"]').val();
                    if(engraving_price) {
                        if(option_price_adjusted) {
                            engraving_price = parseFloat(engraving_price.split(' ')[1]);
                            var total_engraving_price = (engraving_price*parseInt(qtyRow)).toFixed(2);
                            total_engraving_price = total_engraving_price.replace('.', ',');
                            if (parseFloat(total_engraving_price.replace(',', '.')) !== parseFloat(option_price_adjusted.replace(',', '.'))) {
                                var lang = $('html').attr('lang');
                                if (lang === 'en') {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('<strike>' + Resources.CURRENCY_SYMBOL + total_engraving_price + '</strike>' + '<span class="text-accent">+'+ option_price_adjusted);
                                } else {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('<strike>' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL + '</strike>' + '<span class="text-accent">+'+ option_price_adjusted);
                                }
                            } else {
                                var lang = $('html').attr('lang');
                                if (lang === 'en') {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('+ ' + Resources.CURRENCY_SYMBOL + total_engraving_price);
                                } else {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('+ ' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL);
                                }
                            }
                            
                        } else {
                            engraving_price = parseFloat(engraving_price.split(' ')[1]);
                            var total_engraving_price = (engraving_price*parseInt(qtyRow)).toFixed(2);
                            total_engraving_price = total_engraving_price.replace('.', ',');
                            var lang = $('html').attr('lang');
                            if (lang === 'en') {
                                cartRow.find(".price-option").show();
                                cartRow.find('.price-option .value').html('+ ' + Resources.CURRENCY_SYMBOL + total_engraving_price);
                            } else {
                                cartRow.find(".price-option").show();
                                cartRow.find('.price-option .value').html('+ ' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL);
                            }
                        }
                    }
                    var price_old = $(response).find(".mini-cart-product-"+uuid).find('.price-unadjusted').find('.strikethrough ').html();
                    if(price_unadjusted) {
                        if (parseFloat(price_old.replace(',', '.')) !== parseFloat(uuid_price.replace(',', '.'))) {
                            cartRow.find('.product-price .loading').replaceWith('<span class="text-accent">' + uuid_price + '</span>' + price_unadjusted);
                        }
                        else {
                            cartRow.find('.product-price .loading').replaceWith(uuid_price);
                        }
                    } else {
                        cartRow.find('.product-price .loading').replaceWith(uuid_price);
                    }
                    cartRow.find('.value-quantity').html(qty_product);
                    cartRow.find('#quanity').val(qty_product);
                    $(".order-value").html(order_total);
                    $('.first-step .left-bloc-top-bar').html(Resources.CARTSTEPTITLE + ' (' + total_qty + ')' );
                    $('.packing-bloc').load(location.href+" .packing-bloc>*");
                    $('#cart-messages').load(location.href+" #cart-messages>*");
                }
            });
        }
    });
}

var initializeEvents = {
    init:function () {
        //Format title
        var formatTitle = function () {
            var str = $('.cart-empty h1').text(),
                lastWord = str.match(/\w+$/)[0],
                lastIndex = str.lastIndexOf(" ");
            str = str.substring(0, lastIndex);
            $('.cart-empty h1').empty().text(str);
            //Build the word
            var letters = lastWord.split('');
            $(letters).each(function (index, value) {
                $('.last-word').append('<span class="item-' + index + '">' + value + '</span>');
            });
        }

        if ($('.cart-empty').length) {
            formatTitle();
        }

        //Quantity logic
        var item = $('.cart-table > li');
        item.each(function (index, value) {
            var el = $(this);
            var $form = $(this).closest('form');

            var id = $form.find('input[name="DLProductID"]').val();
            var name = $form.find('input[name="DLProductName"]').val();
            var brand = $form.find('input[name="DLProductBrand"]').val();
            var price = parseFloat($form.find('input[name="DLProductPrice"]').val());
            var category = $form.find('input[name="DLProductCategory"]').val();
            var variant = $form.find('input[name="DLProductVariant"]').val();
            var currencyCode = $form.find('input[name="DLCurrencyCode"]').val();
            var quantity = 1;

            el.find('.addQ').on('click', function () {
                var productuuid = $(this).closest('.cart-row').data('uuid');
                if(ajaxrq && currentproductuuid && currentproductuuid === productuuid ){
                    ajaxrq.abort();
                }else{
                    currentproductuuid = productuuid;
                }
                var i = index;
                //Push Datalayer to GTM for event add to cart
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        "event": "addToCart",
                        "ecommerce": {
                            "currencyCode": currencyCode,
                            "add": {
                                "products": [{
                                    "name": name,
                                    "id": id,
                                    "price": price,
                                    "brand": brand,
                                    "category": category,
                                    "variant": variant,
                                    "quantity": quantity
                                }]
                            }
                        }
                    });
                }

                var valueQ = $(this).parents('.quantity').find('.value-quantity');
                var maxForItem = $('.itemMax-' + i).val();
                var atsValue = parseInt($('.itemMax-' + i).data('available'));
                var vQ = valueQ.html();
                if (maxForItem != 'null' && atsValue != 0) {
                    var minMaxOrderAndATS =Math.min(parseInt(maxForItem),atsValue);
                    if (vQ >= minMaxOrderAndATS) {
                        el.find('.error').addClass('show');
                        return;
                    }
                }else if(atsValue != 0){
                    if (vQ >= atsValue) {
                        el.find('.error').addClass('show');
                        return;
                    }
                }
                valueQ.html(parseInt(valueQ.text()) + parseInt(1));
                var q = $('.item-' + i).val();

                var maximumOrderQuantity = $(this).closest(".item-quantity").find("#maximumOrderQuantity").val();
                var nextQty = parseInt(vQ) + 1;
                if(nextQty === parseInt(maximumOrderQuantity)){
                    $(this).css("pointer-events", "none");
                }
                $('.item-' + i).val(parseInt(q) + parseInt(1));
                postAjaxCartForm('dwfrm_cart_updateCart', productuuid);

            });
            el.find('.removeQ').on('click', function () {
                el.find('.addQ').css("pointer-events", "auto");
                var productuuid = $(this).closest('.cart-row').data('uuid');
                if(ajaxrq && currentproductuuid && currentproductuuid === productuuid ){
                    ajaxrq.abort();
                }else{
                    currentproductuuid = productuuid;
                }
                var i = index;
                //Push Datalayer to GTM for event remove from cart
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        "event": "removeFromCart",
                        "ecommerce": {
                            "remove": {
                                "products": [{
                                    "name": name,
                                    "id": id,
                                    "price": price,
                                    "brand": brand,
                                    "category": category,
                                    "variant": variant,
                                    "quantity": quantity
                                }]
                            }
                        }
                    });
                }

                var valueQ = $(this).parents('.quantity').find('.value-quantity');

                var vQ = valueQ.html();
                if (parseInt(vQ) > parseInt(1)) {
                    valueQ.html(parseInt(valueQ.text()) - parseInt(1));
                    var q = $('.item-' + i).val();
                    $('.item-' + i).val(parseInt(q) - parseInt(1));
                    postAjaxCartForm('dwfrm_cart_decreaseProductInCart', productuuid);
                    toggleGiftBox();
                } else {
                    el.find('.removeProduct').trigger('click');
                    toggleGiftBox();
                }


            });

        });

        function toggleGiftBox() {
            var isServiceExistinCart = $(".cart-table").find(".isService").length;
            var isNotServiceExistInCart = $(".cart-table").find(".isNotService").length;
            if(isServiceExistinCart && isNotServiceExistInCart) {
                $('#messagecadeau').show();
            } else if(!isServiceExistinCart && isNotServiceExistInCart) {
                $('#messagecadeau').show();
            } else if(isServiceExistinCart && !isNotServiceExistInCart) {
                $('#messagecadeau').hide();
            }
        }
        toggleGiftBox();

        $('.cart-row').each(function () {
            var el = $(this).find('.product-grave-content > fieldset input').not('.hide');
            if (el.is(':checked')) {
                el.parents('.product-grave-content').find('.product-grave-textarea').fadeIn();
            }
        });

        var el = $('.cart-table > li .item-details .product-options');
        var tp = $('.order-totals-table');
        el.find('.product-grave-content > fieldset > label').on('click', function () {
            var isDisabled = $(this).prev().prop('disabled');
            if (!$(this).prev().is(':checked')) {
                if(!isDisabled){
                    $(this).parent().find('.product-grave-textarea').fadeIn();
                }
                $(this).parent().find('.product-grave-textarea').find('textarea[name=customizedText]').focus();
                var oldprice = tp.find('.order-value').eq(0).text();
                var oldPricesplit = oldprice.split(' ');
                var stroldPrice = oldPricesplit[0];
                var resOldPrice = stroldPrice.replace(",", ".");
                oldprice = parseFloat(resOldPrice);
            }

            else {
                if(!isDisabled){
                    $(this).parent().find('.product-grave-textarea').fadeOut();
                }
                var price = ($(this).parent().find('input[value="0"]')).attr('data-combined');
            }
            $(this).prev().trigger('click');
        });

        if ($('#cyb_payerauth').length) {
            $('body').addClass('is3dSecure');
        }
    }
}

module.exports = initializeEvents;