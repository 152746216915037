'use strict';

var dialog = require('../../dialog'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash');

/**
 * @description Make the AJAX request to add an item to wishlist
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToWishlist = function (link) {
    var $link = $(link);
    return Promise.resolve($.ajax({
        type: 'GET',
        url: util.ajaxUrl($link.attr('href')),
        data: {}
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
};
/**
 * @description Make the AJAX request to remove an item to wishlist
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var removeItemFromWishlist = function (link) {
	 var $link = $(link);
	return Promise.resolve($.ajax({
    type: 'GET',
    url: util.ajaxUrl($link.attr('href')),
    data: {}
})).then(function (response) {
    // handle error in the response
    if (response.error) {
        throw new Error(response.error);
    } else {
        return response;
    }
});
}; 

/**
 * @description Handler to handle the add to wishlist event
 */
var addToWishlist = function (e) {
    e.preventDefault();
    var $link = $(this);
    
    // push dataLayer for GTM in case of adding product to the wishlist
    if(typeof dataLayer !== 'undefined'){
    	var productName = $(this).attr('data-productname');
    	dataLayer.push({"event": "eventGA", "categoryGA": "engagement", "actionGA": "add to whishlist", "labelGA": ""+ productName +"","valueGA": ""});
    }
    	     
    	 

    addItemToWishlist($link).then(function (response) {
    	location.reload();
    }.bind(this));
};
/**
 * @description Handler to handle the remove to wishlist event
 */
var removeFromWishlist = function (e) {
	e.preventDefault();
	var $link = $(this);
	
    removeItemFromWishlist($link).then(function (response) {
    	location.reload();
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to woshlist event
 */
/* 
var addAllToWishlist = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};
*/

/**
 * @function
 * @description Binds the click event to a given target for the add-to-wishlist handling
 */
module.exports = function () {
    $('.addWishlist').on('click', addToWishlist);
    $('.removeWishlist').on('click', removeFromWishlist);
};
